import { createSlice } from '@reduxjs/toolkit';

const messageErrorSlice = createSlice({
  name: 'messageError',
  initialState: {
    message: null,
  },
  reducers: {
    setMessage(state, action) {
      state.message = action.payload;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
});

export const { setMessage, clearMessage } = messageErrorSlice.actions;
export const selectMessageError = (state) => state.messageError.message;
export default messageErrorSlice.reducer;
