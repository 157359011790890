import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  CardActionArea, Grid, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  cost: {
    display: 'flex', alignItems: 'flex-end',
  },
  premiumStyle: {
    height: '30px',
    backgroundColor: 'orange',
  },
  basicStyle: {
    height: '30px',
    backgroundColor: 'silver',
  },
});

const CardCustom = ({
  premium, type, cost, description, contract, unit, quantity, onClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return desktop ? (
    <Card sx={{ maxWidth: 500, margin: '5px', borderRadius: '5px 5px 20px 20px' }}>
      <CardActionArea onClick={onClick}>
        <div className={premium ? classes.premiumStyle : classes.basicStyle} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <div style={{ display: 'flex' }}>
                <Typography>{type}</Typography>
                <Typography sx={{ margin: '0 10px', color: 'red' }}>{contract}</Typography>
              </div>
              <div className={classes.cost}>
                <Typography>¥</Typography>
                <Typography fontSize={30}>{cost}</Typography>
                <Typography>
                  /
                  {quantity}
                  {unit}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={7} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  ) : (
    <Card sx={{ maxWidth: 500, margin: '5px', height: 200, borderRadius: '5px 5px 20px 20px' }}>
      <CardActionArea onClick={onClick}>
        <div className={premium ? classes.premiumStyle : classes.basicStyle} />
        <CardContent>
          <div style={{ display: 'flex' }}>
            <Typography>{type}</Typography>
            <Typography sx={{ margin: '0 10px', color: 'red' }}>{contract}</Typography>
          </div>
          <div className={classes.cost}>
            <Typography>¥</Typography>
            <Typography fontSize={30}>{cost}</Typography>
            <Typography>
              /
              {quantity}
              {unit}
            </Typography>
          </div>
          <Typography gutterBottom variant="subtitle1" component="div">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardCustom;
