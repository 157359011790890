import React, { useState } from 'react';
import {
  Button, TextField, Typography, Snackbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LoginLayout from './LoginLayout';
import { useTranslation } from '../common/components/LocalizationProvider';
import useQuery from '../common/util/useQuery';
import { snackBarDurationShortMs } from '../common/util/duration';
import { useCatch } from '../reactHelper';
import LogoImage from './LogoImage';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    border: '1px solid black',
    padding: '30px',
  },
}));

const ResetPasswordPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const theme = useTheme();
  const query = useQuery();

  const token = query.get('passwordReset');

  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = useCatch(async (event) => {
    event.preventDefault();
    let response;
    if (!token) {
      response = await fetch('/api/admin/admin_password/reset', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      });
    }
    if (response.ok) {
      setSnackbarOpen(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } else {
      throw Error(await response.text());
    }
  });

  return (
    <LoginLayout>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px' }}>
        <LogoImage color={theme.palette.primary.main} />
      </div>
      <div className="container-page-login container-reset-pass">
        <div className="text-reset-pass">
          <Typography fontWeight={700} mb={1} textAlign="center" fontSize={25}>{t('loginResetting')}</Typography>
          <Typography fontSize={16} fontWeight={700}>{t('loginResetText')}</Typography>
        </div>
        <div className="block-field">
          <Typography>{t('loginId')}</Typography>
          <TextField
            required
            type="email"
            placeholder="xxxxxx@xxxxxxxxx"
            fullWidth
            name="email"
            value={email}
            autoComplete="email"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="block-field">
          <Button
            className="btn-perform"
            variant="contained"
            onClick={handleSubmit}
            disabled={!/^(?!.*_\w*)\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)}
            fullWidth
          >
            {t('send')}
          </Button>
        </div>
        <div className="block-field">
          <a href="#" onClick={() => navigate('/login')}>{t('return')}</a>
        </div>

      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={snackBarDurationShortMs}
        message={t('loginResetEmail')}
      />
    </LoginLayout>
  );
};

export default ResetPasswordPage;
