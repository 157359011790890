import React, { useEffect, useState } from 'react';
import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { helperActions } from '../../../store';
import fetchApi from '../../FetchApi';
import { normalAcc, specialAcc } from '../../data';

const useStyle = makeStyles((theme) => ({
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    fontSize: '3rem',
    color: 'black',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  currentFont: {
    fontSize: '2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
}));
const AdminCard = ({ className, normal }) => {
  const t = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const [account, setAccount] = useState({
    totalManager: '...',
    totalSubManager: '...',
    totalUser: '...',
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch(helperActions.setLoading(true));
      const response = await fetchApi(`/api/admin/statistics/users?category=${normal ? 0 : 1}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        setAccount(objRecive);
      }
    };
    fetchData();
    dispatch(helperActions.setLoading(false));
  }, []);

  return (
    <Box>
      <Card variant="outlined" className={className}>
        <CardContent>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', textDecoration: 'underline', textAlign: 'center' }} gutterBottom>
            {normal ? t('countNormalAccount') : t('countSpecialAccount')}
          </Typography>
          <div className={classes.contentBox}>
            <PersonIcon className={classes.icon} sx={{ stroke: '#f02000', strokeWidth: 1 }} />
            <Typography className={classes.currentFont}>{account.totalManager}</Typography>
          </div>
          <div className={classes.contentBox}>
            {/* <PersonOutlinedIcon className={classes.icon} /> */}
            <PersonIcon className={classes.icon} />
            <Typography className={classes.currentFont}>{account.totalSubManager}</Typography>
          </div>
          {/* <div className={classes.contentBox}>
            <LocationOnIcon className={classes.icon} />
            <Typography className={classes.currentFont}>{account.totalUser}</Typography>
          </div> */}
        </CardContent>
      </Card>
    </Box>
  );
};

export default AdminCard;
