import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'positions',
  initialState: {
    items: {},
    todayPositions: {},
    todayHistory: [],
    address: [],
  },
  reducers: {
    update(state, action) {
      action.payload.forEach((item) => state.items[item.deviceId] = item);
    },
    getTodayPositions(state, action) {
      state.todayPositions = action.payload;
    },
    getTodayHistory(state, action) {
      state.todayHistory = action.payload;
    },
    pushAddress(state, action) {
      state.address.push(action.payload);
    },
  },
});

export { actions as positionsActions };
export { reducer as positionsReducer };
