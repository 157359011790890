/* eslint-disable max-classes-per-file */

// START --fix bugs parser Data - 2023/10/06
export class Member {
  constructor() {
    this.field1 = 'detail';
    this.field2 = 'category';
    this.field3 = 'paymentstatuscode';
    this.field4 = 'name';
    this.field5 = 'id';
    this.field6 = 'disabled';
    this.field7 = 'email';
    this.field8 = 'groupName';
    this.field9 = 'representationId';
    this.field10 = 'userpaymentid';
    this.field11 = 'billingmethod';
    this.field12 = 'createDate';
    this.field13 = 'password';
    this.field14 = 'power';
    this.field15 = 'batteryLevel';
    this.field16 = 'accquisitionTime';
    this.field17 = 'groupId';
    this.field18 = 'rateplan';
    this.field19 = 'paymentmethod';
    this.field20 = 'paymentMethod2';
    this.field21 = 'paymentspan';
    this.field22 = 'updateDate';
    this.field23 = 'providerType';
    this.field25 = 'price';
    this.field27 = 'lastBillingDate';
    this.field28 = 'nextBillingDate';
    this.field29 = 'phone';
    this.field30 = 'withdrawal_date';
    this.field31 = 'note';
  }
}
export class DetailMember {
  constructor() {
    this.field1 = 'category1';
    this.field3 = 'status';
    this.field4 = 'name';
    this.field5 = 'id';
    this.field6 = 'disabled';
    this.field7 = 'email';
    this.field8 = 'endDate';
    this.field9 = 'representationId';
    this.field10 = 'typeApp';
    this.field11 = 'note';
    this.field12 = 'createDate';
    this.field13 = 'updatedate';
    this.field14 = 'category';
    this.field15 = 'password';
    this.field16 = 'next_billing_date';
    this.field17 = 'last_billing_date';
  }
}
export class PaymentStatus {
  constructor() {
    this.newApplication = 'newApplication';
    this.continousBilling = 'continousBilling';
    this.reminder = 'reminder';
    this.cancelationLaw = 'cancelationLaw';
  }
}

export class CategoryAccount {
  constructor() {
    this.accountGeneral = 0;
    this.accountSpecial = 1;
  }
}

export class ApplicationType {
  constructor() {
    this.mail = 'Mail';
    this.line = 'LINE';
    this.ios = 'IOS';
  }
}
export class TypeApp {
  constructor() {
    this.mail = 0;
    this.line = 1;
    this.ios = 2;
  }
}
export class StatusUsage {
  constructor() {
    this.inUse = 'inUse';
    this.stopped = 'stopped';
  }
}
export class BillingMethod {
  constructor() {
    this.inApp = 'editInApp';
    this.special = 'editSpecial';
    this.outside = 'editOutside';
  }
}

// START --fix bugs UAT341 - 2023/10/06
export class RatePlan {
  constructor() {
    this.basic = 'BASIC';
    this.prenium = 'PREMIUM';
  }
}
// END --fix bugs UAT341 - 2023/10/06

export class PaymentMethod {
  constructor() {
    this.inApp = 'inApp';
    this.credit = 'credit';
    this.bank = 'bank';
    this.other = 'other';
  }
}

// export class Payment2 {
//   constructor() {
//     this.representative = 'representativePayment';
//     this.payYourSelf = 'payYourSelf';
//     this.bank = 'bank';
//   }
// }

export class PaymentSpan {
  constructor() {
    this.singleMonth = 'singleMonth';
    this.advanceMonth = 'advanceMonth';
    this.advanceYear = 'advanceYear';
    this.firstTime = 'firstTime';
  }
}

export class Type {
  constructor() {
    this.local = 'local';
    this.google = 'google';
    this.line = 'line';
    this.yahoo = 'yahoo';
  }
}
