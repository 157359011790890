/* eslint-disable no-restricted-syntax */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  IconButton, Typography, Snackbar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import Header from '../tools/components/Header';
import { useTranslation } from '../../common/components/LocalizationProvider';
import ConfirmationPopup from '../tools/components/ComfirmationPopup';
import ModalConfirm from '../modal/ModalConfirm';
import { helperActions, setMessage } from '../../store';
import QrCode from '../components/QRCode';
import fetchApi from '../FetchApi';
import {
  // eslint-disable-next-line import/named
  RatePlan, CategoryAccount, TypeApp, DetailMember, PaymentStatus,
} from '../common/DefineClass';
import NewTable from '../components/NewTable';
import {
  convertWidth, valueTranslate, DropdownEdit, encrypted,
} from '../common/utils';
import DatePickerEditor from '../components/DatePickerEditor';
import {
  billingMethod, paymentMethod, typeApplication, categoryType,
} from '../common/const';
import Vectorback from '../../resources/images/Vectorback.png';
import ModalSuccess from '../modal/ModalSuccess';
import DetailsEditDateEditor from './components/DetailsEditDateEditor';

const userModel = new DetailMember();
const categoryAccount = new CategoryAccount();
const typeApp = new TypeApp();
const paymentStatus = new PaymentStatus();
const ratePlan = new RatePlan();

const useStyle = makeStyles((theme) => ({
  qrcodeBox: {
    backgroundColor: '#FFFFFF',
    width: 208,
    height: 241,
    marginTop: 70,
    boxShadow: '3px 4px 5px 0px #D3D3D3',
  },
  backBtn: {
    backgroundColor: '#fff',
    display: 'flex',
    color: '#7f7f7f',
    border: '1px solid #7f7f7f',
    width: '123px',
    height: '48px',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '30px',
    marginLeft: 30,
    padding: '20px',
  },
  createBtn: {
    width: '123px',
    backgroundColor: '#02AA9E',
    display: 'flex',
    color: 'white',
    height: '48px',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '30px',
    marginLeft: 30,
    padding: '20px',
    '&:disabled': {
      backgroundColor: '#C0C0C0',
    },
    '&:hover': {
      backgroundColor: '#02AA9EB2',
      color: 'white',
    },
  },
  invitationCode: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#222A35',
  },
  configTable: {
    borderRadius: '0px !important',
    border: '1px solid #C2C2C2 !important',
    height: '600px',
  },
}));

// declare column percentage
const listColumPercent = {
  category: 8,
  status: 10,
  name: 12,
  userId: 14,
  disabled: 7,
  email: 20,
  password: 10,
  representId: 7,
  startDate: 8,
  power: 8,
  battery: 10,
  longlat: 25,
  accquisitionTime: 15,
  actions: 6,
  ratePlan: 15,
  expirationDate: 10,
};

const DetailsEditTable = () => {
  const { control, watch, setFocus, formState: { isValid } } = useForm({
    mode: 'onChange',
  });

  const classes = useStyle();
  const gridRef = useRef();
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const boxRef = useRef();
  const { id } = useParams();

  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [changedRows, setChangedRows] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [manager, setManager] = useState(undefined);

  const [gridApi, getGridApi] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/admin/home/list');
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  // const [statusTime, setStatusTime] = useState(0);
  // const handleChangeRadioOption = (event, params) => {
  //   setStatusTime(parseInt(event.target.value, 10));
  //   params.api.refreshCells();
  // };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const onCellValueChanged = useCallback((e) => {
    const dataOnRow = e.data;
    const oldItemIndex = e.rowIndex;

    const copyChangedRows = [...changedRows];
    const copyUsers = [...users];
    copyChangedRows[oldItemIndex] = dataOnRow;
    copyUsers[oldItemIndex] = dataOnRow;

    setChangedRows(copyChangedRows);
    setUsers(copyUsers);
  }, [changedRows, users]);

  const onCellEditRequest = useCallback((e) => {
    const dataOnRow = e.data;
    const { field } = e.colDef;
    const { newValue } = e;
    const oldItem = users.find((row) => row.id === dataOnRow.id);

    const newItem = { ...oldItem };
    newItem[field] = newValue;
    dispatch(helperActions.updateData(newItem));
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi(`/api/admin/members/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const userList = await response.json();
        const newData = [];
        const transformedUsersData = userList.map((data) => ({
          ...data,
          billingmethod: data.billing_method,
          userpaymentid: data.user_payment_id,
          lastbillingdate: data.last_billing_date,
          nextbillingdate: data.next_billing_date,
          updatedate: data.update_date,
          paymentmethod: data.payment_method,
          paymentnumber: data.payment_number,
          paymentspan: data.payment_span,
          rateplan: data.rate_plan,
          historyList: `${data.latitude},${data.longitude}`,
        }));
        transformedUsersData?.forEach((val) => {
          const cond = newData.find((data) => data.id === val.id);
          if (!cond) newData.push(val);
        });
        const represent = newData.find((data) => data.role === 1);
        const nullArray = new Array(newData.length).fill(null);
        setManager(represent);
        setUsers(newData);
        setOriginalUsers(newData);
        setChangedRows(nullArray);
      }
    };

    if (id) fetchData();
  }, [id]);

  const differentElements = changedRows.filter((element1) => {
    const existsInArray2 = originalUsers.some((element2) => JSON.stringify(element1) === JSON.stringify(element2));

    return !existsInArray2;
  });

  const result = differentElements.filter((element) => element !== null);

  const representGroupId = users.find((user) => user.id === user.representationId)?.groupId;
  let hasErrors = false;
  const updateData = result.map((item) => {
    const data = {
      id: item.id,
      name: item.name,
      email: item.email,
      payment_status_code: paymentStatus.newApplication,
      billingMethod: item.billingmethod,
      paymentMethod: item.paymentmethod,
      lastBillingDate: item.last_billing_date !== null ? moment(item.last_billing_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      nextBillingDate: item?.next_billing_date !== null ? moment(item?.next_billing_date).format('YYYY-MM-DD') : moment('9999/12/31').format('YYYY/MM/DD'),
      typeApp: item?.typeApp || 0,
      note: item.note,
      category: item.category,
      category1: item.category1,
      rateplan: ratePlan.basic,
      enddate: item.id !== 0
        ? moment.utc(item.nextbillingdate || moment()).valueOf()
        : moment.utc(item.endDate || moment(), 'YYYY/MM/DD').valueOf(),
    };

    if (item.id === 0) {
      data.groupId = representGroupId;
      data.password = item.password;
      data.managerId = id;
      if (item.category === 1) {
        data.paymentMethod = billingMethod[2].value;
        data.billingMethod = paymentMethod[2].value;
      } else {
        data.billingMethod = billingMethod[0].value;
        data.paymentMethod = paymentMethod[0].value;
      }
    }
    if (item.name && (item.id === 0 ? item.password : true) && item.email) {
      hasErrors = true;
    }
    return data;
  });

  const getRowHeight = useCallback((params) => (params.data.isNew ? 120 : 40), []);

  const detailsColumnDefs = [
    {
      headerName: t('categoryTableHeader1'),
      field: userModel.field1,
      width: convertWidth(10),
      valueFormatter: (params) => valueTranslate(t, params.data.category1, 'category1'),
      filter: false,
    },

    {
      headerName: t('categoryTableHeader2'),
      field: userModel.field2,
      width: convertWidth(8),
      valueFormatter: (params) => valueTranslate(t, params.data.role, 'role'),
      filter: false,
    },
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      filter: false,
      field: userModel.field14,
      valueFormatter: (params) => {
        const fieldValue = params.data.isNew ? params.data.category : params.data[userModel.field14];
        return params.data.isNew
          ? valueTranslate(t, fieldValue, 'categoryAccount')
          : valueTranslate(t, fieldValue, 'categoryAccount');
      },
    },

    {
      headerName: t('nameTableHeader'),
      width: convertWidth(listColumPercent.name),
      field: userModel.field4,
      editable: true,
      filter: false,
    },
    {
      headerName: t('userId'),
      width: convertWidth(6),
      field: userModel.field5,
      filter: false,
    },
    {
      headerName: t('userStatusTableHeader'),
      width: convertWidth(listColumPercent.disabled),
      field: userModel.field6,
      filter: false,
      cellRenderer: (params) => {
        if (!params.data.isNew) {
          return valueTranslate(t, params.value, 'account');
        }
        return valueTranslate(t, false, 'account');
      },
    },
    {
      headerName: t('userEmailTableHeader'),
      width: convertWidth(listColumPercent.email),
      field: userModel.field7,
      editable: true,
      filter: false,
    },
    {
      headerName: t('passwordTableHeader'),
      width: convertWidth(listColumPercent.password),
      field: userModel.field15,
      filter: false,
      editable: ((params) => {
        if (params.data[userModel.field5] === 0) {
          return true;
        }
        return false;
      }),
    },
    {
      headerName: t('representIdTableHeader'),
      width: convertWidth(listColumPercent.representId),
      field: userModel.field9,
      filter: false,
      cellRenderer: (params) => {
        const { data } = params;
        const { representationId, managedUserId } = data;
        return representationId || managedUserId;
      },
    },
    {
      headerName: t('applicationType'),
      field: userModel.field10,
      filter: false,
      width: convertWidth(7),
      valueFormatter: (params) => valueTranslate(t, params.data.typeApp, 'applicationType'),
      editable: ((params) => {
        if (params.data[userModel.field5] === 0) {
          return true;
        }
        return false;
      }),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t('mail'), value: typeApp.mail },
          { name: t('line'), value: typeApp.line },
          { name: t('ios'), value: typeApp.ios },
        ],
      },
    },
    {
      headerName: t('startDateTableHeader'),
      width: convertWidth(listColumPercent.startDate),
      field: userModel.field17,
      filter: false,
      valueFormatter: (params) => (params?.value ? moment(params.value).local().format('YYYYMMDD') : moment().local().format('YYYYMMDD')),
      cellEditor: DatePickerEditor,
      editable: true,
    },
    {
      headerName: t('endDateTableHeader'),
      width: convertWidth(listColumPercent.expirationDate),
      field: userModel.field16,
      filter: false,
      valueFormatter: (params) => (moment(params.value).format('YYYY/MM/DD') === moment('9999/12/31').format('YYYY/MM/DD') ? t('unLimited') : moment(params.value).local().format('YYYYMMDD')),
      cellEditor: DetailsEditDateEditor,
      editable: true,
    },
    {
      headerName: t('note'),
      width: convertWidth(listColumPercent.email),
      field: userModel.field11,
      editable: true,
      filter: false,
    },
    {
      headerName: t('updateDateTableHeader'),
      width: convertWidth(listColumPercent.startDate),
      field: userModel.field13,
      filter: false,
      valueFormatter: (params) => (params.value ? moment(params.value).local().format('YYYYMMDD') : ''),
      editable: ((params) => {
        if (params.data[userModel.field5 === 0]) {
          return true;
        }
        return false;
      }),
    },
    {
      headerName: t('sharedDelete'),
      field: 'actions',
      filter: false,
      width: convertWidth(listColumPercent.actions),
      cellRenderer: ((params) => (
        params.data[userModel.field5] ? (
          <Typography>-</Typography>
        ) : (
          <IconButton
            onClick={() => {
              const rowDisplay = [];
              params.api?.applyTransaction({ remove: [params.data] });
              params.api?.forEachNode((node) => {
                rowDisplay.push(node.data);
              });
              setUsers(rowDisplay);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )
      )),
    },
  ];

  useEffect(() => {
    const copyChangedRows = [...changedRows];
    const differentElements = copyChangedRows.filter((element1) => {
      const existsInArray2 = users.some((element2) => JSON.stringify(element1) === JSON.stringify(element2));
      return !existsInArray2;
    });

    const result = differentElements.filter((element) => element !== null);
    result?.forEach((item) => {
      const index = copyChangedRows.findIndex((element) => JSON.stringify(element) === JSON.stringify(item));
      if (index !== -1) {
        copyChangedRows.splice(index, 1);
      }
    });

    setChangedRows(copyChangedRows);
  }, [users]);

  let messageSnackBar = 'editvalidate';

  const hasEmptyName = updateData.some((obj) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const emailRegex = /^(?!.*\.\.)(?!.*^-)(?!.*-$)(?!^\.)(?!\.$)([a-zA-Z0-9]+([._+-][a-zA-Z0-9]+)*)@([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.[a-zA-Z]{2,})$/;
    const currentDate = new Date().toISOString().split('T')[0];
    if (obj.name === '' || obj.email === '') {
      return true;
    }
    if (!emailRegex.test(obj.email)) {
      messageSnackBar = 'messageEmailValid';
      return true;
    }
    if (obj.id === 0) {
      if (!passwordRegex.test(obj.password)) {
        messageSnackBar = 'messagePasswordValid';
        return true;
      }
      if (obj?.lastBillingDate < currentDate) {
        messageSnackBar = 'validateEditAccountMember2';
        return true;
      }
      if (obj?.nextBillingDate) {
        if (obj.nextBillingDate < currentDate || obj.lastBillingDate >= obj.nextBillingDate) {
          messageSnackBar = 'validateEditAccountMember';
          return true;
        }
      }
    }

    return false;
  });
  const handleSubmit = async () => {
    if (hasEmptyName) {
      handleOpenSnackbar();
    } else {
      // Password encryption
      updateData.forEach((item) => item.password = encrypted(item.password));

      const response = await fetchApi('/api/admin/payment_status/settings_v2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
      if (response.ok) {
        const result = await response.json();
        if (result.success.length > 0) {
          dispatch(helperActions.setPathname(location.pathname));
          setOpenSuccess(true);
        } else {
          dispatch(setMessage(result?.fail[0]?.reason));
        }
      }
    }
  };

  const dataTable = {
    getGridApi,
    newCustomeColumn: detailsColumnDefs,
    listData: users,
    tableRef: gridRef,
    floatingFilter: false,
    floatingFilterComponent: false,
    boxRef,
    filter: false,
    className: classes.configTable,
    floatingFilterOff: true,
    onCellEditRequest,
    singleClickEdit: true,
    suppressRowClickSelection: true,
    rowSelection: 'single',
    onCellValueChanged,
  };

  const requiredFields = updateData.length > 0 && updateData?.every((item) => item.name && item.email && (item.id === 0 ? item.password : true));

  return (
    <>
      <Header
        listPath={`home/list/detailTableHeader/${id}/addAccountMemberPath`}
        noDescription
        noBack
        topic="Create a manager account"
      />
      <ModalConfirm
        openModal={openModal}
        autoClose={false}
        handleClose={handleCloseModal}
        action={() => setOpenModal(false)}
        propsModal={{
          confirm: t('confirmCreateAccountMember'),
          subTitle: t('confirmCreateAccountMember1'),
          titleCancel: t('cancelAddMember'),
          titleContinue: t('continueAccount'),
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 20 }}>
        <Button
          sx={{ border: '1px solid #02AA9E', color: '#02AA9E', padding: 2, mb: 2 }}
          onClick={() => {
            const copyUsers = [...users];
            const copyOriginalUsers = [...originalUsers];
            const newUser = {
              category: categoryAccount.accountSpecial,
              name: '',
              id: 0,
              disabled: null,
              email: '',
              password: '',
              next_billing_date: moment('9999/12/31').format('YYYY/MM/DD'),
              representationId: manager.representationId,
              accquisitionTime: '',
              createDate: null,
              endDate: null,
              actions: '',
              note: '',
              isNew: true,
              typeApp: typeApp.mail,
              category1: 0,
              role: 2,
            };
            copyUsers.push(newUser);
            setChangedRows((prev) => [...prev, newUser]);
            setUsers(copyUsers);
            setOriginalUsers(copyOriginalUsers);
          }}
        >
          <AddCircleIcon />
          <Typography ml={1}>{t('textAddAccountMember')}</Typography>
        </Button>
        <Button disabled={!requiredFields} className={classes.createBtn} onClick={handleSubmit}>
          {t('sharedSave')}
        </Button>
        <Button className={classes.backBtn} onClick={() => setOpenModal(true)}>
          <img style={{ marginRight: '10px' }} src={Vectorback} alt="icon back" />
          {t('back')}
        </Button>
      </div>
      <NewTable {...dataTable} />
      <div className={classes.qrcodeBox}>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <Typography sx={{ marginTop: 2 }} className={classes.invitationCode}>{t('qrcode')}</Typography>
          <QrCode qrCodeData={manager?.inviteCode} />
          <Typography className={classes.invitationCode}>{t('invitationCode')}</Typography>
          <Typography sx={{ fontSize: 18 }} fontSize={26}>{manager?.inviteCode}</Typography>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        message={t(messageSnackBar)}
        action={action}
      />
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={() => {
          setOpenSuccess(false);
          navigate('/admin/home/list');
        }}
        title={t('messageSuccess')}
      />
    </>
  );
};

export default DetailsEditTable;
