// eslint-disable-next-line import/prefer-default-export
export const billingMethod = [
  { name: 'inApp', value: 1 },
  { name: 'outside', value: 2 },
  { name: 'special', value: 3 },
];

export const paymentMethod = [
  { name: 'inApp', value: 1 },
  { name: 'credit', value: 2 },
  { name: 'bank', value: 3 },
];

export const typeApplication = [
  { name: 'Mail', value: 0 },
  { name: 'LINE', value: 1 },
  { name: 'IOS', value: 2 },
];

export const categoryType = [
  { name: 'General', value: 0 },
  { name: 'Special', value: 1 },
];
