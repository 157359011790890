import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Rating } from '@mui/material';

const useStyles = makeStyles({
  box: {
    width: '317px',
    height: '469px',
    padding: '24px',
    color: '#6C6A6A',
    border: '1px solid #00000000',
    borderRadius: '14px',
    boxShadow: '0px 2px 15px #61728B42',
    opacity: 1,
    overflow: 'hidden',
  },
  rating: {
    fontSize: '15px',
    color: '#009380',
    paddingLeft: '10px',
  },
});

const AccuracyPopupLocationSharing = ({ acc }) => {
  const classes = useStyles();

  const [accuracy, setAccuracy] = useState(1);

  useEffect(() => {
    const updateAccuracy = () => {
      if (acc === null) {
        setAccuracy(0);
      } else if (acc === 100) {
        setAccuracy(1);
      } else if (acc >= 10 && acc <= 100) {
        setAccuracy(2);
      } else if (acc >= 0 && acc <= 10) {
        setAccuracy(3);
      } else {
        setAccuracy(0);
      }
    };
    updateAccuracy();
  }, [acc]);

  return (
    <div style={{ display: 'flex' }}>
      <Rating name="read-only" value={accuracy} max={3} readOnly className={classes.rating} />
    </div>
  );
};

export default AccuracyPopupLocationSharing;
