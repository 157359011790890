import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const AnalysisSelect = () => {
  const t = useTranslation();
  const [selectInterval, setSelectInterval] = useState('');

  const handleChange = (event) => {
    setSelectInterval(event.target.value);
  };
  return (
    <FormControl fullWidth style={{ marginTop: '30px' }}>
      <InputLabel>{t('analysisSelectYear')}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectInterval}
        label="interval"
        onChange={handleChange}
        sx={{ backgroundColor: '#7f7f7f', color: '#ffffff', minWidth: '30%', width: '200px' }}
      >
        <MenuItem value={10}>2021</MenuItem>
        <MenuItem value={20}>2022</MenuItem>
        <MenuItem value={30}>2023</MenuItem>
      </Select>
    </FormControl>
  );
};

export default AnalysisSelect;
