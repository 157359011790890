/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Paper,
  Switch,
  Toolbar,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  Popover,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Badge,
  AppBar,
  Drawer,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListIcon from '@mui/icons-material/ViewList';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MenuIcon from '@mui/icons-material/Menu';
// import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import ReloadIcon from '../resources/images/iconXD/Reload.svg';
import DevicesList from './DevicesList';
import MapView from '../map/core/MapView';
import MapSelectedDevice from '../map/main/MapSelectedDevice';
import MapAccuracy from '../map/main/MapAccuracy';
import MapGeofence from '../map/main/MapGeofence';
import MapCurrentLocation from '../map/MapCurrentLocation';
// import BottomMenu from '../common/components/BottomMenu';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMap from '../map/main/PoiMap';
import MapPadding from '../map/MapPadding';
// import StatusCard from '../common/components/StatusCard';
import { devicesActions, helperActions } from '../store';
import MapDefaultCamera from '../map/main/MapDefaultCamera';
import usePersistedState from '../common/util/usePersistedState';
import MapLiveRoutes from '../map/main/MapLiveRoutes';
import { useDeviceReadonly } from '../common/util/permissions';
import MapPositions from '../map/MapPositions';
import MapDirection from '../map/MapDirection';
import MapOverlay from '../map/overlay/MapOverlay';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import MapScale from '../map/MapScale';
import MapNotification from '../map/notification/MapNotification';
import EventsDrawer from './EventsDrawer';
import useFeatures from '../common/util/useFeatures';
import DeviceDrawer from './DeviceDrawer';
import MenuPilot from '../common/components/MenuPilot';
import MapPopUp from '../map/MapPopUp';
import useFilter from './useFilter';
import Alarm from '../resources/alarm.mp3';

import { useAttributePreference } from '../common/util/preferences';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    '& .maplibregl-ctrl-top-right': {
      display: 'none',
    },
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: theme.spacing(8),
    height: '100%',
    zIndex: 5,
    width: theme.dimensions.drawerWidthDesktop,
    bottom: theme.dimensions.bottomBarHeight,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  toolbarContainer: {
    zIndex: 4,
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  statusCard: {
    position: 'fixed',
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${
        theme.dimensions.bottomBarHeight
      }px)`,
    },
    transform: 'translateX(-50%)',
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(10),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
    zIndex: 4,
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.6)',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  bottomMenu: {
    position: 'fixed',
    left: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    zIndex: 4,
    width: theme.dimensions.drawerWidthDesktop,
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
  switchReplay: {
    position: 'absolute',
    right: '10px',
    padding: '10px',
    display: 'flex',
    top: '70px',
    '& .MuiSwitch-track': {
      backgroundColor: '#EFEFEF !important',
      opacity: '1 !important',
      borderRadius: '10px',
      borderTop: '4px inset #80808030',
      boxShadow: 'inset 0px 7px 3px #00000029',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FCFCFC',
      boxShadow: '0px 7px 3px #00000029',
    },
  },
  update: {
    height: '46px',
    width: '46px',
    position: 'absolute',
    right: '15px',
    padding: '10px',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    top: '120px',
    boxShadow: '0px 0px 5px grey',
    // remove hover MUI
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  appBar: {
    backgroundColor: 'white',
  },
}));

const MainPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const t = useTranslation();
  const location = useLocation();
  const deviceReadonly = useDeviceReadonly();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const path = '/';
  useEffect(() => {
    if (path) {
      navigate('/admin/home/list');
    }
  }, [path]);

  const features = useFeatures();

  //  const [mapOnSelect] = usePersistedState('mapOnSelect', false); // Viet khong dong cham
  const mapOnSelect = useAttributePreference('mapOnSelect', true); // v5.6

  const [mapGeofences] = usePersistedState('mapGeofences', true);
  const [mapLiveRoutes] = usePersistedState('mapLiveRoutes', false);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  // const positions = useSelector((state) => state.positions.items); // Viet khong dong cham
  const positions = useSelector((state) => state.session.positions); // v5.6
  const position = useSelector((state) => state.session.positions); // v5.
  const [filteredPositions, setFilteredPositions] = useState([]);
  const audioRef = useRef();
  /* // Viet khong dong cham
  const selectedPosition = filteredPositions.find(
    (position) => selectedDeviceId && position.deviceId === selectedDeviceId,
  ); */
  const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId); // v5.6

  const groups = useSelector((state) => state.groups.items);
  const devices = useSelector((state) => state.devices.items);

  const [filteredDevices, setFilteredDevices] = useState([]); // Viet khong dong cham

  const [filterKeyword, setFilterKeyword] = useState(''); // Viet khong dong cham
  const [filterStatuses, setFilterStatuses] = useState([]); // Viet khong dong cham
  const [filterGroups, setFilterGroups] = useState([]); // Viet khong dong cham
  /* v5.6 */
  const [keyword, setKeyword] = useState('');
  const [filter, setFilter] = usePersistedState('filter', {
    statuses: [],
    groups: [],
  });

  const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
  const [filterMap, setFilterMap] = usePersistedState('filterMap', false);

  const filterRef = useRef();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  // const [devicesOpen, setDevicesOpen] = useState(false); // Viet khong dong cham
  const [devicesOpen, setDevicesOpen] = useState(desktop); // v5.6
  const [eventsOpen, setEventsOpen] = useState(false);
  const [alarmOn, setAlarmOn] = useState(true);

  // state of the switch history
  const [todayHistory, setTodayHistory] = useState(false);

  const eventHandler = useCallback(() => setEventsOpen(true), [setEventsOpen]); // Viet khong dong cham
  // const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]); // v5.6

  const eventsAvailable = useSelector((state) => !!state.events.items.length);
  const user = useSelector((state) => state.session.user);
  const turnOffDrawer = useSelector((state) => state.helper.active);

  // hàm giả sử cấp quyền cho client

  useEffect(() => {
    const deviceArr = Object.values(devices);
    if (user.phone !== deviceArr[0]?.phone) {
      if (deviceArr.length !== 0) {
        const identify = user.phone.split(' ');
        const newDevice = async () => {
          fetch('/api/devices', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              uniqueId: identify[identify.length - 1],
              name: user.name,
              phone: user.phone,
            }),
          });
        };
        newDevice(devices);
      }
    }
  }, []);

  const handleClose = () => {
    setDevicesOpen(!devicesOpen);
  };

  useEffect(() => setDevicesOpen(desktop), [desktop]);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  useEffect(() => {
    const deviceArr = Object.values(devices);
    deviceArr.find((device) => {
      const condition = device?.attributes.userId === user?.id;
      if (condition) {
        audioRef.current.loop = true;
        // eslint-disable-next-line no-unused-expressions
        device.emergency ? audioRef?.current?.play() : audioRef?.current?.pause();
        setAlarmOn(false);
      }
      return condition;
    });
  }, [devices, audioRef, alarmOn]);

  const onClick = useCallback(
    (_, deviceId) => {
      dispatch(devicesActions.select(deviceId));
    },
    [dispatch],
  );

  useFilter(keyword, filter, filterSort, filterMap, positions, setFilteredDevices, setFilteredPositions); // v5.6

  // hàm cho nút switch history
  const showTodayHistory = async (deviceId) => {
    const from = moment().startOf('day').toISOString();
    const to = moment().endOf('day').toISOString();
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/positions?${query.toString()}`);
    if (response.ok) {
      const positions = await response.json();
      console.log('🚀 ~ file: MainPage.js:340 ~ showTodayHistory ~ response.json():', response.json());
      if (positions.length) {
        // setShow(false);
      } else {
        throw Error(t('sharedNoData'));
      }
    } else {
      throw Error(await response.text());
    }
  };

  // filter the device list and sort it by last update time if required.
  useEffect(() => {
    const filtered = Object.values(devices)
      .filter(
        (device) => !filterStatuses.length || filterStatuses.includes(device.status),
      )
      .filter(
        (device) => !filterGroups.length || filterGroups.includes(device.groupId),
      )
      .filter((device) => `${device.name} ${device.uniqueId}`
        .toLowerCase()
        .includes(filterKeyword.toLowerCase()));
    if (filterSort === 'lastUpdate') {
      filtered.sort((device1, device2) => {
        const time1 = device1.lastUpdate
          ? moment(device1.lastUpdate).valueOf()
          : 0;
        const time2 = device2.lastUpdate
          ? moment(device2.lastUpdate).valueOf()
          : 0;
        return time2 - time1;
      });
    }
    setFilteredDevices(filtered);
    setFilteredPositions(
      filterMap
        ? filtered.map((device) => positions[device.id]).filter(Boolean)
        : Object.values(positions),
    );
  }, [
    devices,
    positions,
    filterKeyword,
    filterStatuses,
    filterGroups,
    filterSort,
    filterMap,
  ]);

  return (
    <>
      <div className={classes.root}>
        <audio
          ref={audioRef}
          src={Alarm}
          muted={alarmOn}
        />
        <Drawer
          variant="temporary"
          open={turnOffDrawer}
          anchor="right"
          onClose={() => {
            dispatch(helperActions.unActive());
          }}
        >
          {desktop ? <MenuPilot /> : <MenuPilot style={{ width: '200px' }} />}
        </Drawer>
        <AppBar className={classes.appBar}>
          <Toolbar style={{ position: 'relative', padding: 0 }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ position: 'absolute', right: '14.22px', padding: 0 }}
              onClick={() => {
                dispatch(helperActions.active());
              }}
            >
              <MenuIcon style={{ color: '#6C6A6A', fontSize: '30px' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MapView>
          <MapPopUp />
          <MapOverlay />
          {mapGeofences && <MapGeofence />}
          <MapAccuracy />
          {mapLiveRoutes && <MapLiveRoutes />}
          <MapPositions
            positions={filteredPositions}
            onClick={onClick}
            showStatus
          />
          {selectedPosition && selectedPosition.course && (
            <MapDirection position={selectedPosition} />
          )}
          <MapDefaultCamera />
          <MapSelectedDevice />
          <PoiMap />
        </MapView>
        <MapScale />
        <MapCurrentLocation />
        <MapGeocoder />
        {!features.disableEvents && (
          <MapNotification enabled={eventsAvailable} onClick={eventHandler} />
        )}
        {desktop && (
          <MapPadding
            left={parseInt(theme.dimensions.drawerWidthDesktop, 10)}
          />
        )}
        <Switch
          className={classes.switchReplay}
          disabled={!selectedDeviceId}
          onChange={() => {
            setTodayHistory(!todayHistory);
            showTodayHistory(selectedDeviceId);
            navigate('/history');
          }}
        />
        <IconButton className={classes.update}>
          <img src={ReloadIcon} alt="reload" />
        </IconButton>
        {desktop && (
          <Button
            variant="contained"
            color={phone ? 'secondary' : 'primary'}
            classes={{ containedPrimary: classes.sidebarToggleBg }}
            className={classes.sidebarToggle}
            onClick={handleClose}
            disableElevation
          >
            <ListIcon />
            <div className={classes.sidebarToggleText}>{t('deviceTitle')}</div>
          </Button>
        )}
        <Paper
          square
          elevation={3}
          className={`${classes.sidebar} ${
            !devicesOpen && classes.sidebarCollapsed
          }`}
          style={{ display: desktop || 'none' }}
        >
          <Paper square elevation={3} className={classes.toolbarContainer}>
            <Toolbar className={classes.toolbar} disableGutters>
              {!desktop && (
                <IconButton edge="start" sx={{ mr: 2 }} onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <OutlinedInput
                ref={filterRef}
                placeholder={t('sharedSearchDevices')}
                value={filterKeyword}
                onChange={(event) => setFilterKeyword(event.target.value)}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => setFilterAnchorEl(filterRef.current)}
                    >
                      <Badge
                        color="info"
                        variant="dot"
                        invisible={
                          !filterStatuses.length && !filterGroups.length
                        }
                      >
                        <TuneIcon fontSize="small" />
                      </Badge>
                    </IconButton>
                  </InputAdornment>
                )}
                size="small"
                fullWidth
              />
              <Popover
                open={!!filterAnchorEl}
                anchorEl={filterAnchorEl}
                onClose={() => setFilterAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <div className={classes.filterPanel}>
                  <FormControl>
                    <InputLabel>{t('deviceStatus')}</InputLabel>
                    <Select
                      label={t('deviceStatus')}
                      value={filterStatuses}
                      onChange={(e) => setFilterStatuses(e.target.value)}
                      multiple
                    >
                      <MenuItem value="online">
                        {t('deviceStatusOnline')}
                      </MenuItem>
                      <MenuItem value="offline">
                        {t('deviceStatusOffline')}
                      </MenuItem>
                      <MenuItem value="unknown">
                        {t('deviceStatusUnknown')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>{t('settingsGroups')}</InputLabel>
                    <Select
                      label={t('settingsGroups')}
                      value={filterGroups}
                      onChange={(e) => setFilterGroups(e.target.value)}
                      multiple
                    >
                      {Object.values(groups).map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel>{t('sharedSortBy')}</InputLabel>
                    <Select
                      label={t('sharedSortBy')}
                      value={filterSort}
                      onChange={(e) => setFilterSort(e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="">{'\u00a0'}</MenuItem>
                      <MenuItem value="lastUpdate">
                        {t('deviceLastUpdate')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={filterMap}
                          onChange={(e) => setFilterMap(e.target.checked)}
                        />
                      )}
                      label={t('sharedFilterMap')}
                    />
                  </FormGroup>
                </div>
              </Popover>
              <IconButton
                onClick={() => navigate('/settings/device')}
                disabled={deviceReadonly}
              >
                <AddIcon />
              </IconButton>
              {desktop && (
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )}
            </Toolbar>
          </Paper>
          <div className={classes.deviceList}>
            <DevicesList devices={filteredDevices} />
          </div>
        </Paper>
        {desktop && (
          <div className={classes.bottomMenu}>{/* <BottomMenu /> */}</div>
        )}
        {!features.disableEvents && (
          <EventsDrawer
            open={eventsOpen}
            onClose={() => setEventsOpen(false)}
          />
        )}
        {selectedDeviceId && (
          <div className={classes.statusCard}>
            {/* <StatusCard
              deviceId={selectedDeviceId}
              onClose={() => dispatch(devicesActions.select(null))}
            /> */}
          </div>
        )}
      </div>
      <div>{!desktop && (<div><DeviceDrawer deviceId={selectedDeviceId} height={250} /></div>)}</div>

    </>
  );
};

export default MainPage;
