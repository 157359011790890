/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-undef */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import maplibregl from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  TextField, Typography, Button, useMediaQuery,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/styles';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PartyMode } from '@mui/icons-material';
import Header from '../Header';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { useCatchCallback, useEffectAsync } from '../../../../reactHelper';
import MapView, { map } from '../../../../map/core/MapView';
import MapDefaultCamera from '../../../../map/main/MapDefaultCamera';
import MapCurrentLocation from '../../../../map/MapCurrentLocation';
import MapGeocoder from '../../../../map/geocoder/MapGeocoder';
import TableConfig from '../../../components/TableConfig';
import { errorsActions, geofencesActions } from '../../../../store';
import ComfirmationPopup from '../ComfirmationPopup';
import {
  circleFormat,
  geofenceToFeature, geometryToArea, getCoordinatesArea, polygonToCircle, reverseCoordinates, zoomInToArea,
} from '../../../../map/core/mapUtil';
import CircleMode from './CircleMode';
import theme from '../../../../map/draw/theme';
import NewTable from '../../../components/NewTable';
import fetchApi from '../../../FetchApi';
import '../../../../styles/components/SetupArea.css';
import Vectorback from '../../../../resources/images/Vectorback.png';
import ModalConfirm from '../../../modal/ModalConfirm';
import ModalSuccess from '../../../modal/ModalSuccess';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    // polygon: true,
    // line_string: true,
    // trash: true,
  },
  modes: {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
  },
  userProperties: false,
  styles: [...theme, {
    id: 'gl-draw-title',
    type: 'symbol',
    filter: ['all'],
    layout: {
      'text-field': '{user_name}',
      'text-font': ['Roboto Regular'],
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  }],
});

const useStyles = makeStyles({
  configTable: {
    height: '600px',
  },
  saveBtn: {
    width: '100px',
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
    },
    '&:disabled': {
      backgroundColor: 'gray',
      color: 'white',
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
  },
  title: {
    fontWeight: 'bold',
    margin: '20px 0 20px 0',
    '&::after': {
      content: '"*"',
      color: 'red',
    },
  },
  areaBtn: {
    width: '120px !important',
    height: '40px !important',
    backgroundColor: '#ffebad !important',
    color: 'black !important',
    border: '7px outset #ffebad !important',
  },
});

const EditArea = () => {
  const t = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const tableRef = useRef(null);
  const { id } = useParams();

  const isMobile = useMediaQuery('(max-width:600px)');
  const centerStyle = isMobile ? { marginBottom: '20px' } : {};

  const [item, setItem] = useState([{}]);
  const [drawBtn, setDrawBtn] = useState();
  const [updateName, setUpdateName] = useState('');
  const [userArr, setUserArr] = useState([]);
  const [gridApi, getGridApi] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSuccessDelete, setOpenSuccessDelete] = useState(false);
  const [areaButton, setAreaButton] = useState(false);
  const [dataTable, setDataTable] = useState({
    getGridApi,
    listChecked: userArr,
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    removeColumn: [1],
  });

  const geofences = useSelector((state) => state.geofences.items);

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // 0.5次開発 - DEVELOPMENT-260 - 2024/11/1 TKS DangNH: remove button area
  // class MyCustomControl {
  //   onAdd(map) {
  //     this.containerCir = document.createElement('button');
  //
  //     this.map = map;
  //
  //     this.containerCir.onclick = () => {
  //       const zoom = this.map.getZoom();
  //
  //       draw.changeMode('draw_circle', {
  //         initialRadiusInKm: 1 / 2 ** (zoom - 11),
  //       });
  //
  //       draw.delete('-96.5801808656236544.76489866786821');
  //     };
  //     this.containerCir.className =
  //       `mapbox-gl-draw_ctrl-draw-btn my-custom-control-cir ${classes.areaBtn}`;
  //
  //     this.content = document.createElement('div');
  //     this.content.innerText = t('areaBtn');
  //     this.mainContainer = document.createElement('div');
  //
  //     this.mainContainer.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
  //     this.mainContainer.appendChild(this.containerCir);
  //     this.containerCir.appendChild(this.content);
  //
  //     return this.mainContainer;
  //   }
  //
  //   onRemove() {
  //     // this.container.parentNode.removeChild(this.container);
  //     this.mainContainer.remove();
  //     this.map = undefined;
  //   }
  // }
  // const myCustomControl = new MyCustomControl();

  const refreshGeofences = useCatchCallback(async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi(`/api/admin/geofences/linktouser/${id}`);
      if (response.ok) {
        const responseData = await response.json();
        setItem(responseData);
        setUpdateName(responseData[0].name);
        setUserArr(responseData[0].userIds);
        setDataTable({
          getGridApi,
          listChecked: responseData[0].userIds,
          floatingFilter: true,
          showPagination: true,
          className: classes.configTable,
          removeColumn: [1],
        });
      } else {
        throw Error(await response.text());
      }
    };
    if (id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    refreshGeofences();
    map.addControl(draw);
    return () => {
      map.removeControl(draw);
    };
    // 0.5次開発 - DEVELOPMENT-260 - 2024/11/1 TKS DangNH: remove function button area
    // map.addControl(myCustomControl, 'bottom-right');
    // setDrawBtn(myCustomControl);
    // return () => {
    //   map.removeControl(myCustomControl);
    //   map.removeControl(draw);
    // };
  }, [map, draw, location]);

  useEffect(() => {
    const onDrawCreate = async (event) => {
      const copyItem = { ...item[0] };
      const feature = event.features[0];
      draw.deleteAll();
      draw.add(feature);
      copyItem.area = polygonToCircle(geometryToArea(feature.geometry));
      setItem([copyItem]);
      setAreaButton(false);
    };

    map.on('draw.create', onDrawCreate);
    return () => {
      map.off('draw.create', onDrawCreate);
    };
  }, [draw, map]);

  useEffect(() => {
    draw.deleteAll();
    if (item[0]?.area) {
      const areaFocus = zoomInToArea(item[0]?.area);
      map.fitBounds(areaFocus[0], areaFocus[1]);
      draw.add(geofenceToFeature(theme, item[0]));
    }
  }, [geofences, item]);

  useEffect(() => {
    const listener = async (event) => {
      const feature = event.features[0];
      if (item[0]?.area) {
        const copyItem = { ...item[0] };
        copyItem.area = polygonToCircle(geometryToArea(feature.geometry));
        setItem([copyItem]);
      }
    };

    map.on('draw.update', listener);
    return () => map.off('draw.update', listener);
  }, [dispatch, item]);

  const handleDeleteArea = async () => {
    try {
      const response = await fetch(`/api/geofences/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        refreshGeofences();
        setOpenSuccessDelete(true);
        setOpenModal(false);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      dispatch(errorsActions.push(error.message));
    }
  };

  const handleUpdateArea = async () => {
    try {
      const response = await fetch('/api/admin/geofences/linktouser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: updateName,
          area: item[0].area,
          userIds: gridApi.getSelectedRows().map((data) => data.id),
          geofenceid: Number(id),
        }),
      });
      if (response.ok) {
        setOpenSuccess(true);
        dispatch(geofencesActions.update(item));
        map.removeControl(drawBtn);
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      dispatch(errorsActions.push(error.message));
    }
  };

  return (
    <div>
      <Header listPath="tools/createArea/edit" link={-1} noDescription noBack>
        <p className="title-page">{t('areaEditTitle')}</p>
        <div className="setup-head-item">
          <Typography>{t('titleSetupArea')}</Typography>
          <Button
            disabled={!updateName}
            className="button-creat-area"
            onClick={() => handleUpdateArea()}
          >
            <Typography>{t('sharedSave')}</Typography>
          </Button>
          <Button className="button-back" onClick={() => navigate(-1)}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
        </div>
      </Header>
      <div style={{
        margin: '10px',
        backgroundColor: '#FFFFFF',
        boxShadow: '5px 5px 20px 0px #D3D3D3',
        padding: '5px 30px 30px 30px',
      }}
      >
        <div className="name-field">
          <Typography variant="h5" className="title-field">{t('sharedAreaName')}</Typography>
          <TextField
            required
            className="text-field"
            placeholder={t('placeholderNameArea')}
            name="areaName"
            value={updateName}
            onChange={(event) => setUpdateName(event.target.value)}
            sx={{ color: 'black' }}
          />
          <div className="required-field">
            <p>{t('fieldRequired')}</p>
          </div>
        </div>
        <div className="edit-area">
          <div className="name-field">
            <Typography className="title-field" variant="h5">{t('sharedArea')}</Typography>
            <div className="required-field">
              <p>{t('fieldRequired')}</p>
            </div>
            <Typography>{t('areaText')}</Typography>
          </div>
          <div>
            <Button
              sx={{ marginRight: 2 }}
              startIcon={<FontAwesomeIcon icon={faTrashCan} size="lg" />}
              onClick={() => setOpenModal(true)}
              className="button-delete"
            >
              <div>{t('sharedDelete')}</div>
            </Button>
            <Button
              disabled={areaButton}
              className="button-delete"
              onClick={() => {
                setAreaButton(true);
                draw.changeMode('draw_circle', {
                  initialRadiusInKm: 1 / 2 ** (map.getZoom() - 11),
                });
              }}
            >
              <Typography>{t('areaBtn')}</Typography>
            </Button>
          </div>
        </div>
        <div style={{ flexGrow: 1, height: '500px', width: '1180px', boxShadow: '0px 4px 4px 0px #00000040' }}>
          <MapView>
            <MapDefaultCamera />
          </MapView>
          <MapCurrentLocation />
          <MapGeocoder />
        </div>
        <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '20px 0 20px 0' }}>{t('applicableObject')}</Typography>
        <NewTable {...dataTable} />
        <ModalConfirm
          openModal={openModal}
          handleClose={handleCloseModal}
          action={handleDeleteArea}
          propsModal={{
            confirm: t('titleConfirmDeleteArea'),
            titleCancel: t('sharedCancel'),
            titleContinue: t('sharedPerform'),
          }}
        />
        <ModalSuccess
          openSuccess={openSuccess}
          handleClose={() => {
            setOpenSuccess(false);
            navigate('/admin/tools/setupArea');
          }}
          title={t('createAreaSuccess')}
        />
        <ModalSuccess
          openSuccess={openSuccessDelete}
          handleClose={() => {
            setOpenSuccessDelete(false);
            navigate('/admin/tools/setupArea');
          }}
          title={t('deleteCompleted')}
        />
      </div>
    </div>
  );
};

export default EditArea;
