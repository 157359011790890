import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import '../../../styles/components/DetailsEditDateEditor.css';

export default memo(({ value, onValueChange, stopEditing, node, api }) => {
  const [radioChecked, setRadioChecked] = useState(value && moment(value).format('YYYY/MM/DD') !== '9999/12/31' ? 'true' : 'false');
  const [selectedDate, setSelectedDate] = useState(value && moment(value).isValid() ? new Date(value) : new Date());
  const t = useTranslation();
  const refDatePicker = useRef();
  const lastDateOfCalendar = moment('9999/12/31').toDate();

  const handleChange = (event) => {
    const newValue = event.target.value;
    setRadioChecked(newValue);
    if (newValue === 'false') {
      onValueChange(moment(lastDateOfCalendar).format('YYYY/MM/DD'));
    } else {
      const formattedDate = moment(selectedDate).format('YYYY/MM/DD');
      if (formattedDate === '9999/12/31') {
        setSelectedDate(new Date());
        onValueChange(moment().format('YYYY/MM/DD'));
      } else {
        onValueChange(formattedDate);
      }
    }
  };

  const onChange = (selectedDate) => {
    if (selectedDate) {
      setSelectedDate(selectedDate);
      onValueChange(moment(selectedDate).format('YYYY/MM/DD'));
      stopEditing();
    } else {
      onValueChange(moment(lastDateOfCalendar).format('YYYY/MM/DD'));
    }
  };

  useEffect(() => {
    node?.setRowHeight(120);
    api?.onRowHeightChanged();
  }, []);

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={radioChecked}
        name="radio-buttons-group"
        onChange={handleChange}
        className="date-editor"
      >
        <FormControlLabel
          onClick={() => onChange(null)}
          value="false"
          control={(
            <Radio icon={<RadioButtonUncheckedIcon className="radio-button" />} checkedIcon={<RadioButtonCheckedIcon className="radio-button-checked" />} />)}
          label={t('unLimited')}
        />
        <FormControlLabel
          value="true"
          control={(<Radio icon={<RadioButtonUncheckedIcon className="radio-button" />} checkedIcon={<RadioButtonCheckedIcon className="radio-button-checked" />} />)}
          label={t('limited')}
        />
      </RadioGroup>
      {radioChecked === 'true' && (
        <DatePicker
          className="date-picker"
          ref={refDatePicker}
          portalId="root"
          popperClassName="ag-custom-component-popup"
          onChange={onChange}
          selected={selectedDate}
          dateFormat="yyyyMMdd"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      )}
    </FormControl>
  );
});
