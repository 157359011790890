import React from 'react';
import {
  Card, Typography, Divider, Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles({
  paymentMethodInfor: {
    fontWeight: 'bold',
  },
  navigateStep: {
    backgroundColor: 'white',
    color: '#0eaa92',
    height: '30px',
    margin: '2px 10px 2px 50px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  disabledBtn: {
    backgroundColor: '#0eaa92',
    color: '#0eaa92',
    height: '30px',
    margin: '2px 10px 2px 50px',
  },
  title: {
    color: 'white', width: '190px',
  },
});

const CardCustom = (props) => {
  const { title, body } = props;
  return (
    <Card sx={{ margin: '20px 0px', borderRadius: '0px' }}>
      <div style={{ backgroundColor: '#0eaa92', height: '34px' }}>
        <div style={{ margin: '0px 10px', display: 'flex', alignItems: 'center' }}>{title}</div>
      </div>
      <div style={{ margin: '10px 20px 15px 20px ' }}>
        {body}
      </div>
    </Card>
  );
};

const ConfirmInformation = (props) => {
  const { dataCheck, endDate, setActiveStep } = props;
  const classes = useStyles();
  const t = useTranslation();

  const month = endDate.substring(0, 2);
  const day = endDate.substring(2);
  const transformedDate = month !== '00' && day !== '00' ? `${month}/${day}` : '';
  const fullName = `${dataCheck.ln} ${dataCheck.fn}`;
  // console.log({ dataCheck, endDate, fullName, transformedDate });

  return (
    <>
      <Typography sx={{ color: '#0eaa92' }}>{t('finalConfirmation')}</Typography>
      <CardCustom
        title={(
          <>
            <Typography className={classes.title}>{t('confirmDetails')}</Typography>
            <Button className={classes.navigateStep} onClick={() => setActiveStep(0)}>{t('sharedChange')}</Button>
          </>
        )}
        body={(
          <>
            <Typography sx={{ color: 'red' }}>ベーシックプラン1ヶ月単位自動継続</Typography>
            <Divider sx={{ margin: '5px 0px' }} />
            <Typography sx={{ color: 'red' }}>月額 110円 税込</Typography>
          </>
        )}
      />

      <CardCustom
        title={(
          <>
            <Typography className={classes.title}>{t('confirmMethod')}</Typography>
            <Button className={classes.navigateStep} onClick={() => setActiveStep(1)}>{t('sharedChange')}</Button>
          </>
        )}
        body={(
          <>
            <Typography className={classes.paymentMethodInfor}>{t('confirmPayment')}</Typography>
            <Typography className={classes.paymentMethodInfor}>
              {`${t('confirmCardHolder')}: ${fullName} `}
            </Typography>
            <Typography className={classes.paymentMethodInfor}>
              {`${t('confirmCardNumber')}: ${dataCheck.cn} `}
            </Typography>
            <Typography className={classes.paymentMethodInfor}>
              {`${t('confirmExpiration')}: ${transformedDate} `}
            </Typography>
          </>
        )}
      />

      <CardCustom
        title={(
          <>
            <Typography className={classes.title}>{t('confirmMatters')}</Typography>
            <Button className={classes.disabledBtn} disabled />
          </>
            )}
        body={(
          <>
            <Typography>{t('confirmMatter1')}</Typography>
            <Typography>{t('confirmMatter2')}</Typography>
            <Typography>{t('confirmMatter3')}</Typography>
            <Typography>{t('confirmMatter4')}</Typography>
          </>
        )}
      />
    </>
  );
};

export default ConfirmInformation;
