/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Box, Card, CardContent, Typography, Grid, Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import { helperActions } from '../../../store';
import fetchApi from '../../FetchApi';

const cardAnalysis = [
  {
    icon: <PersonIcon sx={{ fontSize: 100, color: '#8faadc' }} />,
    title: 'analysisNumberOfManager',
    count: '1000',
  },
  {
    icon: <PersonOutlinedIcon sx={{ fontSize: 100, color: '#8faadc' }} />,
    title: 'analysisNumberOfSubManager',
    count: '300',
  },
  {
    icon: <LocationOnIcon sx={{ fontSize: 100, color: '#8faadc' }} />,
    title: 'analysisNumberOfMonitoringObject',
    count: '1350',
  },
];
const useStyles = makeStyles((theme) => ({
  userStatistics: {
    paddingBottom: '40px',
    fontSize: '30px',
    textDecoration: 'underline',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
    },
  },
  count: {
    fontWeight: 'bold',
    fontSize: '50px',
    [theme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
  },
  countTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
}));

const AnalysisCard = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const loading = useSelector((state) => state.helper.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    totalManager: '...',
    totalSubManager: '...',
    totalUser: '...',
  });

  const cardAnalysis = [
    {
      icon: <PersonIcon sx={{ fontSize: 100, color: '#8faadc' }} />,
      title: 'analysisNumberOfManager',
      count: data.totalManager,
    },
    {
      icon: <PersonOutlinedIcon sx={{ fontSize: 100, color: '#8faadc' }} />,
      title: 'analysisNumberOfSubManager',
      count: data.totalSubManager,
    },
    {
      icon: <LocationOnIcon sx={{ fontSize: 100, color: '#8faadc' }} />,
      title: 'analysisNumberOfMonitoringObject',
      count: data.totalUser,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      dispatch(helperActions.setLoading(true));
      const response = await fetchApi('/api/admin/statistic/users?category=99', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        setData(objRecive);
      }
    };

    fetchData();
    dispatch(helperActions.setLoading(false));
  }, []);

  return (
    <Box sx={{ minWidth: 275 }}>
      <Typography className={classes.userStatistics}>{t('analysisCardContent')}</Typography>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {cardAnalysis.map((item, index) => (
          <Grid item xs={4} sm={4} md={4}>
            <Paper
              sx={{
                p: 2,
                margin: 'auto',
                maxWidth: 500,
                flexGrow: 1,
                borderRadius: '20px',
                height: 'auto',
                border: '1px solid #8095ba',
              }}
              key={index}
            >
              <Grid container spacing={2} style={{ display: 'grid', gridTemplateColumns: '30% 70%' }}>
                <Grid item style={{ gridColumn: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {item.icon}
                </Grid>
                <Grid item xs={12} sm container style={{ gridColumn: '2' }}>
                  <Grid item xs container direction="column" spacing={2} style={{ gridColumn: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs>
                      <Typography className={classes.countTitle} variant="h2" gutterBottom>
                        {t(item.title)}
                      </Typography>
                      <Typography className={classes.count} variant="body2" gutterBottom>
                        {item.count}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AnalysisCard;
