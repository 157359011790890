import { useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import { useMediaQuery, useTheme } from '@mui/material';
import { map } from './core/MapView';

const MapCamera = ({
  latitude, longitude, positions, coordinates,
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (coordinates || positions) {
      if (!coordinates) {
        coordinates = positions.map((item) => [item.longitude, item.latitude]);
      }
      if (coordinates.length) {
        const bounds = coordinates.reduce((bounds, item) => bounds.extend(item), new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
        const canvas = map.getCanvas();
        const padding = Math.min(canvas.width, canvas.height) * 0.1;

        map.fitBounds(bounds, {
          padding: {
            top: padding,
            left: padding,
            bottom: padding + 200,
            right: padding,
          },
          duration: 0,
        });
      }
    } else {
      map.jumpTo({
        center: [longitude, latitude],
        zoom: Math.max(map.getZoom(), 10),
      });
    }
  }, [latitude, longitude, positions, coordinates]);

  return null;
};

export default MapCamera;
