import React, { useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import AdminMenu from './components/AdminMenu';
import PageLayoutAdmin from './PageLayoutAdmin';
import { helperActions } from '../store';

const useStyles = makeStyles({
  layout: {
    margin: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  waitDisplay: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(35, 34, 35, 0.49)',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    zIndex: 1,
  },
});

const Admin = () => {
  const classes = useStyles();
  const loading = useSelector((state) => state.helper.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = '/admin/home';

  // useEffect(() => {
  //   if (path) {
  //     navigate('/admin/home/list');
  //   }
  // }, [path]);

  useEffect(() => {
    dispatch(helperActions.setAdminData({
      current: [],
      filter: [],
      orderBy: [],
      default: [],
    }));
  }, [navigate]);

  return (
    <PageLayoutAdmin menu={<AdminMenu />} breadcrumbs={['userAdmin', 'homepage']}>
      <div style={{ position: 'relative', minHeight: '100%', overflow: loading ? 'hidden' : 'auto', background: '#ECECEC' }}>
        <div className={classes.layout}>
          <Outlet />
        </div>
        {/* <div className={classes.waitDisplay} style={{ display: loading ? 'flex' : 'none', position: 'fixed' }}>
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#308FE8',
            }}
            size={40}
            thickness={5}
            value={100}
          />
        </div> */}
      </div>

    </PageLayoutAdmin>
  );
};

export default Admin;
