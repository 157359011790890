import React from 'react';
import { List, ListItemIcon, MenuItem } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import HandymanIcon from '@mui/icons-material/Handyman';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import '../../styles/components/PageLayoutAdmin.css';

// const MenuItem = ({
//   title, link, icon, selected,
// }) => (
//   <ListItemButton
//     key={link}
//     component={Link}
//     to={link}
//     selected={selected}
//     style={{
//       display: 'flex',
//       justifyContent: 'space-between',
//       backgroundColor: '#222A35',
//     }}
//   >
//     <ListItemIcon>{icon}</ListItemIcon>
//     <div style={{ fontSize: '1.5rem' }}>{title}</div>
//   </ListItemButton>
// );

const CustomMenuItem = ({ title, link, icon, selected }) => (
  <MenuItem
    className="menu-item"
    component={Link}
    to={link}
    selected={selected}
  >
    <ListItemIcon>{icon}</ListItemIcon>
    <div className="title-menu">{title}</div>
  </MenuItem>
);

const AdminMenu = () => {
  const t = useTranslation();
  const location = useLocation();

  return (
    <List className="list-menu">
      <CustomMenuItem
        title={t('settingsUser')}
        link="/admin/home/list"
        icon={<PersonIcon />}
        selected={location.pathname.startsWith('/admin/home/list')}
      />
      <CustomMenuItem
        title={t('tool')}
        icon={<HandymanIcon />}
        link="/admin/tools"
        selected={location.pathname.startsWith('/admin/tools')}
      />
    </List>
  );
};

export default AdminMenu;
