/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import Header from '../Header';
import NewTable from '../../../components/NewTable';
import { accountsChangePasswordActions, helperActions } from '../../../../store';
import Vectorback from '../../../../resources/images/Vectorback.png';
import { getUserIds } from '../../../common/utils';

const useStyles = makeStyles(() => ({
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
}));

const ChangePasswordNew = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataSelect, setDataSelect] = useState([]);
  const [gridApi, getGridApi] = useState(null);

  const checkedList = useSelector((state) => state.helper.checkedList);

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  useEffect(() => {
    dispatch(helperActions.getChangePasswordUser({}));
  }, []);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    removeColumn: [1],
    className: classes.configTable,
    floatingFilter: true,
    showPagination: true,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  return (
    <>
      <Header noBack>
        <p className="title-page">{t('linkListChangePassword')}</p>
        <div className="setup-head-item">
          <Typography>{t('titleChangePassword')}</Typography>
          <Button
            className="button-creat-area"
            disabled={userIds.length === 0}
            onClick={() => {
              dispatch(accountsChangePasswordActions.push(userIds));
              navigate('/admin/tools/changePasswordForm');
            }}
          >
            <Typography>{t('perform')}</Typography>
          </Button>
          <Button className="button-back" onClick={() => navigate(-1)}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
        </div>
      </Header>
      <NewTable {...dataTable} />
    </>
  );
};

export default ChangePasswordNew;
