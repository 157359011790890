import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HistoryLocationSharingPage from '../history/HistoryLocationSharingPage';
import LocationSharingPage from './LocationSharingPage';

const NavigateLocationSharingPage = () => {
  const history = useSelector((state) => state.helper.history);
  // const [showHistory, setShowHistory] = useState(false);
  if (!history) {
    return <LocationSharingPage />;
  }
  return <HistoryLocationSharingPage />;
};

export default NavigateLocationSharingPage;
