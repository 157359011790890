import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from '../../common/components/LocalizationProvider';

const CheckoutResult = ({ resultCode }) => {
  const t = useTranslation();
  return (
    <div style={{ margin: '10px' }}>
      {resultCode === '' ? (
        <Typography variant="h5" gutterBottom>{t('paymentSuccess')}</Typography>
      ) : (
        <Typography variant="h5" gutterBottom>{t('paymentfailure')}</Typography>
      )}
    </div>
  );
};

export default CheckoutResult;
