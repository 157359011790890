import store, { setLockScreen, errorsActions, setMessage } from '../store';

const fetchApi = (url, config, mockData, env) => {
  store.dispatch(setLockScreen(true));
  store.dispatch(errorsActions.push(null));
  if (env === 'TEST') {
    const mockdata = new Promise((resolve, reject) => {
      setTimeout(() => {
        store.dispatch(setMessage(null));
        resolve(new Response(JSON.stringify(mockData)));
      }, 2000);
    });
    return mockdata;
  }

  return fetch(url, config)
    .then((response) => {
      store.dispatch(setLockScreen(false));
      if (response.status === 504) {
        store.dispatch(setMessage('504 Gateway Timeout'));
      }
      return response;
    })
    .catch((error) => {
      store.dispatch(setLockScreen(false));
      throw error;
    });
};

export default fetchApi;
