import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import TableConfig from '../../../components/TableConfig';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import BreadcrumbsCustom from '../../../components/BreadcrumbsCustom';
import fetchApi from '../../../FetchApi';
import NewTable from '../../../components/NewTable';
import { helperActions } from '../../../../store';

const useStyle = makeStyles((theme) => ({
  configTable: {
    height: '600px',
    width: '100%',
  },
  editBtn: {
    backgroundColor: '#C0C0C0',
    color: 'white',
    border: 'outset 5px #C0C0C0',
    margin: '0.5rem',
    width: '130px',
    '&:hover': {
      backgroundColor: '#C0C0C0',
    },
  },
  performBtn: {
    backgroundColor: '#ff0505',
    color: 'white',
    border: 'outset 5px #ff0505',
    margin: '0.5rem',
    width: '130px',
    '&:hover': {
      backgroundColor: '#ff0505',
    },
  },
  mobileStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const ConfirmNotification = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();

  const notification = useSelector((state) => state.helper.notification);
  const dataTarget = useSelector((state) => state.helper.selectedUser);

  const handleSubmit = async () => {
    const response = await fetchApi('/api/admin/notifications', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userIds: notification.userIds,
        title: notification.title,
        content: notification.mainContent,
      }),
    });
    if (response.ok) {
      dispatch(helperActions.setPathname(location.pathname));
      navigate('/admin/finish');
    }
  };

  const dataTable = {
    removeColumn: [0, 1],
    saveData: true,
    listData: dataTarget,
    floatingFilterOff: true,
    className: classes.configTable,
    childrenTop: (
      <>
        <div style={{ margin: '2rem 0', borderBottom: '2px solid gray' }}>
          <BreadcrumbsCustom getAfter="admin" />
          <div>{t('notificationContent')}</div>
        </div>
        <Typography style={{ fontSize: '30px', textDecoration: 'underline', marginBottom: '20px' }}>{t('notificationConfirmContents')}</Typography>
        <Box>
          <Typography style={{ fontSize: '26px', textDecoration: 'underline' }}>{t('title')}</Typography>
          <div style={{ marginBottom: '1rem' }}>{notification.title}</div>
          <Typography style={{ fontSize: '26px', textDecoration: 'underline' }}>{t('content')}</Typography>
          <div style={{ marginBottom: '1rem', width: '100%' }}>{notification.mainContent}</div>
        </Box>
        <Typography style={{ fontSize: '26px', textDecoration: 'underline', marginBottom: '20px' }}>{t('mailRecipient')}</Typography>
      </>
    ),
  };

  return (
    <div>
      <NewTable {...dataTable} />
      <div className={classes.mobileStyle}>
        <Button className={classes.editBtn} onClick={() => navigate('/admin/tools/sendnotifications')}>{t('sharedEdit')}</Button>
        <Button className={classes.performBtn} onClick={handleSubmit}>{t('send')}</Button>
      </div>
    </div>
  );
};

export default ConfirmNotification;
