import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PageLayoutPilot from '../common/components/PageLayoutPilot';
import MenuPilot from '../common/components/MenuPilot';
import DeathIcon from '../resources/images/Death.svg';
import SmileIcon from '../resources/images/Smile.svg';
import DeathIconWhite from '../resources/images/DeathIconWhite.svg';
import SmileIconWhite from '../resources/images/SmileIconWhite.svg';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  default: {
    width: '309px',
    height: '93px',
    color: '#A5A5A5',
    boxShadow: 'none',
    fontSize: '1rem',
    justifyContent: 'flex-start',
    paddingLeft: '28px',
    margin: '0 auto',
  },
  success: {
    backgroundColor: '#F5F5F5',
    marginBottom: '19px',
    '&:hover': {
      backgroundColor: '#009380',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: '#009380',
      color: 'white',
    },
  },
  error: {
    backgroundColor: '#F5F5F5',
    marginBottom: '154px',
    '&:hover': {
      backgroundColor: '#F65A5A',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: '#F65A5A',
      color: 'white',
    },
  },
  unConfirm: {
    backgroundColor: '#B2DED9 !important',
    color: 'white !important',
    height: '44px',
    borderRadius: '20px',
    justifyContent: 'center !important',
  },
  confirm: {
    backgroundColor: '#009380',
    color: 'white !important',
    height: '44px',
    borderRadius: '20px',
    justifyContent: 'center !important',
  },
}));
const Exception = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [isClick, setIsClick] = useState(false);
  const [checkFocusCondition, setCheckFocusCondition] = useState(false);
  const [bugFocus, setBugFocus] = useState(false);
  const [helpFoucs, setHelpFoucs] = useState(false);
  const [bugBtn, setBugBtn] = useState(false);
  const [helpBtn, setHelpBtn] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const onFocus = (callback1, callback2) => {
    setIsClick(true);
    callback1(true);
    callback2(true);
  };

  const onBlur = (callback1, callback2) => {
    if (!checkFocusCondition) setIsClick(false);
    callback1(false);
    callback2(false);
  };

  const mouseOverConfirm = () => {
    setCheckFocusCondition(true);
  };

  const onConfirm = () => {
    setIsConfirm(true);
  };

  const s = (array) => {
    let value = '';
    array.forEach((a) => {
      value += ` ${a}`;
    });
    return value;
  };

  return (
    <PageLayoutPilot
      menu={<MenuPilot />}
      breadcrumbs={['safetyPage']}
      location="/clientalarm"
    >
      <div style={{ margin: `45px auto ${isConfirm ? '440px' : '9px'} auto`, fontSize: isConfirm ? '16px' : '14px' }}>
        {isConfirm ? t('safetySend') : t('safety')}
      </div>
      {!isConfirm && (
        <>
          <div
            style={{ margin: '0 auto 49px auto', fontSize: '17px', color: '#4B4B4B' }}
          >
            {t('selectAndTouch')}
          </div>
          <Button
            variant="contained"
            onFocus={() => onFocus(setBugBtn, setBugFocus)}
            onBlur={() => onBlur(setBugBtn, setBugFocus)}
            onMouseOver={() => setBugBtn(true)}
            onMouseOut={() => { if (!bugFocus) setBugBtn(false); }}
            startIcon={(<img src={bugBtn ? SmileIconWhite : SmileIcon} width="47px" height="47px" alt="smileIcon" style={{ color: 'white' }} />)}
            className={s([classes.success, classes.default])}
          >
            <div style={{ marginLeft: '32px' }}>{t('bug')}</div>
          </Button>
          <Button
            variant="contained"
            onFocus={() => onFocus(setHelpBtn, setHelpFoucs)}
            onBlur={() => onBlur(setHelpBtn, setHelpFoucs)}
            onMouseOver={() => setHelpBtn(true)}
            onMouseOut={() => { if (!helpFoucs) setHelpBtn(false); }}
            startIcon={(<img src={helpBtn ? DeathIconWhite : DeathIcon} width="47px" height="47px" alt="deathIcon" />)}
            className={s([classes.error, classes.default])}
          >
            <div style={{ marginLeft: '32px' }}>{t('help!')}</div>
          </Button>
          <Button
            variant="contained"
            className={s([isClick ? classes.confirm : classes.unConfirm, classes.default])}
            disabled={!isClick}
            onClick={() => onConfirm()}
            onMouseOver={() => mouseOverConfirm()}
          >
            {t('send')}
          </Button>
        </>
      )}
      {isConfirm && (
        <Button
          variant="contained"
          className={s([isClick ? classes.confirm : classes.unConfirm, classes.default])}
          onClick={() => navigate('/')}
        >
            {t('close')}
        </Button>
      )}
    </PageLayoutPilot>
  );
};

export default Exception;
