import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  Button,
  Drawer,
  IconButton, Paper, Slider, Switch, Toolbar, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import Dialog from '@mui/material/Dialog';
import MenuIcon from '@mui/icons-material/Menu';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapPositions from '../map/MapPositions';

import { useTranslation } from '../common/components/LocalizationProvider';
import DeviceDrawer from '../main/DeviceDrawer';
import { helperActions } from '../store';
import MenuPilot from '../common/components/MenuPilot';
import { useEffectAsync } from '../reactHelper'; // v5.6

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    '& .maplibregl-ctrl': {
      marginTop: '70px',
    },
    '& .maplibregl-ctrl-top-right': {
      display: 'none',
    },
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    zIndex: 3,
    left: 0,
    top: 0,
    width: '100%',
  },
  title: {
    flexGrow: 1,
  },
  slider: {
    width: '100%',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formControlLabel: {
    height: '100%',
    width: '100%',
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  sliderMobile1Device: {
    width: '80%',
    position: 'fixed',
    zIndex: 3,
    left: '10%',
    top: 'calc(100vh - 150px - 40px)',
  },
  dialogBox: {
    '& .MuiPaper-rounded': {
      borderRadius: '20px',
    },
  },
  dialog: {
    width: '317px',
    height: '207px',
    padding: '0 24px',
    borderRadius: '50px',
  },
  dialogText: {
    margin: '52px auto 30px auto',
    color: '#6C6A6A',
    fontSize: '15px',
    textAlign: 'center',
  },
  dialogBtn: {
    width: '100%',
    height: '47px',
    color: 'white',
    backgroundColor: '#009380',
    borderRadius: '50px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#009380',
    },
    '&:active': {
      color: 'white',
      backgroundColor: '#009380',
    },
  },
  switchReplay: {
    position: 'absolute',
    right: '10px',
    padding: '10px',
    display: 'flex',
    top: '70px',
    '& .MuiSwitch-track': {
      backgroundColor: '#009380 !important',
      opacity: '1 !important',
      borderRadius: '10px',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'white',
    },
  },
  update: {
    height: '46px',
    width: '46px',
    position: 'absolute',
    right: '20px',
    padding: '10px',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    top: '120px',
    boxShadow: '0px 0px 10px grey',
    // remove hover MUI
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
}));

const HistoryPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const timerRef = useRef();
  const defaultDeviceId = useSelector((state) => state.devices.selectedId);
  const dispatch = useDispatch();
  const turnOffDrawer = useSelector((state) => state.helper.active);

  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const onClick = useCallback((positionId) => {
    if (positionId) {
      navigate(`/position/${positionId}`);
    }
  }, [navigate]);

  useEffect(() => {
    if (playing && positions.length > 0) {
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, 500);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, positions]);

  useEffect(() => {
    if (index >= positions.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, positions]);

  useEffectAsync(async () => {
    if (defaultDeviceId) {
      const deviceId = defaultDeviceId;
      const local = moment().format('YYYY-MM-DD');
      const from = moment(`${local} 00:00:00:00`).utc().format();
      const to = moment().utc().format();
      const query = new URLSearchParams({ deviceId, from, to });
      if (deviceId) {
        const response = await fetch(`/api/positions?${query.toString()}`);
        // Duy hung
        if (response.ok) {
          setIndex(0);
          const positions = await response.json();
          setPositions(positions);
          if (positions.length) {
            setPlaying(!playing);
          } else {
            setDialogText(t('sharedNoData'));
            setOpenDialog(true);
          }
        } else {
          setDialogText(await response.text());
          setOpenDialog(true);
        }
      }
    } else {
      navigate('/');
    }
  }, []);

  return (
    <div className={classes.root}>
      <MapView>
        <MapRoutePath positions={positions} />
        {index < positions.length && (
        <MapPositions positions={[positions[index]]} onClick={onClick} />
        )}
      </MapView>
      <Switch
        className={classes.switchReplay}
        checked
        onChange={() => {
          navigate(-1);
        }}
      />
      <IconButton className={classes.update}>
        <SettingsBackupRestoreOutlinedIcon
          fontSize="large"
        />
      </IconButton>
      <div className={classes.sidebar}>
        <Paper elevation={3} square>
          <Drawer
            variant="temporary"
            open={turnOffDrawer}
            anchor="right"
            onClose={() => {
              dispatch(helperActions.unActive());
            }}
          >
            <MenuPilot style={{ width: '200px' }} />
          </Drawer>
          <Toolbar>
            <IconButton edge="start" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              style={{ position: 'absolute', right: '15px' }}
              onClick={() => {
                dispatch(helperActions.active());
              }}
            >
              <MenuIcon style={{ color: 'black' }} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>{t('history')}</Typography>
          </Toolbar>
        </Paper>
        {!turnOffDrawer && (
        <Slider
          className={classes.sliderMobile1Device}
          max={positions.length - 1}
          step={null}
          marks={positions.map((_, index) => ({ value: index }))}
          value={index}
          onChange={(_, index) => setIndex(index)}
          sx={{
            height: 10,
            color: '#009380',
            '& .MuiSlider-thumb': {
              borderRadius: '50%',
              width: '15px',
              height: '15px',
              border: '2px solid white',
            },
            '& .MuiSlider-rail': {
              backgroundColor: 'white',
              opacity: 1,
              boxShadow: '0 0 20px gray',
            },
            '& .MuiSlider-mark': {
              width: 0,
            },
          }}
        />
        )}
      </div>
      {turnOffDrawer ? <div /> : <DeviceDrawer height={150} deviceId={defaultDeviceId} onlyOne />}
      <Dialog open={openDialog} className={classes.dialogBox}>
        <div className={classes.dialog}>
          <div className={classes.dialogText}>{dialogText}</div>
          <Button
            className={classes.dialogBtn}
            onClick={() => {
              setOpenDialog(false);
              navigate(-1);
            }}
          >
            {t('close')}

          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default HistoryPage;
