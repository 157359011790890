import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const CustomInputComponent = React.forwardRef(({ value, onClick }, ref) => (
  <TextField
    variant="outlined"
    ref={ref}
    value={value}
    onClick={onClick}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={onClick}>
            <CalendarTodayIcon />
          </IconButton>
        </InputAdornment>
      ),
    }}
    style={{ cursor: 'pointer' }}
  />
));

export default CustomInputComponent;
