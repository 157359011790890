import React, { useState } from 'react';
import {
  Button, TextField, Typography, Snackbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import LoginLayout from './LoginLayout';
import { useTranslation } from '../common/components/LocalizationProvider';
import LogoImage from './LogoImage';
import '../styles/pages/LogoutLayout.css';
import { helperActions, sessionActions } from '../store';

const LogoutPage = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleUpdateUser = () => {
    navigate('/login');
    dispatch(helperActions.setAdminData({
      current: [],
      filter: [],
      orderBy: [],
      default: [],
    }));
    dispatch(sessionActions.updateUser(null));
  };
  return (
    <LoginLayout>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px' }}>
        <LogoImage color={theme.palette.primary.main} />
      </div>
      <div className="container-page-login container-logout">
        <Typography fontWeight={700}>{t('messageLogout')}</Typography>
        <div className="block-field">
          <Button
            className="btn-perform"
            variant="contained"
            onClick={() => {
              handleUpdateUser();
            }}
            fullWidth
          >
            {t('navigateLogin')}
          </Button>
        </div>

      </div>
    </LoginLayout>
  );
};

export default LogoutPage;
