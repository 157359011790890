import React from 'react';
import {
  Card, Divider, Grid, CardActionArea, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../../common/components/LocalizationProvider';
import AnalysisCard from './components/AnalysisCard';
import AnalysisPieChart from './components/AnalysisPieChart';
import AnalysisSelect from './components/AnalysisSelect';
import AnalysisStackedChart from './components/AnalysisStackedChart';

const useStyles = makeStyles({
  a: {},
});

const Analys = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div>
      <div>
        <AnalysisCard />
        <AnalysisPieChart />
        <AnalysisSelect />
        <AnalysisStackedChart />
      </div>
    </div>
  );
};

export default Analys;
