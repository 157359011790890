import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import React from 'react';
import warning from '../../resources/images/warning.png';
// eslint-disable-next-line import/no-extraneous-dependencies
import '../../styles/components/ModalWarning.css';

const ModalWarning = ({ openModal, handleClose, propsModal }) => {
  const { confirm, subTitle, subTitle2, msgWarning, titleCancel } = propsModal;
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container-modal-warning">
          <img width="90px" height="90px" src={warning} alt="img" />
          <br />
          <Typography className="text-confirm">{confirm}</Typography>
          <Typography className="text-confirm">{subTitle}</Typography>
          <Typography className="text-confirm">{subTitle2}</Typography>
          {msgWarning && <Typography className="upsert-device-modal-title">{msgWarning}</Typography>}
          <br />
          <div className="container-button">
            <Button
              variant="contained"
              className="button-cancel"
              onClick={handleClose}
            >
              {titleCancel}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalWarning;
