/* eslint-disable no-plusplus */
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import makeStyles from '@mui/styles/makeStyles';
import { map } from './core/MapView';
import Bad from '../resources/images/DeathIconWhite.svg';
import Good from '../resources/images/SmileIconWhite.svg';

const useStyle = makeStyles({
  goodBackground: {
    '& .maplibregl-popup-content': {
      backgroundColor: '#009380',
      padding: 0,
      borderRadius: '6px',
      boxShadow: '0 0 20px #949494b5',
    },
    '& .maplibregl-popup-tip': {
      backgroundColor: '#009380',
      clipPath: 'polygon(0 0, 23% 40%, 100% 0)',
      borderBottom: 'none',
      borderTop: 0,
      height: '30px',
    },
    '& .maplibregl-popup-close-button': {
      backgroundColor: 'white !important',
      borderRadius: '100%',
      paddingTop: '2px',
      paddingBottom: '2px',
      border: '1px solid black !important',
      top: '-5px',
      right: '-5px',
    },
  },
  badBackground: {
    '& .maplibregl-popup-content': {
      backgroundColor: 'red',
      padding: 0,
      borderRadius: '6px',
      boxShadow: '0 0 20px #949494b5',
    },
    '& .maplibregl-popup-tip': {
      backgroundColor: 'red',
      clipPath: 'polygon(0 0, 23% 40%, 100% 0)',
      borderBottom: 'none',
      borderTop: 0,
      height: '30px',
    },
    '& .maplibregl-popup-close-button': {
      backgroundColor: 'white !important',
      borderRadius: '50%',
      paddingTop: '2px',
      paddingBottom: '2px',
      border: '1px solid black !important',
      top: '-5px',
      right: '-5px',
    },
  },
  popUpConfig: {
    width: '80px',
    height: '50px',
    backgroundSize: '60% 60%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
});
const MapPopUpLocationSharing = () => {
  const positions = useSelector((state) => state.session.positions);
  const devices = useSelector((state) => state.devices.items);
  const classes = useStyle();

  const [popUp, setPopUp] = useState([]);
  // eslint-disable-next-line no-undef
  const elements = document.getElementsByClassName('maplibregl-popup');
  const arrayDevices = Object.values(devices);
  const arrayPositions = Object.values(positions);
  const handleClose = async (itemId, status) => {
    const data = {
      deviceId: itemId,
      userStatus: `${status} active`,
    };
    await fetch('/api/devices/userstatus', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  };
  useEffect(() => {
    for (let i = 0; i < elements.length; i++) {
      elements[i].remove();
    }
    setPopUp([]);
    let arr = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-unused-vars
    for (const device in devices) {
      const popup = new maplibregl.Popup({ anchor: 'bottom', offset: { bottom: [0, -5] }, closeOnMove: false, closeOnClick: false })
        .addTo(map);
      arr = arr.concat(popup);
    }
    setPopUp(arr);
  }, [arrayDevices.length]);

  useEffect(() => {
    const listDevicePopUp = arrayDevices.filter((it) => it.userStatus === 'bad' || it.userStatus === 'good');

    let a = 0;
    arrayPositions.filter((it) => listDevicePopUp.find((e) => {
      const cond = e.id === it.deviceId;
      if (cond && popUp.length !== 0) {
        // eslint-disable-next-line no-unused-expressions
        let image;
        let classname;
        switch (e.userStatus) {
          case 'bad':
            image = Bad;
            classname = classes.badBackground;
            break;
          case 'good':
            image = Good;
            classname = classes.goodBackground;
            break;
          default:
            image = '';
            classname = '';
        }
        const img = (
          <div />
        );
        popUp[a].toggleClassName(classname);
        popUp[a].setLngLat([it.longitude, it.latitude])
          .setHTML(`<div style="background-image:url(${image})" class="${classes.popUpConfig}"></div>`)
          .toggleClassName(classname);
        popUp[a].on('close', () => handleClose(e.id, e.userStatus));
        a += 1;
      }

      return cond;
    }));
  }, [positions, popUp, devices]);

  return null;
};

export default MapPopUpLocationSharing;
