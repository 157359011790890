/* eslint-disable no-undef */
import React, {
  useRef, useState, useCallback, useEffect,
} from 'react';
import {
  Button, Modal, Typography, Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';
import AdminCard from './components/AdminCard';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';
import { useTranslation } from '../../common/components/LocalizationProvider';
import {
  valueTranslate, getUserData, getUserIds,
} from '../common/utils';
import { Member } from '../common/DefineClass';
import { helperActions } from '../../store';
import fetchApi from '../FetchApi';
import TableListAccount from './components/TableListAccount';
import StatiticUsers from './components/StatiticUsers';
import EditIcon from '../../resources/images/icon/homeEdit.svg';
import HomeRemove from '../../resources/images/icon/home-remove.svg';
import exportCSV from '../../resources/images/icon/iconExportCSV.svg';

const userModel = new Member();

const useStyle = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-evenly',
    },
  },
  accountDefault: {
    backgroundColor: '#F07000',
    color: 'white',
    margin: '1rem 3rem 1rem 0',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    },
  },
  accountSpecial: {
    backgroundColor: '#D0F000',
    color: 'black',
    margin: '1rem 0',
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '130px',
    },
  },
  csvBtn: {
    display: 'inline-flex',
    gap: '2px',
    color: '#818181',
    border: '0.5px solid #818181',
    textDecoration: 'none',
    backgroundColor: '#FFFFFF',
    width: '90px',
    height: '35px',
    borderRadius: '17px',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  listBtn: {
    display: 'inline-flex',
    margin: '1rem 0 1rem 0',
  },
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  submitBtn: {
    width: '120px',
    border: 'outset 5px red',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  closeBtn: {
    width: '120px',
    border: 'outset 5px grey',
    backgroundColor: 'grey',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ListTable = () => {
  const navigate = useNavigate();
  const classes = useStyle();
  const tableRef = useRef();
  const t = useTranslation();
  const dispatch = useDispatch();

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const checkedList = useSelector((state) => state.helper.checkedList);

  const data = getUserData(checkedList, dataSelect);
  const userIds = getUserIds(checkedList, dataSelect);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDeleteUser = async () => {
    const response = await fetchApi('/api/admin/users', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userIds }),
    });
    if (response.ok) {
      navigate('/admin/finish');
    } else {
      console.log('not ok');
    }
  };

  useEffect(() => {
    dispatch(helperActions.selectedUser(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    refreshData,
    setRefreshData,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };
  return (
    <>
      <div>
        <BreadcrumbsCustom listPath="account" />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '420px' }}>
            <StatiticUsers setRefreshData={setRefreshData} />
          </div>
          <div className={classes.listBtn}>
            <Button
              onClick={() => navigate('exportCSV')}
              className={classes.csvBtn}
            >
              <img src={exportCSV} alt="exportCSV" />
              <div>CSV</div>
            </Button>
            <Button
              onClick={() => navigate('delete')}
              className={classes.csvBtn}
            >
              <img src={HomeRemove} style={{ marginRight: 5 }} alt="remove" />
              <div>{t('sharedDelete')}</div>
            </Button>
            <Button
              onClick={() => navigate('edit')}
              className={classes.csvBtn}
            >
              <img src={EditIcon} style={{ marginRight: 5 }} alt="edit" />
              <div>{t('sharedEdit')}</div>
            </Button>
          </div>
        </div>

      </div>
      <TableListAccount {...dataTable} />
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          height: 200,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
        >
          <div>
            <Typography className={classes.modalTitle}>{t('confirmDelete')}</Typography>
            <br />
            <Typography className={classes.modalTitle}>{t('confirmEdit2')}</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '30px' }}>
              <Button
                className={classes.closeBtn}
                variant="contained"
                sx={{ mt: 1, mb: 2 }}
                onClick={handleClose}
              >
                {t('sharedCancel')}
              </Button>
              <Button
                className={classes.submitBtn}
                variant="contained"
                onClick={() => {
                  handleDeleteUser();
                  setOpenModal(false);
                }}
                sx={{ mt: 1, mb: 2 }}
              >
                {t('perform')}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ListTable;
