import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
} from '@mui/material';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, getUserIds, valueTranslate } from '../common/utils';
import { Member } from '../common/DefineClass';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Vectorback from '../../resources/images/Vectorback.png';
import Header from '../tools/components/Header';
import '../../styles/components/ListAccountExportCSV.css';
import NewTable from '../components/NewTable';
import StatiticUsers from './components/StatiticUsers';

const userModel = new Member();

const ListAccountExportCSV = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const t = useTranslation();
  const [dataSelect, setDataSelect] = useState([]);
  const [gridApi, getGridApi] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const checkedList = useSelector((state) => state.helper.checkedList);
  const dispatch = useDispatch();
  const data = getUserData(checkedList, dataSelect);
  const userIds = getUserIds(checkedList, dataSelect);

  const onBtnExport = useCallback(() => {
    const columnDefs = tableRef.current.api.getColumnDefs();
    const columnKeys = columnDefs.slice(1).map((columnDef) => columnDef.colId);
    const params = {
      columnKeys,
    };
    const category1ColumnConfig = {
      field: 'category1',
      valueFormatter: (params) => valueTranslate(t, params.value, 'category1'),
    };
    const roleColumnConfig = {
      field: 'role',
      valueFormatter: (params) => valueTranslate(t, params.value, 'role'),
    };
    const categoryColumnConfig = {
      field: userModel.field2,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    };

    const paymentStatusColumnConfig = {
      field: userModel.field3,
      valueFormatter: (params) => valueTranslate(t, params.value, 'paymentStatus'),
    };

    const useStColumnConfig = {
      field: userModel.field6,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    };

    const billingMethodConfig = {
      field: userModel.field11,
      valueFormatter: (params) => valueTranslate(t, params.value, 'billingMethod'),
    };

    const paymentMethodColumnConfig = {
      field: userModel.field19,
      valueFormatter: (params) => valueTranslate(t, params.value, 'paymentMethod'),
    };

    const providerTypeConfig = {
      field: userModel.field23,
      valueFormatter: (params) => valueTranslate(t, params.value, 'type'),
    };

    const typeAppConfig = {
      field: 'typeApp',
      valueFormatter: (params) => valueTranslate(t, params.value, 'typeApp'),
    };

    // Call the value conversion function corresponding to each column in processCellCallback
    tableRef.current.api.exportDataAsCsv({
      processCellCallback: (params) => {
        const column = params.column.getColDef();
        switch (column.field) {
          case 'category1':
            return category1ColumnConfig.valueFormatter(params);
          case 'role':
            return roleColumnConfig.valueFormatter(params);
          case userModel.field27:
            return params.value ? moment(params.value).format('YYYYMMDD') : '';
          case userModel.field28: {
            const formattedDate = moment(params.value).local().format('YYYYMMDD');
            const lastDateOfCalendar = moment('9999/12/31').format('YYYYMMDD');
            return formattedDate === lastDateOfCalendar ? t('unLimited') : formattedDate;
          }
          case userModel.field22:
            return params.value ? moment(params.value).format('YYYYMMDD') : '';
          case userModel.field2:
            return categoryColumnConfig.valueFormatter(params);
          case userModel.field3:
            return paymentStatusColumnConfig.valueFormatter(params);
          case userModel.field6:
            return useStColumnConfig.valueFormatter(params);
          case userModel.field9:
            return params.node.data.representationId || params.node.data.managedUserId;
          case userModel.field11:
            return billingMethodConfig.valueFormatter(params);
          case userModel.field19:
            return paymentMethodColumnConfig.valueFormatter(params);
          case userModel.field23:
            return providerTypeConfig.valueFormatter(params);
          case 'typeApp':
            return typeAppConfig.valueFormatter(params);
          default:
            return params.value;
        }
      },
      onlySelected: true,
      fileName: 'TREMIL-export.csv',
      rowData: data,
      ...params,
    });
  }, []);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    removeColumn: [1],
    tableRef,
    refreshData,
    setRefreshData,
    floatingFilter: true,
    showPagination: true,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  return (
    <>
      <div className="list-account-export">
        <Header listPath="account/accountExportCSV" noDescription noBack topic="Export file CSV account">
          <div className="container-export">
            <StatiticUsers setRefreshData={setRefreshData} />
            <p className="title-header">{t('titleExportCSVListAccount')}</p>
            <Button
              onClick={onBtnExport}
              className="button-export"
              disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
            >
              {t('buttonExport')}
            </Button>
            <Button className="button-back" onClick={() => navigate('/admin/home/list')}>
              <img src={Vectorback} alt="icon back" />
              {t('back')}
            </Button>
          </div>
        </Header>
      </div>
      <NewTable {...dataTable} />
    </>
  );
};

export default ListAccountExportCSV;
