/* eslint-disable func-names */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import length from '@turf/length';
import numeral from 'numeral';
import turfCircle from '@turf/circle';

const CircleMode = MapboxDraw.modes.draw_line_string;

function createVertex(parentId, coordinates, path, selected) {
  return {
    type: 'Feature',
    properties: {
      meta: 'vertex',
      parent: parentId,
      coord_path: path,
      active: !!selected,
    },
    geonetry: {
      type: 'Point',
      coordinates,
    },
  };
}

// create a circle-like polygon give a center point and radius
// https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js/39006388#39006388

const createGeoJSONCircle = (center, radiusInKm, parentId) => {
  // const dispatch = useDispatch();
  // dispatch(helperActions.center(center));
  // dispatch(helperActions.center(radiusInKm));
  const circle = turfCircle(center, radiusInKm);
  circle.properties = {
    parent: parentId,
    active: 'true',
    center,
    radius: radiusInKm,
  };
  return circle;
};

const getDisplayMeasurements = (feature) => {
  // should log both metric and standard display strings for the current drawn feature

  // metric calculation
  const drawnLength = length(feature) * 1000; // meters
  let metricUnits = 'm';
  let metricFormat = '0,0';
  let metricMeasurement;

  let standardUnits = 'feet';
  let standardFormat = '0,0';
  let standardMeasurement;

  metricMeasurement = drawnLength;
  if (drawnLength >= 1000) {
    // if over 1000 meters, upgrade metric
    metricMeasurement = drawnLength / 1000;
    metricUnits = 'km';
    metricFormat = '0.00';
  }

  standardMeasurement = drawnLength * 3.28084;
  if (standardMeasurement >= 5280) {
    // if over 5280 feet, upgrade standard
    standardMeasurement /= 5280;
    standardUnits = 'mi';
    standardFormat = '0.00';
  }

  const displayMeasurements = {
    metric: `${numeral(metricMeasurement).format(metricFormat)} ${metricUnits}`,
    standard: `${numeral(standardMeasurement).format(
      standardFormat,
    )} ${standardUnits}`,
  };

  return displayMeasurements;
};
const doubleClickZoom = {
  enable: (ctx) => {
    setTimeout(() => {
      // First check we've got a map and some context.
      if (
        !ctx.map ||
        !ctx.map.doubleClickZoom ||
        !ctx._ctx ||
        !ctx._ctx.store ||
        !ctx._ctx.store.getInitialConfigValue
      ) { return; }
      // Now check initial state wasn't false (we leave it disabled if so)
      if (!ctx._ctx.store.getInitialConfigValue('doubleClickZoom')) return;
      ctx.map.doubleClickZoom.enable();
    }, 0);
  },
};

CircleMode.clickAnywhere = function (state, e) {
  const data = state.line || state.polygon;
  //   this ends the drawing after the user creates a second point, triggering this.onStop
  if (state.currentVertexPosition === 1) {
    data.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
    return this.changeMode('simple_select', { featureIds: [data.id] });
  }
  this.updateUIClasses({ mouse: 'add' });

  data.updateCoordinate(
    state.currentVertexPosition,
    e.lngLat.lng,
    e.lngLat.lat,
  );
  if (state.direction === 'forward') {
    state.currentVertexPosition += 1; // eslint-disable-line
    data.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat,
    );
  } else {
    data.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }

  return null;
};
// creates the final geojson point feature with a radius property
// triggers draw.create
CircleMode.onStop = function (state) {
  doubleClickZoom.enable(this);
  this.activateUIButton();
  const data = state.line;
  // check to see if we've deleted this feature
  if (this.getFeature(data.id) === undefined) return;
  // remove last added coordinate
  data.removeCoordinate('0');

  if (data.isValid()) {
    const lineGeoJson = data.toGeoJSON();
    const displayMeasurements = getDisplayMeasurements(lineGeoJson);
    // reconfigure the geojson line into a geojson circle ploygon with a radius property
    const circleFeature = createGeoJSONCircle(
      lineGeoJson.geometry.coordinates[0],
      length(lineGeoJson),
      null,
    );
    const circleWithRadius = {
      ...circleFeature,
      properties: {
        ...circleFeature.properties,
        radiusInKm: (length(lineGeoJson) * 1000).toFixed(1),
        radiusMetric: displayMeasurements.metric,
        radiusStandard: displayMeasurements.standard,
      },
    };

    this.map.fire(
      'draw.create',
      {
        features: [circleWithRadius],
      },
    );
  } else {
    this.deleteFeature([data.id], { silent: true });
    this.changeMode('simple_select', {}, { silent: true });
  }
};

CircleMode.toDisplayFeatures = function (state, geojson, display) {
  const data = state.line || state.polygon;
  // console.log('🚀 ~ file: CircleMode.js:189 ~ data:', data);
  const isActiveLine = geojson.properties.id === data.id;

  geojson.properties.active = isActiveLine ? 'true' : 'false';
  if (!isActiveLine) return display(geojson);
  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return null;
  geojson.properties.meta = 'feature';

  // displays center vertex as a point feature
  display(
    createVertex(
      data.id,
      geojson.geometry.coordinates[
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      ],
      `${
        state.direction === 'forward'
          ? geojson.geometry.coordinates.length - 2
          : 1
      }`,
      false,
    ),
  );

  // displays the line as it is drawn
  display(geojson);

  const displayMeasurements = getDisplayMeasurements(geojson);

  // create custom feature for the current pointer position
  const currentVertex = {
    type: 'Feature',
    properties: {
      meta: 'currentPosition',
      radiusMetric: displayMeasurements.metric,
      radiusStandard: displayMeasurements.standard,
      parent: data.id,
      active: 'true',
    },
    geometry: {
      type: 'Point',
      coordinates: geojson.geometry.coordinates[1],
    },
  };
  display(currentVertex);

  // create custom feature for radius circlemarker
  const center = geojson.geometry.coordinates[0];
  const radiusInKm = length(geojson, { units: 'kilometers' });
  const circleFeature = createGeoJSONCircle(center, radiusInKm, data.id);
  circleFeature.properties.meta = 'radius';

  display(circleFeature);
  return null;
};

export default CircleMode;
