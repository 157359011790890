import React, { useEffect, useState } from 'react';
import {
  Button, Card, CardActionArea, CardContent, Grid, Typography, CardActions, IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Header from '../Header';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import fetchApi from '../../../FetchApi';
import '../../../../styles/components/SetupArea.css';
import EditIcon from '../../../../resources/images/icon/icon-edit.svg';
import Vectorback from '../../../../resources/images/Vectorback.png';

const url = '/admin/tools';

const useStyles = makeStyles((theme) => ({
  noArea: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#333f50',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      marginTop: '3rem',
    },
  },
}));

const SetupArea = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const [items, setItem] = useState([]);
  // const items = useSelector((state) => state.geofences.items);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi('/api/admin/geofences/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        setItem(objRecive);
      }
    };
    fetchData();
  }, [navigate]);

  // console.log(items);
  return (
    <>
      <Header noBack>
        <p className="title-page">{t('linkListSetUpArea')}</p>
        <div className="setup-head-item">
          <Typography>{t('titleSetupArea')}</Typography>
          <Button
            className="button-creat-area"
            onClick={() => navigate(`${url}/setupArea/createArea`)}
          >
            <Typography>{t('buttonCreate')}</Typography>
          </Button>
          <Button className="button-back" onClick={() => navigate(-1)}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
        </div>
      </Header>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '30px 0 30px 30px' }} />
      {items.length > 0 ? (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 6, md: 6, lg: 10, xl: 10 }}>
          {items.map((item) => (
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} key={item.id}>
              <Card className="card-item">
                <CardActionArea onClick={() => navigate(`${url}/setupArea/areaDetails/${item.id}`)} className="card-action-area">
                  <CardContent className="item-name">
                    <Typography className="area-name" gutterBottom component="div">
                      {item.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions
                  className="card-action"
                  onClick={() => {
                    navigate(`${url}/setupArea/editArea/${item.id}`);
                  }}
                >
                  <div className="content-action">
                    <img src={EditIcon} alt="edit" />
                    {t('linkListEdit')}
                  </div>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (<Typography className={classes.noArea}>{t('noArea')}</Typography>)}
    </>
  );
};

export default SetupArea;
