/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Paper, Switch, Toolbar, IconButton, Button, Dialog,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListIcon from '@mui/icons-material/ViewList';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ref, onValue } from 'firebase/database';
import DevicesList from './DevicesList';
import MapView, { map } from '../map/core/MapView';
import MapSelectedDevice from '../map/main/MapSelectedDevice';
import MapAccuracy from '../map/main/MapAccuracy';
import MapGeofence from '../map/main/MapGeofence';
import MapCurrentLocation from '../map/MapCurrentLocation';
import { useTranslation } from '../common/components/LocalizationProvider';
import PoiMap from '../map/main/PoiMap';
import MapPadding from '../map/MapPadding';
import StatusCard from '../common/components/StatusCardLocationSharing';
import { devicesActions, helperActions, positionsActions } from '../store';
import MapDefaultCamera from '../map/main/MapDefaultCameraLocationSharing';
import usePersistedState from '../common/util/usePersistedState';
import MapLiveRoutes from '../map/main/MapLiveRoutes';
import MapPositions from '../map/MapPositionsLocationSharing';
import MapDirection from '../map/MapDirection';
import MapOverlay from '../map/overlay/MapOverlay';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import MapScale from '../map/MapScale';
import DeviceDrawer from './DeviceDrawer';
import MapPopUp from '../map/MapPopUpLocationSharing';
import { useAttributePreference } from '../common/util/preferences';
import { database } from '../firebase-config/Firebase';
import preloadImages, { mapIcons, mapImages } from '../map/core/preloadImages';
import { useEffectAsync } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    '& .maplibregl-ctrl-top-right': {
      display: 'none',
    },
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    left: 0,
    top: theme.spacing(8),
    height: '100%',
    zIndex: 5,
    width: theme.dimensions.drawerWidthDesktop,
    bottom: theme.dimensions.bottomBarHeight,
    transition: 'transform .5s ease',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  sidebarCollapsed: {
    transform: `translateX(-${theme.dimensions.drawerWidthDesktop})`,
    marginLeft: 0,
    [theme.breakpoints.down('md')]: {
      transform: 'translateX(-100vw)',
    },
  },
  toolbarContainer: {
    zIndex: 4,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  deviceList: {
    flex: 1,
  },
  statusCard: {
    position: 'fixed',
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      // left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
      left: `calc(50% + ${theme.dimensions.drawerWidthDesktop} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${
        theme.dimensions.bottomBarHeight
      }px)`,
    },
    transform: 'translateX(-50%)',
  },
  sidebarToggle: {
    position: 'fixed',
    left: theme.spacing(1.5),
    top: theme.spacing(10),
    borderRadius: '0px',
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      left: 0,
    },
    zIndex: 4,
  },
  sidebarToggleText: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  sidebarToggleBg: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.6)',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  bottomMenu: {
    position: 'fixed',
    left: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    zIndex: 4,
    width: theme.dimensions.drawerWidthDesktop,
  },
  filterPanel: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    width: theme.dimensions.drawerWidthTablet,
  },
  switchReplay: {
    position: 'absolute',
    right: '10px',
    padding: '10px',
    display: 'flex',
    top: '70px',
    '& .MuiSwitch-track': {
      backgroundColor: '#EFEFEF !important',
      opacity: '1 !important',
      borderRadius: '10px',
      borderTop: '4px inset #80808030',
      boxShadow: 'inset 0px 7px 3px #00000029',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#FCFCFC',
      boxShadow: '0px 7px 3px #00000029',
    },
  },
  update: {
    height: '46px',
    width: '46px',
    position: 'absolute',
    right: '15px',
    padding: '10px',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    top: '120px',
    boxShadow: '0px 0px 5px grey',
    // remove hover MUI
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  appBar: {
    backgroundColor: 'white',
  },
  dialogBox: {
    '& .MuiPaper-rounded': {
      borderRadius: '20px',
    },
  },
  dialog: {
    width: '317px',
    height: '207px',
    padding: '0 24px',
    borderRadius: '50px',
  },
  dialogText: {
    margin: '52px auto 30px auto',
    color: '#6C6A6A',
    fontSize: '15px',
    textAlign: 'center',
  },
  dialogBtn: {
    width: '100%',
    height: '47px',
    color: 'white',
    backgroundColor: '#009380',
    borderRadius: '50px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#009380',
    },
    '&:active': {
      color: 'white',
      backgroundColor: '#009380',
    },
  },
}));

const LocationSharingPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const t = useTranslation();
  const location = useLocation();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const mapOnSelect = useAttributePreference('mapOnSelect', true); // v5.6
  const [mapGeofences] = usePersistedState('mapGeofences', true);
  const [mapLiveRoutes] = usePersistedState('mapLiveRoutes', false);
  const [devicesOpen, setDevicesOpen] = useState(desktop); // v5.6

  const [openDialog, setOpenDialog] = useState(false);
  const [realTimePosition, setRealTimePosition] = useState([]);

  // lấy thông tin của các user
  const [members, setMembers] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);

  const queryParams = new URLSearchParams(location.search);
  const userIds = queryParams.get('uid') ? queryParams.get('uid').split(',') : [];

  /**
   * mock thì thêm fake trước realtimePosition
   */
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const selectedDeviceData = realTimePosition.find((item) => item.id === selectedDeviceId)?.data;
  const selectedPosition = selectedDeviceData ? selectedDeviceData[selectedDeviceData.length - 1] : null;

  // const selectedMember = members.find((member) => member.uid === selectedDeviceId); // status card

  const fetchData = async () => {
    try {
      const response = await fetch('/api/admin/location_sharing', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: userIds,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const filteredData = data.filter((member) => {
          const dateTimeString = `${member.date.substring(0, 10)} ${member.time}`;
          const memberDateTimeUTC = moment.utc(dateTimeString, 'YYYY-MM-DD HH:mm');
          const memberDateTimeLocal = memberDateTimeUTC.local().format('YYYY-MM-DD HH:mm');

          const momentMemberDateTime = moment(memberDateTimeLocal, 'YYYY-MM-DD HH:mm');
          const momentSpecificDateTime = moment().format('YYYY-MM-DD HH:mm');

          return momentMemberDateTime.isAfter(momentSpecificDateTime);
        });

        setMembers(filteredData);
        dispatch(helperActions.getUserData(filteredData));
        filteredData.forEach((u) => {
          mapIcons[`${u.uid}avatar`] = u?.avatar;
          preloadImages().then(() => {
            Object.entries(mapImages).forEach(([key, value]) => {
              if (key === `${u.uid}avatar-primary`
                || key === `${u.uid}avatar-positive`
                || key === `${u.uid}avatar-negative`
                || key === `${u.uid}avatar-neutral`) {
                if (map.hasImage(key)) {
                  map.updateImage(key, value);
                } else {
                  map.addImage(key, value);
                }
              }
            });
          });
        });
      } else {
        console.log('Request failed');
      }
    } catch (error) {
      console.log('Error occurred:', error);
    }
  };

  const handleClose = () => {
    setDevicesOpen(!devicesOpen);
  };

  useEffect(() => setDevicesOpen(desktop), [desktop]);

  useEffect(() => {
    if (!desktop && mapOnSelect && selectedDeviceId) {
      setDevicesOpen(false);
    }
  }, [desktop, mapOnSelect, selectedDeviceId]);

  const onClick = useCallback(
    (_, deviceId, position) => {
      dispatch(devicesActions.select(deviceId));
      if (position) {
        map.flyTo({
          center: [position.longitude, position.latitude],
          offset: [0, 0],
          zoom: 18,
          essential: true,
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    const positionCallbacks = [];

    userIds.forEach((userId) => {
      const positionRef = ref(database, `profiles/${userId}`);
      const positionCallback = (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const positionData = [];
          positionData.push({
            id: userId,
            data: data?.current,
            privateMode: data?.map_setting?.get_private_mode,
          });
          if (data.current) {
            setRealTimePosition((prevPosition) => {
              const currentPosition = prevPosition.filter((prev) => positionData[0].id !== prev.id);
              return [...currentPosition, ...positionData];
            });
          }
          setOpenDialog(false);
        } else {
          // setOpenDialog(true);
        }
      };
      positionCallbacks.push({ ref: positionRef, callback: positionCallback });
    });
    positionCallbacks.forEach(({ ref, callback }) => {
      onValue(ref, callback);
    });

    return () => ({});
  }, []);

  // console.log(realTimePosition);

  useEffect(() => {
    fetchData();
    const timer = setInterval(() => {
      fetchData();
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Kết hợp dữ liệu từ cả hai mảng dựa trên uid
  const combinedDataMembers = members.map((member) => {
    const position = realTimePosition.find((pos) => pos.id === member.uid);

    if (position == null) {
      return null;
    }

    return {
      ...member,
      acc: position ? position.data.acc : null,
      bat: position ? position.data.bat : null,
      buzzer_flag: position ? position.data.buzzer_flag : null,
      lat: position ? position.data.lat : null,
      lng: position ? position.data.lng : null,
      spd: position ? position.data.spd : null,
      status_flag: position ? position.data.status_flag : null,
      time: position ? position.data.time : null,
      date: position ? position.data.date : null,
      address: position ? position.data.address : null,
      privateMode: position ? position.privateMode : null,
    };
  }).filter((member) => member != null && member.privateMode === true);

  /**
   * Tạo mảng mới để lưu kết quả
   */
  useEffect(() => {
    const clickedMember = combinedDataMembers.filter((member) => member.uid === selectedDeviceId);
    setSelectedMember(clickedMember);
    dispatch(devicesActions.getMembers(clickedMember));
    const mergedData = realTimePosition.filter((pos) => pos.privateMode === true).map((position) => {
      const matchingMember = members.find((member) => member.uid === position.id);

      const mergedObject = {
        id: position.id,
        attributes: {
          batteryLevel: position.data ? position.data.bat : '',
          distance: '',
        },
        deviceId: matchingMember?.user_id || '',
        latitude: position.data ? position.data.lat : '',
        longitude: position.data ? position.data.lng : '',
        speed: position.data ? position.data.spd : '',
        address: '',
        accuracy: position.data ? position.data.acc : '',
        status: 'online',
        avatar: matchingMember?.avatar || position.data.avatar,
        name: matchingMember?.name || '',
      };

      return mergedObject;
    });

    setPositions(mergedData);
  }, [realTimePosition, members, selectedDeviceId]);

  useEffect(() => {
    const position = realTimePosition.find((v) => v.id === selectedDeviceId);
    dispatch(positionsActions.getTodayHistory(position?.data || []));
  }, [selectedDeviceId]);

  // const getLocations = () => {
  //   const positionsArray = Object.values(combinedDataMembers).map((it) => ({ latitude: it.lat, longitude: it.lng, deviceId: it.uid }));
  //   positionsArray.forEach(({ latitude, longitude, deviceId }) => {
  //     const showAddress = async () => {
  //       if (positionsArray.length > 0 && latitude && longitude) {
  //         const query = new URLSearchParams({ latitude, longitude });
  //         const response = await fetch(`https://tre-aws-stg.tks.asia/api/server/geocode?${query.toString()}`);
  //         if (response.ok) {
  //           const getAddress = await response.text();
  //           const splittedAddress = getAddress.split(', ').slice(0, -2);
  //           const reversedAddress = splittedAddress.reverse();
  //           const updatedAddress = reversedAddress.join(' ');
  //           dispatch(positionsActions.pushAddress({ updatedAddress, deviceId }));
  //         } else {
  //           // throw Error(await response.text());
  //           console.log('error: ', await response.text());
  //         }
  //       }
  //     };
  //     showAddress();
  //   });
  // };

  // // lấy địa chỉ
  // const debouncedFunction = _.debounce(getLocations, 60000);

  // useEffectAsync(async () => {
  //   getLocations();
  //   debouncedFunction();
  // }, [combinedDataMembers]);

  return (
    <>
      <div className={classes.root}>
        <MapView>
          <MapPopUp />
          <MapOverlay />
          {mapGeofences && <MapGeofence />}
          <MapAccuracy />
          {mapLiveRoutes && <MapLiveRoutes />}
          <MapPositions
            positions={positions}
            members={members}
            onClick={onClick}
            showStatus
          />
          {selectedPosition && selectedPosition.course && (
            <MapDirection position={selectedPosition} />
          )}
          <MapDefaultCamera location={realTimePosition} />
          <MapSelectedDevice />
          <PoiMap />
        </MapView>
        <MapScale />
        <MapCurrentLocation />
        {desktop && (
          <MapPadding
            left={parseInt(theme.dimensions.drawerWidthDesktop, 10)}
          />
        )}
        {/* <Switch
          className={classes.switchReplay}
          disabled={!selectedDeviceId}
          onChange={() => {
            dispatch(helperActions.openHistory());
          }}
        /> */}
        {/* { desktop && selectedDeviceId && (
        <div className={classes.statusCard}>
          <StatusCard
            deviceId={selectedDeviceId}
            position={selectedPosition}
            selectedMember={selectedMember}
            onClose={() => {
              dispatch(devicesActions.select(null));
              fetchData();
            }}
          />
        </div>
        )} */}
        {desktop && (
          <Button
            variant="contained"
            color={phone ? 'secondary' : 'primary'}
            classes={{ containedPrimary: classes.sidebarToggleBg }}
            className={classes.sidebarToggle}
            onClick={handleClose}
            disableElevation
          >
            <ListIcon />
            <div className={classes.sidebarToggleText}>{t('deviceTitle')}</div>
          </Button>
        )}
        <Paper
          square
          elevation={3}
          className={`${classes.sidebar} ${
            !devicesOpen && classes.sidebarCollapsed
          }`}
          style={{ display: desktop || 'none' }}
        >
          <Paper square elevation={3} className={classes.toolbarContainer}>
            <Toolbar className={classes.toolbar} disableGutters>
              {!desktop && (
                <IconButton edge="start" sx={{ mr: 2 }} onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              {desktop && (
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )}
            </Toolbar>
          </Paper>
          <div className={classes.deviceList}>
            <DevicesList devices={selectedMember.length > 0 ? selectedMember : combinedDataMembers} />
          </div>
        </Paper>
      </div>
      <div>
        {!desktop && (<div><DeviceDrawer deviceId={selectedDeviceId} height={260} list={selectedMember.length > 0 ? selectedMember : combinedDataMembers} onlyOne closeBtn /></div>)}
      </div>
      <Dialog open={openDialog} className={classes.dialogBox}>
        <div className={classes.dialog}>
          <div className={classes.dialogText}>{t('sharingText')}</div>
          <Button
            className={classes.dialogBtn}
            onClick={() => {
              setOpenDialog(false);
              dispatch(helperActions.closeHistory());
            }}
          >
            {t('close')}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default LocationSharingPage;
