/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Button, Typography, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LaunchIcon from '@mui/icons-material/Launch';
import moment from 'moment';
import Header from '../tools/components/Header';
import TableConfig from '../components/TableConfig';
import { useTranslation } from '../../common/components/LocalizationProvider';
import QrCode from '../components/QRCode';
import fetchApi from '../FetchApi';
import NewTable from '../components/NewTable';
import { Member, PaymentStatus } from '../common/DefineClass';
import { convertWidth, valueTranslate } from '../common/utils';

const userModel = new Member();

const useStyle = makeStyles((theme) => ({
  editBtn: {
    display: 'inline-flex',
    color: 'black',
    textDecoration: 'none',
    backgroundColor: '#e0e0e0',
    width: '90px',
    height: '40px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    margin: '0 1rem 1rem 0',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  qrcodeBox: {
    margin: '1rem 1rem 1rem 3rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '1rem',
    },
  },
  configTable: {
    height: '600px',
  },
  invitationCode: {
    fontSize: 20,
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#222A35',
  },
}));

// declare column percentage
const listColumPercent = {
  category: 8,
  status: 10,
  name: 12,
  userId: 8,
  disabled: 7,
  email: 20,
  password: 10,
  representId: 9,
  startDate: 10,
  power: 8,
  battery: 10,
  longlat: 25,
  accquisitionTime: 15,
  actions: 6,
  ratePlan: 15,
  expirationDate: 10,
};

const DetailTable = () => {
  const navigate = useNavigate();
  const classes = useStyle();
  const t = useTranslation();
  const { id } = useParams();

  const [users, setUsers] = useState([]);
  const [manager, setManager] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi(`/api/admin/members/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const userList = await response.json();
        const newData = [];
        const transformedUsersData = userList.map((data) => ({
          ...data,
          billingmethod: data.billing_method,
          userpaymentid: data.user_payment_id,
          lastbillingdate: data.last_billing_date,
          nextbillingdate: data.next_billing_date,
          updatedate: data.update_date,
          paymentmethod: data.payment_method,
          paymentnumber: data.payment_number,
          paymentspan: data.payment_span,
          rateplan: data.rate_plan,
        }));
        transformedUsersData.forEach((val) => {
          const cond = newData.find((data) => data.id === val.id);
          if (!cond) newData.push(val);
        });
        setManager(newData.find((data) => data.role === 1));
        setUsers(newData);
      }
    };

    if (id) fetchData();
  }, [id]);

  const detailsColumnDefs = [
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: userModel.field2,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    },
    {
      headerName: t('statusTableHeader'),
      width: convertWidth(listColumPercent.status),
      field: userModel.field3,
      valueFormatter: (params) => valueTranslate(t, params.data.paymentstatuscode, 'paymentStatus'),
    },
    {
      headerName: t('nameTableHeader'),
      width: convertWidth(listColumPercent.name),
      field: userModel.field4,
    },
    {
      headerName: t('userIdTableHeader'),
      width: convertWidth(listColumPercent.userId),
      field: userModel.field5,
    },
    {
      headerName: t('userStatusTableHeader'),
      width: convertWidth(listColumPercent.disabled),
      field: userModel.field6,
      cellRenderer: (params) => valueTranslate(t, params.value, 'account'),
    },
    {
      headerName: t('userEmailTableHeader'),
      width: convertWidth(listColumPercent.email),
      field: userModel.field7,
    },
    {
      headerName: t('passwordTableHeader'),
      width: convertWidth(listColumPercent.password),
      field: userModel.field13,
    },
    {
      headerName: t('representIdTableHeader'),
      width: convertWidth(listColumPercent.representId),
      field: userModel.field9,
      cellRenderer: (params) => {
        const { data } = params;
        const { representationId, managedUserId } = data;
        return representationId || managedUserId;
      },
    },
    {
      headerName: t('startDateTableHeader'),
      width: convertWidth(listColumPercent.startDate),
      field: userModel.field12,
      cellRenderer: (params) => {
        const inputDate = params.value;
        const formattedDate = moment(inputDate).format('YYYY/MM/DD');
        return formattedDate;
      },
    },
    {
      headerName: t('powerTableHeader'),
      width: convertWidth(listColumPercent.power),
      field: userModel.field14,
    },
    {
      headerName: t('batteryTableHeader'),
      width: convertWidth(listColumPercent.battery),
      field: userModel.field15,
    },

    // START --change field LatAndLng to HistoryList wireframe 4.0 - 2023/10/10
    {
      headerName: t('historyList'),
      width: convertWidth(listColumPercent.longlat),
      field: 'historyList',
      cellRenderer: ((params) => (
        <div key={params.data.userModel?.field4} style={{ width: '100%' }}>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(`/admin/home/list/details/history/${params.data.id}`);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ margin: '0', textAlign: 'left' }}>
                {params.data.latitude}
                ,
              </p>
              <p style={{ margin: '0', textAlign: 'left' }}>{params.data.longitude}</p>
            </div>
          </Link>
        </div>
      )),
    },
    // END --change field LatAndLng to HistoryList wireframe 4.0 - 2023/10/10

    {
      headerName: t('acquisition'),
      width: convertWidth(listColumPercent.accquisitionTime),
      field: userModel.field16,
    },
    {
      headerName: t('billingMethodTableHeader'),
      field: userModel.field11,
      width: convertWidth(listColumPercent.billingMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.billingmethod, 'billingMethod'),
    },
    {
      headerName: t('paymentTableHeader'),
      field: userModel.field19,
      width: convertWidth(listColumPercent.paymentMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.paymentmethod, 'paymentMethod'),
    },
    {
      headerName: t('ratePlanTableHeader'),
      field: userModel.field18,
      width: convertWidth(listColumPercent.ratePlan),
      valueFormatter: (params) => valueTranslate(t, params.data.rate_plan, 'ratePlan'),
    },
    {
      headerName: t('expiryDateTableHeader'),
      width: convertWidth(listColumPercent.expirationDate),
      field: userModel.field28,
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
    },
  ];

  // START --change field LatAndLng to HistoryList wireframe 4.0 - 2023/10/10
  // Biến đổi data để thêm trường historyList
  const transformedUsersData = users.map((object) => ({
    ...object,
    historyList: `${object.latitude},${object.longitude}`,
  }));
    // END --change field LatAndLng to HistoryList wireframe 4.0 - 2023/10/10

  const dataTable = {
    listData: transformedUsersData,
    floatingFilterOff: true,
    className: classes.configTable,
    newCustomeColumn: detailsColumnDefs,
    rowSelection: false,
    rowHeight: 50,
    childrenTop: (
      <div className={classes.listBtn}>
        <Button onClick={() => navigate('edit')} className={classes.editBtn}>
          <LaunchIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
          <div>{t('sharedEdit')}</div>
        </Button>
      </div>
    ),
    childrenBottom: (
      <div className={classes.qrcodeBox}>
        <Typography className={classes.invitationCode}>{t('qrcode')}</Typography>
        <QrCode qrCodeData={manager?.inviteCode} />
        <Typography className={classes.invitationCode}>{t('invitationCode')}</Typography>
        <Typography fontSize={26}>{manager?.inviteCode}</Typography>
      </div>
    ),
  };

  return (
    <>
      <Header link={-1} noDescription />
      <NewTable {...dataTable} />
    </>
  );
};

export default DetailTable;
