import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BreadcrumbsCustom from '../../components/BreadcrumbsCustom';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyle = makeStyles(() => ({
  backBtn: {
    backgroundColor: '#7f7f7f',
    display: 'flex',
    color: 'white',
    width: '130px',
    justifyContent: 'space-evenly',
    fontSize: '20px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
}));

const Header = (props) => {
  const { children, className, link, handleOnNavigate, noDescription, noBack, listPath } = props;
  const navigate = useNavigate();
  const classes = useStyle();
  const t = useTranslation();

  const handleButton = () => {
    if (handleOnNavigate) {
      handleOnNavigate();
    } else {
      navigate(link || '/admin/tools');
    }
  };

  return (
    <div>
      {noDescription ? (
        <div className={className} style={{ marginBottom: '1rem' }}>
          {!noBack && (
          <Button className={classes.backBtn} onClick={() => handleButton()}>
            <ArrowForwardIosIcon />
            {t('back')}
          </Button>
          )}

          <BreadcrumbsCustom listPath={listPath} getAfter="admin" />
          {children}
        </div>
      ) : (
        <div className={className} style={{ marginBottom: '1rem' }}>
          {!noBack && (
          <Button className={classes.backBtn} onClick={() => handleButton()}>
            <ArrowForwardIosIcon />
            {t('back')}
          </Button>
          )}

          <BreadcrumbsCustom listPath={listPath} getAfter="admin" />
          {children}
        </div>
      )}
    </div>
  );
};

export default Header;
