import React from 'react';
import { useController } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DatePicker } from '@mui/x-date-pickers';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import LabelAdd from '../LabelAdd';

const FormDatePicker = ({ label, required, controllerProps, dateTimePickerProps }) => {
  const { field, fieldState } = useController(controllerProps);
  const { error } = fieldState;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {label && <LabelAdd label={label} required={required} />}
      <FormControl className="form-control" error={!!error}>
        <DatePicker
          value={field.value || null}
          {...dateTimePickerProps}
          {...field}
        />
        {error && <FormHelperText>{error.message}</FormHelperText>}
      </FormControl>
    </LocalizationProvider>
  );
};

export default FormDatePicker;
