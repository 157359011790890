import React, { useState } from 'react';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTranslation } from './LocalizationProvider';
// import {
//   useManager,
// } from '../util/permissions';
import { helperActions, sessionActions } from '../../store';

const useStyles = makeStyles(() => ({
  menu: {
    padding: '42.23px 27.81px 0 27.81px',
    '& .MuiTypography-root': {
      fontSize: '14px',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    '& .MuiListItemButton-root': {
      padding: '0 !important',
      marginBottom: '43px',
      alignItems: 'flex-start',
    },
    '& .MuiListItemIcon-root': {
      minWidth: 'fit-content',
      paddingRight: '15px',
      paddingTop: '2px',
    },
    '& .MuiListItemText-root': {
      margin: '0 !important',
      color: '#6C6A6A',
    },
  },
  configIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
}));

const MenuItem = ({ title, link, icon, selected }) => {
  const classes = useStyles();
  return (
    <ListItemButton key={link} component={Link} to={link} selected={selected}>
      <ListItemIcon className={classes.configIcon}>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

const MenuPilot = ({ style }) => {
  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // const manager = useManager();
  const userId = useSelector((state) => state.session.user.id);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleAccount = () => {
    navigate(`/settings/user/${userId}`);
  };

  const handleLogout = async () => {
    dispatch(helperActions.unActive());
    await fetch('/api/session', { method: 'DELETE' });
    navigate('/login');
    dispatch(sessionActions.updateUser(null));
  };
  return (
    <>
      <List style={style} className={classes.menu}>
        <MenuItem
          title={t('adminSettings')}
          link="/settings/preferences"
          icon={<SettingsOutlinedIcon />}
          // selected={location.pathname === '/settings/preferences'}
        />
        <MenuItem
          title={t('checkPayment')}
          link="/settings/notifications"
          icon={<CreditCardIcon />}
          // selected={location.pathname.startsWith('/settings/notification')}
        />
        <MenuItem
          title={t('add/delete')}
          link={`/settings/user/${userId}`}
          icon={<PersonAddOutlinedIcon />}
          // selected={location.pathname === `/settings/user/${userId}`}
        />
        <MenuItem
          title={t('areaNotifications')}
          link="/geofences"
          icon={<NotificationsOutlinedIcon />}
          // selected={location.pathname.startsWith('/settings/geofence')}
        />
        <MenuItem
          title={t('disaster')}
          link="/disaster"
          icon={<TransferWithinAStationIcon />}
          selected={location.pathname.startsWith('/disaster')}
        />
        <MenuItem
          title={t('faq')}
          link="/settings/calendars"
          icon={<AnnouncementOutlinedIcon />}
          // selected={location.pathname.startsWith('/settings/calendar')}
        />
        <MenuItem
          title={t('operations')}
          link="/settings/calendars"
          icon={<TouchAppOutlinedIcon />}
          // selected={location.pathname.startsWith('/settings/calendar')}
        />
      </List>
      {/* {manager && (
        <> */}
      <Divider />
      <List className={classes.menu}>
        <MenuItem
          title={t('mapTitle')}
          link="/"
          icon={<MapOutlinedIcon />}
          selected={location.pathname === '/'}
        />
        <MenuItem
          title={t('reportTitle')}
          link="/reports/route"
          icon={<DescriptionOutlinedIcon />}
          selected={location.pathname === '/reports/route'}
        />
        <MenuItem
          title={t('settingsTitle')}
          link="/settings/preferences"
          icon={<SettingsOutlinedIcon />}
          selected={location.pathname === '/settings/preferences'}
        />
        <MenuItem
          title={t('userAdmin')}
          link="/admin/home/list"
          icon={<AdminPanelSettingsOutlinedIcon />}
          selected={location.pathname === '/admin/home/list'}
        />
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t('settingsUser')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={handleAccount}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={t('settingsUser')} />
            </ListItemButton>
            <ListItemButton sx={{ pl: 4 }} onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('loginLogout')} />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      {/* </>
      )} */}
    </>
  );
};

export default MenuPilot;
