/* eslint-disable no-undef */
import React, { useState, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import fetchApi from '../../../FetchApi';
import Header from '../Header';
import ComfirmationPopup from '../ComfirmationPopup';
import NewTable from '../../../components/NewTable';
import { getUserIds } from '../../../common/utils';
import Vectorback from '../../../../resources/images/Vectorback.png';
import '../../../../styles/components/SuspensionOrResume.css';
import FormDatePicker from '../../../components/form/FormDatePicker';
import ModalSuccess from '../../../modal/ModalSuccess';
import ModalWarning from '../../../modal/ModalWarning';

const useStyles = makeStyles((theme) => ({
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  mobileStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const SuspensionOrResumeNew = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const { control, watch, reset, formState: { isValid } } = useForm({ mode: 'onChange' });

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModalDateSetting, setOpenModalSetting] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const checkedList = useSelector((state) => state.helper.checkedList);

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleCloseDateSetting = () => setOpenModalSetting(false);

  const handleSubmit = async (status, sendDate = false) => {
    const obj = {
      userIds,
      disable: status,
    };
    if (sendDate) {
      obj.nextBillingDate = watch('nextBillingDate').valueOf();
    }
    const response = await fetchApi('/api/admin/users/change_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    });
    if (response.ok) {
      if (sendDate) {
        setOpenSuccess(true);
      }
      reset();
      setRefreshData(true);
      handleCloseDateSetting();
    }
  };

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    refreshData,
    setRefreshData,
    removeColumn: [1],
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  const checkGPSDevice = () => {
    if (dataSelect?.some((value) => value?.category1 === 1)) {
      setOpenWarning(true);
    } else {
      setOpenModalSetting(true);
    }
  };

  return (
    <div>
      <Header link={-1} listPath="tools/pathSuspensionOrResume" noDescription noBack>
        <p className="title-page">{t('pathSuspensionOrResume')}</p>
        <div className="button-item">
          <Typography>{t('descriptionSuspensionOrResumePage')}</Typography>
          <div className={classes.mobileStyle}>
            <ComfirmationPopup
              handleOption={() => checkGPSDevice()}
              styleButton={{ margin: '15px' }}
              btnColor="#02AA9EB2"
              btnTitle={t('endDateSetting')}
              width="123px"
              confirmText={t('confirmContinue')}
              disabled={userIds.length === 0}
              confirmContinue
            />
            <ComfirmationPopup
              backGroundColor="#EE7357"
              styleButton={{ margin: '15px' }}
              btnColor="#EE7357"
              btnTitle={t('reportStops')}
              submit={() => handleSubmit(true)}
              width="123px"
              confirmText={t('notifyChangePausesUser')}
              disabled={userIds.length === 0}
            />
            <ComfirmationPopup
              backGroundColor="#4DB4E1"
              styleButton={{ margin: '15px' }}
              btnColor="#4DB4E1"
              btnTitle={t('suspensionOrResumeRestart')}
              submit={() => handleSubmit(false)}
              width="123px"
              confirmText={t('notifyChangeUsingUser')}
              disabled={userIds.length === 0}
              confirmContinue
            />
          </div>
          <Button className="button-back" onClick={() => navigate(-1)}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
        </div>
      </Header>
      <NewTable {...dataTable} />
      <Modal
        open={openModalDateSetting}
        onClose={handleCloseDateSetting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container-modal-date-setting">
          <div className="content-modal">
            <Typography>{t('endDateTitleModal')}</Typography>
            <FormDatePicker
              controllerProps={{
                control,
                name: 'nextBillingDate',
                rules: {
                  required: t('fieldNotNull'),
                  validate: (value) => {
                    if (!value) return true;
                    return moment(value).isBefore(moment(), 'day') ? t('datePre') : true;
                  },
                },
              }}
              dateTimePickerProps={{
                className: 'date-picker',
                format: 'YYYYMMDD',
                minDate: moment(),
              }}
            />
          </div>
          <div className="container-button">
            <Button className="btn-cancel" onClick={handleCloseDateSetting}>{t('sharedCancel')}</Button>
            <Button disabled={!isValid} className="btn-perform" onClick={() => handleSubmit(false, true)}>{t('sharedPerform')}</Button>
          </div>
        </Box>
      </Modal>
      <ModalWarning
        openModal={openWarning}
        handleClose={() => {
          setOpenWarning(false);
        }}
        propsModal={{
          confirm: t('messageWarningSuspension1'),
          subTitle: t('messageWarningSuspension2'),
          titleCancel: t('sharedReturn'),
        }}
      />
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={() => {
          setOpenSuccess(false);
        }}
        title={t('suspensionOrResumePopupTitleSuccess')}
      />
    </div>
  );
};

export default SuspensionOrResumeNew;
