import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIds } from '../common/utils';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Vectorback from '../../resources/images/Vectorback.png';
import Header from '../tools/components/Header';
import '../../styles/components/ListAccountExportCSV.css';
import fetchApi from '../FetchApi';
import ModalConfirm from '../modal/ModalConfirm';
import ModalSuccess from '../modal/ModalSuccess';
import NewTable from '../components/NewTable';
import StatiticUsers from './components/StatiticUsers';

const DeleteAccount = () => {
  const navigate = useNavigate();
  const tableRef = useRef();
  const t = useTranslation();
  const [dataSelect, setDataSelect] = useState([]);
  const [gridApi, getGridApi] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const checkedList = useSelector((state) => state.helper.checkedList);

  const userIds = getUserIds(checkedList, dataSelect);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteUser = async () => {
    const response = await fetchApi('/api/admin/users', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userIds }),
    });
    if (response.ok) {
      setOpenSuccess(true);
    } else {
      console.log('not ok');
    }
  };

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    refreshData,
    setRefreshData,
    tableRef,
    floatingFilter: true,
    showPagination: true,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  return (
    <>
      <div className="list-account-export">
        <Header listPath="account" noDescription noBack topic="Export file CSV account">
          <div className="container-export">
            <StatiticUsers setRefreshData={setRefreshData} />
            <p className="title-header">{t('titleDeleteListAccount')}</p>
            <Button
              onClick={() => setOpenModal(true)}
              className="button-export"
              disabled={!(checkedList.length > 0 || dataSelect.length > 0)}
            >
              <div>{t('sharedRemove')}</div>
            </Button>
            <Button className="button-back" onClick={() => navigate('/admin/home/list')}>
              <img src={Vectorback} alt="icon back" />
              {t('back')}
            </Button>
          </div>
        </Header>
      </div>
      <NewTable {...dataTable} />
      <ModalConfirm
        openModal={openModal}
        handleClose={handleCloseModal}
        action={handleDeleteUser}
        propsModal={{
          confirm: t('accountDeleteListPopupTitle'),
          subTitle: t('accountDeleteListPopupSubTitle'),
          titleCancel: t('sharedCancel'),
          titleContinue: t('sharedPerform'),
        }}
      />
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={() => {
          setOpenSuccess(false);
          navigate('/admin/home/list');
        }}
        title={t('deleteCompleted')}
      />
    </>
  );
};

export default DeleteAccount;
