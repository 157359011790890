import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import DisasterButton from './DisasterButton';
import MenuPilot from '../common/components/MenuPilot';
import PageLayoutPilot from '../common/components/PageLayoutPilot';
import Image from '../resources/images/Example.png';
import { useTranslation } from '../common/components/LocalizationProvider';
// import { useNavigate } from 'react-router-dom';
import AssignmentIcon from '../resources/images/iconXD/AssignmentIcon.svg';

const Container = styled('div')({
  color: 'darkslategray',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: '56.25%',
    [theme.breakpoints.down('sm')]: {
      height: 255,
    },
    [theme.breakpoints.up('md')]: {
      height: '50%',
    },
  },
  background: {
    width: '100%',
    height: 0,
    paddingTop: '56.25%',
    [theme.breakpoints.down('sm')]: {
      height: 255,
    },
    [theme.breakpoints.up('md')]: {
      height: '50%',
    },
    backgroundImage: `url('${Image}')`,
    backgroundSize: '100%',
    backgroundPosition: 'center',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  btn: {
    padding: '10px 20px',
    width: '300px',
    height: '100px',
    margin: '0 auto',
    borderRadius: '7px',
    outline: 'none',
    marginTop: '20px',
    marginBottom: '20px',
    color: '#4B4B4B',
    boxShadow: '0 1px 5px #00000029',
    // opacity: 0,
  },
  btnIcon: {
    height: '54px',
    width: '43px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  u: {
    color: '#6C6A6A',
    fontSize: '12px',
    textDecoration: 'underline',
    textDecorationColor: '#606060',
  },
  icon: {
    width: '17px',
    height: '17px',
    color: '#6C6A6A',
  },
}));

const DisasterPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  // const navigate = useNavigate();

  const handleClick = () => {
    console.log('clicked');
  };

  return (
    <PageLayoutPilot menu={<MenuPilot />} breadcrumbs={['disaster']}>
      <Container>
        <div className={classes.background} />
        <div className={classes.textContainer}>
          <u className={classes.u}>{t('disasterPrevention')}</u>
          <ReportGmailerrorredIcon className={classes.icon} />
        </div>
        <DisasterButton style={{ marginTop: '20px' }} />
        <Button className={classes.btn} onClick={handleClick}>
          <Grid container spacing={2}>
            <Grid item xs={3} className={classes.container}>
              <img src={AssignmentIcon} alt="warning" className={classes.btnIcon} />
            </Grid>
            <Grid item xs={9} className={classes.container}>
              <div style={{ margin: '0 auto' }}>{t('familyRule')}</div>
            </Grid>
          </Grid>
        </Button>
      </Container>
    </PageLayoutPilot>
  );
};

export default DisasterPage;
