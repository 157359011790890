export default {
  sidebarWidth: '28%',
  sidebarWidthTablet: '52px',
  // sửa chiều rộng của sidebar
  drawerWidthDesktop: '430px',
  pageLayoutDesktop: '300px',
  // end
  drawerWidthTablet: '320px',
  drawerHeightPhone: '250px',
  filterFormWidth: '160px',
  eventsDrawerWidth: '320px',
  bottomBarHeight: 120,
  popupMapOffset: 300,
  popupMaxWidth: 600,
  popupImageHeight: 144,
};
