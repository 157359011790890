import React, { useEffect, useState } from 'react';
import {
  Container, TextField, InputLabel, Box, Select, MenuItem, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { renderTimeOptions } from '../components/utils';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles({
  selectField: {
    width: '70px', height: '40px',
  },

});

const PaymentForm = (props) => {
  const { formik } = props;
  const classes = useStyles();
  const t = useTranslation();

  return (
    <Container maxWidth="sm">
      <Box mt={1} />
      <InputLabel htmlFor="cn">
        {t('cardNumber')}
        *
      </InputLabel>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        placeholder={t('cardNumber')}
        id="cn"
        name="cn"
        type="text"
        inputProps={{ style: { textTransform: 'lowercase' } }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.cn}
        error={formik.touched.cn && Boolean(formik.errors.cn)}
        helperText={formik.touched.cn && formik.errors.cn}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Box mt={1} />
      <InputLabel htmlFor="ed">{t('cardExpiry')}</InputLabel>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel htmlFor="em" sx={{ margin: '0 10px' }}>{t('month')}</InputLabel>
          <Select
            id="em"
            defaultValue={1}
            name="em"
            value={formik.values.em}
            className={classes.selectField}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {renderTimeOptions(1, 12)}
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel htmlFor="ey" sx={{ margin: '0 10px' }}>{t('year')}</InputLabel>
          <Select
            id="ey"
            defaultValue={20}
            name="ey"
            value={formik.values.ey}
            className={classes.selectField}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {renderTimeOptions(20, 29)}
          </Select>
        </div>
      </div>

      <Box display="flex" flexDirection="column">
        <TextField
          fullWidth
          style={{ display: 'none' }}
          variant="outlined"
          id="aid"
          name="aid"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.aid}
        />

        <Box mt={1} />
        <InputLabel htmlFor="fn">{t('firstName')}</InputLabel>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder={t('firstName')}
          id="fn"
          name="fn"
          type="text"
          onChange={(e) => {
            const upperCaseValue = e.target.value.toUpperCase();
            formik.handleChange(e);
            formik.setFieldValue('fn', upperCaseValue);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.fn}
          error={formik.touched.fn && Boolean(formik.errors.fn)}
          helperText={formik.touched.fn && formik.errors.fn}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Box mt={1} />
        <InputLabel htmlFor="ln">{t('lastName')}</InputLabel>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder={t('lastName')}
          id="ln"
          name="ln"
          type="text"
          onChange={(e) => {
            const upperCaseValue = e.target.value.toUpperCase();
            formik.handleChange(e);
            formik.setFieldValue('ln', upperCaseValue);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.ln}
          error={formik.touched.ln && Boolean(formik.errors.ln)}
          helperText={formik.touched.ln && formik.errors.ln}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Box mt={1} />
        <InputLabel htmlFor="cvv">
          {t('securityCode')}
          *
        </InputLabel>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder={t('securityCode')}
          id="cvv"
          name="cvv"
          type="text"
          inputProps={{ style: { textTransform: 'lowercase' } }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.cvv}
          error={formik.touched.cvv && Boolean(formik.errors.cvv)}
          helperText={formik.touched.cvv && formik.errors.cvv}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <Box mt={1} />
        <InputLabel htmlFor="pn">{t('commandPhone')}</InputLabel>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder={t('commandPhone')}
          id="pn"
          name="pn"
          type="text"
          inputProps={{ style: { textTransform: 'lowercase' } }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.pn}
          error={formik.touched.pn && Boolean(formik.errors.pn)}
          helperText={formik.touched.pn && formik.errors.pn}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </Container>
  );
};

export default PaymentForm;
