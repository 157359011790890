import React, {
  useId, useCallback, useEffect, useState,
} from 'react';
import { map } from './core/MapView';
import { formatTime, getStatusColor } from '../common/util/formatter';
import preloadImages, { mapIconKey, mapIcons, mapImages } from './core/preloadImages';
import { findFonts } from './core/mapUtil';
import { useAttributePreference, usePreference } from '../common/util/preferences'; // v5.6
import DefaultImage from '../location_sharing/resource/default_avatar.png';

const MapPositionsLocationSharing = ({ positions, onClick, showStatus, selectedPosition, titleField, members }) => {
  // const id = 'positions';
  const id = useId();
  const clusters = `${id}-clusters`;
  const direction = `${id}-direction`;

  // const devices = useSelector((state) => state.devices.items);
  // const [mapCluster] = usePersistedState('mapCluster', true); //v5.2
  const iconScale = useAttributePreference('iconScale', 1);
  const mapCluster = useAttributePreference('mapCluster', true);
  const hours12 = usePreference('twelveHourFormat');
  const directionType = useAttributePreference('mapDirection', 'selected');

  const [userData, setUserData] = useState({});

  useEffect(() => {
    const transformedObject = {};

    positions.forEach((item) => {
      const matchingMember = members.find((member) => member.uid === item.id);

      if (matchingMember) {
        const transformedItem = {
          id: item.id,
          attributes: {},
          deviceId: matchingMember.user_id,
          latitude: item.latitude,
          longitude: item.longitude,
          altitude: '',
          speed: item.speed,
          accuracy: item.accuracy,
          avatar: matchingMember.avatar || DefaultImage,
          name: matchingMember?.name,
          category: `${item.id}avatar`,
        };

        transformedObject[item.id] = transformedItem;
      }
    });
    setUserData(transformedObject);
  }, [positions]);

  const createFeature = (devices, position) => {
    const device = devices[position.id];
    // const device = devices[position.deviceId];
    // console.log(device);
    let showDirection;
    switch (directionType) {
      case 'none':
        showDirection = false;
        break;
      case 'all':
        showDirection = true;
        break;
      default:
        showDirection = false;
        break;
    }

    preloadImages().then(() => {
      Object.entries(mapImages).forEach(([key, value]) => {
        if (key === `${device.id}avatar-primary`
          || key === `${device.id}avatar-positive`
          || key === `${device.id}avatar-negative`
          || key === `${device.id}avatar-neutral`) {
          if (map.hasImage(key)) {
            map.updateImage(key, value);
          } else {
            map.addImage(key, value);
          }
        }
      });
    });

    return {
      id: position.id,
      deviceId: position.deviceId,
      name: device.name || '(No name)',
      fixTime: formatTime(position.fixTime, 'seconds', hours12),
      category: mapIconKey(device.category),
      color: showStatus ? position.attributes.color || getStatusColor(device.status) : 'neutral',
      rotation: position.course,
      direction: showDirection,
      latitude: position.latitude,
      longitude: position.longitude,
    };
  };

  const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
  const onMouseLeave = () => map.getCanvas().style.cursor = '';

  const onMapClick = useCallback((event) => {
    if (!event.defaultPrevented && onClick) {
      onClick();
    }
  }, [onClick]);

  const onMarkerClick = useCallback((event) => {
    event.preventDefault();
    const feature = event.features[0];
    if (onClick) {
      onClick(feature.properties.deviceId, feature.properties.id, feature.properties);
    }
  }, [onClick]);

  const onClusterClick = useCallback((event) => {
    event.preventDefault();
    const features = map.queryRenderedFeatures(event.point, {
      layers: [clusters],
    });
    const clusterId = features[0].properties.cluster_id;
    map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
      if (!error) {
        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom,
        });
      }
    });
  }, [clusters]);

  useEffect(() => {
    map.getLayer(id);
    map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      cluster: mapCluster,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });

    map.addLayer({
      id,
      type: 'symbol',
      source: id,
      filter: ['!has', 'point_count'],
      layout: {
        'icon-image': '{category}-{color}',
        'icon-size': iconScale,
        'icon-allow-overlap': true,
        'icon-offset': [0, -50 * iconScale],
        'text-field': `{${titleField || 'name'}}`,
        'text-allow-overlap': true,
        'text-offset': [0, -8 * iconScale],
        'text-font': findFonts(map),
        'text-size': 12,
      },
    });
    map.addLayer({
      id: clusters,
      type: 'symbol',
      source: id,
      filter: ['has', 'point_count'],
      layout: {
        'icon-image': 'background',
        'icon-size': iconScale,
        'icon-offset': [0, -50 * iconScale],
        'text-field': '{point_count_abbreviated}',
        'text-allow-overlap': true,
        'text-offset': [0, -4.5 * iconScale],
        'text-font': findFonts(map),
        'text-size': 14,
      },
    });
    map.addLayer({
      id: direction,
      type: 'symbol',
      source: id,
      filter: [
        'all',
        ['!has', 'point_count'],
        ['==', 'direction', true],
      ],
      layout: {
        'icon-image': 'direction',
        'icon-size': iconScale,
        'icon-allow-overlap': true,
        'icon-rotate': ['get', 'rotation'],
        'icon-rotation-alignment': 'map',
      },
    });

    map.on('mouseenter', id, onMouseEnter);
    map.on('mouseleave', id, onMouseLeave);
    map.on('mouseenter', clusters, onMouseEnter);
    map.on('mouseleave', clusters, onMouseLeave);
    map.on('click', id, onMarkerClick);
    map.on('click', clusters, onClusterClick);
    map.on('click', onMapClick);

    return () => {
      map.off('mouseenter', id, onMouseEnter);
      map.off('mouseleave', id, onMouseLeave);
      map.off('mouseenter', clusters, onMouseEnter);
      map.off('mouseleave', clusters, onMouseLeave);
      map.off('click', id, onMarkerClick);
      map.off('click', clusters, onClusterClick);
      map.off('click', onMapClick);

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getLayer(clusters)) {
        map.removeLayer(clusters);
      }
      if (map.getLayer(direction)) {
        map.removeLayer(direction);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, [mapCluster, clusters, direction, onMarkerClick, onClusterClick, map, id]);

  useEffect(() => {
    map.getSource(id).setData({
      type: 'FeatureCollection',
      features: positions.filter((it) => userData.hasOwnProperty(it.id)).map((position) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [position.longitude, position.latitude],
        },
        properties: createFeature(userData, position),
      })),
    });
  }, [userData, positions, selectedPosition]);

  return null;
};

export default MapPositionsLocationSharing;
