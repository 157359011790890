/* eslint-disable no-undef */
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import NewTable from '../../../components/NewTable';
import Header from '../Header';
import ComfirmationPopup from '../ComfirmationPopup';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { helperActions } from '../../../../store';
import fetchApi from '../../../FetchApi';
import { getUserIds } from '../../../common/utils';

const useStyles = makeStyles(() => ({
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  configBtn: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem',
  },
}));

const ResetPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);

  const checkedList = useSelector((state) => state.helper.checkedList);

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleResetUser = async () => {
    const response = await fetchApi('/api/admin/members/reset_account', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userIds),
    });
    if (response.ok) {
      dispatch(helperActions.setPathname(location.pathname));
      navigate('/admin/finish');
    } else {
      console.log(response);
    }
  };

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    removeColumn: [1],
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  return (
    <div>
      <Header>
        <Typography>{t('resetPageTitle')}</Typography>
      </Header>
      <NewTable {...dataTable} />
      <div className={classes.configBtn}>
        <ComfirmationPopup
          btnTitle={t('perform')}
          confirmText={t('confirmReset1')}
          subConfirmText={t('confirmReset2')}
          submit={handleResetUser}
          disabled={userIds.length === 0}
          btnColor="#ff0505"
          links="/admin/tools"
        />
      </div>
    </div>
  );
};

export default ResetPage;
