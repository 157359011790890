/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { Checkbox, TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    color: '#ea8440',
    '&.Mui-checked': {
      color: '#ea8440',
    },
  },
  emailField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
}));

const EmailConstant = {
  EMAIL: 'EMAIL',
  EMAIL_CHECKBOX: 'EMAIL_CHECKBOX',
};

const initValue = {
  enabled: false,
  email: '',
};

const EmailAddress = ({ data, index, setData }) => {
  const classes = useStyles();

  const [emailData, setEmailData] = useState(initValue);
  const [error, setError] = useState('');

  const validateInput = (value) => {
    if (value !== '' && !/^(?!.*_\w*)\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return 'Invalid email format';
    }
    return '';
  };

  const handleChangeFieldEmail = (type, value) => {
    const cloneValue = emailData;

    switch (type) {
      case EmailConstant.EMAIL: {
        setEmailData({ ...cloneValue, email: value });
        const validationError = validateInput(value);
        setError(validationError);
        break;
      }
      case EmailConstant.EMAIL_CHECKBOX: {
        setEmailData({ ...cloneValue, enabled: value });
        break;
      }
      default:
    }
  };

  useEffect(() => {
    const cloneMasterData = data;
    const newMasterData = cloneMasterData.map((masterData) => {
      if (masterData.index === index) {
        return { ...emailData, index };
      }
      return masterData;
    });
    setData(newMasterData);
  }, [emailData]);

  const addEmailComponent = useCallback(() => [initValue].map((index) => (
    <div className={classes.emailField} key={`mail${index}`}>
      <TextField
        id={`mail${index}`}
        label="Email"
        error={Boolean(error)}
        onChange={(e) => handleChangeFieldEmail(EmailConstant.EMAIL, e.target.value)}
      />
      <div style={{ textAlign: 'end', flexGrow: '1' }}>
        <Checkbox
          className={classes.checkBox}
          onClick={(e) => handleChangeFieldEmail(EmailConstant.EMAIL_CHECKBOX, e.target.checked)}
        />

      </div>
    </div>
  )), [emailData]);

  return (
    <div>{addEmailComponent()}</div>
  );
};

export default EmailAddress;
