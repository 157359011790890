import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import fetchApi from '../../FetchApi';
import { helperActions } from '../../../store';

const StatiticUsers = ({ setRefreshData }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.helper.adminData.filter);
  const option = [
    {
      label: 'ALL',
    },
    {
      label: t('smartPhone'),
      category: 0,
      name: 'category1',
    },
    {
      label: t('gps'),
      category: 1,
      name: 'category1',
    },
  ];
  const [value, setValue] = React.useState();
  // add the filter of column category 1 to the body here
  const fetchTotalUser = async (newValue) => {
    let filterBy = [];
    if (newValue?.name) {
      filterBy.push({ name: newValue.name, type: '05', value: newValue.category });
    } else {
      filterBy = filterBy.filter((item) => item.name !== 'category1');
    }
    dispatch(helperActions.setAdminData({ filter: filterBy }));
    const response = await fetchApi('/api/admin/members/count', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ filterBy }),
    });
    const result = await response?.json();
    if (result.status === 'OK') {
      setValue(result?.data);
      setRefreshData(true);
    }
  };

  useEffect(() => { fetchTotalUser(value); }, []);
  return (
    <div>
      <Autocomplete
        onChange={(event, newValue) => {
          fetchTotalUser(newValue);
        }}
        defaultValue={option[0]}
        options={option}
        getOptionLabel={(option) => option.label}
        disableClearable
        sx={{ width: '380px' }}
        renderInput={(params) => (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <TextField
              {...params}
              sx={{
                '& .MuiInputBase-root': {
                  border: '2px solid #02AA9E',
                  color: '#02AA9E',
                  fontWeight: 500,
                  fontSize: 18,
                  height: '55px',
                  width: '350px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                },
              }}
            />
            <Box sx={{ position: 'relative', left: '-80px', color: '#02AA9E', fontSize: 25, fontWeight: 500 }}>{value}</Box>
          </Box>
        )}
      />
    </div>
  );
};

export default StatiticUsers;
