/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
  Button, useTheme, Typography, Tooltip, IconButton, FormControlLabel, Checkbox, FormControl, Select, Snackbar, MenuItem, InputAdornment, OutlinedInput, TextField, Divider, useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useLocalization, useTranslation } from '../common/components/LocalizationProvider';
import LoginLayout from './components/LoginLayout';
import usePersistedState from '../common/util/usePersistedState';
import LogoImage from './components/LogoImage';
import SignIn from './service/SignIn';
import LockIcon from './resources/lock-icon.svg';
import MailIcon from './resources/mail-icon.svg';
import { handleLoginTokenListeners, nativeEnvironment, nativePostMessage } from '../common/components/NativeInterface';
import { encrypted } from './components/utils';

const useStyles = makeStyles((theme) => ({
  containerDesktop: {
    display: 'flex',
    flexDirection: 'column',
    // gap: theme.spacing(1),
    border: '0.5px groove grey',
    padding: '30px',
  },
  containerMobile: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const LoginPaymentPage = ({ setIsAuth }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const t = useTranslation();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const languageEnabled = useSelector((state) => !state.session.server.attributes['ui.disableLoginLanguage']);

  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = '';
      try {
        const expiration = moment().add(6, 'months').toISOString();
        const response = await fetch('/api/session/token', {
          method: 'POST',
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = '';
      }
      nativePostMessage(`login|${token}`);
    }
  };

  const handlePasswordLogin = async (event) => {
    const encryptedPasword = encrypted(password);
    event.preventDefault();
    try {
      const response = await fetch('/api/session', {
        method: 'POST',
        body: new URLSearchParams(`email=${encodeURIComponent(email)}&password=${encodeURIComponent(encryptedPasword)}`),
      });
      if (response.ok) {
        const user = await response.json();
        if (user.administrator === false) {
          generateLoginToken();
          if (rememberMe === true) {
            localStorage.setItem('loginEmail', email);
            localStorage.setItem('loginPassword', password);
          }
          navigate('/payment');
          setRememberMe(false);
        } else {
          throw Error(await response.text());
        }
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      // setFailed(true);
      setPassword('');
    }
  };

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && email && password) {
      handlePasswordLogin(e);
    }
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem('loginEmail');
    const savedPassword = localStorage.getItem('loginPassword');

    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
    }
  }, []);

  const remember = (isChecked) => {
    setRememberMe(isChecked);
    if (isChecked) {
      localStorage.setItem('loginEmail', email);
      localStorage.setItem('loginPassword', password);
    } else {
      localStorage.removeItem('loginEmail');
      localStorage.removeItem('loginPassword');
    }
  };

  return (
    <LoginLayout>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '5px' }}>
          <LogoImage color={theme.palette.primary.main} />
        </div>
        <div className={desktop ? classes.containerDesktop : classes.containerMobile}>
          <div>
            <SignIn setIsAuth={setIsAuth} />
          </div>
          <Divider sx={{ margin: '20px 0' }} />
          <Typography sx={{ margin: '9px' }}>{t('local')}</Typography>
          <TextField
            id="email"
            required
            name="email"
            autoComplete="email"
            value={email}
            autoFocus={!email}
            placeholder={t('local')}
            onChange={(e) => setEmail(e.target.value)}
            // onKeyUp={handleSpecialKey}
            // helperText={failed && 'Invalid username or password'}
            sx={{ marginBottom: '5px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={MailIcon} alt="mail" />
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              required
              id="password"
              type={showConfirmPassword ? 'text' : 'password'}
              value={password}
              // error={failed}
              autoFocus={!!email}
              autoComplete="current-password"
              // onKeyUp={handleSpecialKey}
              placeholder={t('passwordField')}
              startAdornment={(
                <InputAdornment position="start">
                  <img src={LockIcon} alt="lock" />
                </InputAdornment>
                )}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                 )}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <FormControlLabel
            control={(
              <Checkbox
                checked={rememberMe}
                onChange={(e) => remember(e.target.checked)}
                name="rememberMe"
                sx={{
                  '&.Mui-checked': {
                    color: '#0EAA92',
                  },
                }}
              />
      )}
            label={t('loginRemember')}
          />
          <Button
            onClick={handlePasswordLogin}
            onKeyUp={handleSpecialKey}
            variant="contained"
            style={{ backgroundColor: '#0EAA92', borderRadius: '30px', margin: '10px 0' }}
            disabled={!email || !password}
          >
            {t('loginLogin')}
          </Button>
        </div>
      </div>
      {languageEnabled && (
      <div style={{ display: 'flex', alignItems: 'center', padding: '20px 0' }}>
        <Typography>{t('loginlanguage')}</Typography>
        <FormControl sx={{ paddingLeft: '10px' }}>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {languageList.map((it) => <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      )}
    </LoginLayout>
  );
};

export default LoginPaymentPage;
