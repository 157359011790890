import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Image from '../resources/images/alarmsetting-background.png';
import MenuPilot from '../common/components/MenuPilot';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayoutPilot from '../common/components/PageLayoutPilot';

const useStyles = makeStyles(() => ({
  background: {
    width: '100%',
    height: '185px',
    backgroundImage: `url('${Image}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'right bottom',
  },
  btn: {
    width: '312px',
    height: '105px',
    margin: '26px auto 15px auto',
    backgroundColor: '#FFD41F',
    fontSize: '20px',
    color: '#4B4B4B',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#FFD41F',
      color: '#4B4B4B',
    },
    '&:focus': {
      backgroundColor: '#FFD41F',
      color: '#4B4B4B',
    },
  },
  function: {
    display: 'flex',
    width: '312px',
    margin: '0 auto',
    height: '124px',
    justifyContent: 'space-between',
  },
  functionBtn: {
    width: '148px',
    height: '100%',
    boxShadow: '0px 0px 6px #00000029',
    borderRadius: '7px',
  },
}));

const AlarmSetting = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PageLayoutPilot
      menu={<MenuPilot />}
      breadcrumbs={['helpFunction']}
    >
      <div className={classes.background} />
      <Button
        className={classes.btn}
        onClick={() => navigate(`${location.pathname}/soundbuzzer`)}
      >
        {t('buzzerSound')}
      </Button>
      <div className={classes.function}>
        <Button
          className={classes.functionBtn}
        >
          {t('showToOthers')}
        </Button>
        <Button
          className={classes.functionBtn}
        >
          {t('inform')}
        </Button>
      </div>
    </PageLayoutPilot>
  );
};

export default AlarmSetting;
