import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'accountsChangePassword',
  initialState: {
    accountsChangePassword: [],
  },
  reducers: {
    push(state, action) {
      state.accountsChangePassword = action.payload;
    },
    reset(state) {
      state.accountsChangePassword = [];
    },
  },
});

export { actions as accountsChangePasswordActions };
export { reducer as accountsChangePasswordReducer };
export const selectAccountsChangePassword = (state) => state.accountsChangePassword.accountsChangePassword;
