import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button, Dialog, Rating, Paper,
} from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import { useTranslation } from './LocalizationProvider';
import AccuracyImage from '../../resources/images/iconXD/AccuracyImage.svg';
import { useEffectAsync } from '../../reactHelper';

const geolib = require('geolib');

const useStyles = makeStyles({
  box: {
    width: '317px',
    height: '469px',
    padding: '24px',
    color: '#6C6A6A',
    border: '1px solid #00000000',
    borderRadius: '14px',
    boxShadow: '0px 2px 15px #61728B42',
    opacity: 1,
    overflow: 'hidden',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '11px',
  },
  imageContent: {
    // backgroundImage: url('${image}'),
    width: '100%',
    height: '130px',
    backgroundSize: 'cover',
    margin: '21px 0 36px 0',
  },
  boxContent: {
    marginBottom: '11px',
    height: '99px',
  },
  btn: {
    width: '100%',
    height: '47px',
    backgroundColor: '#009380',
    borderRadius: '24px',
    color: 'white',
    opacity: 1,
  },
  text1: {
    fontSize: '15px',
  },
  text2: {
    fontSize: '12px',
  },
  iconOfButton: {
    height: '18px',
    width: '18px',
    color: '#6c6a6a',
  },
  accuracy: {
    height: '17px',
    fontSize: '12px',
    paddingLeft: '0px',
    color: '#6c6a6a',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '110px',
  },
  rating: {
    fontSize: '15px',
    color: '#6c6a6a',
    paddingLeft: '10px',
  },
});

const AccuracyPopup = ({ deviceId, positions }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [accuracy, setAccuracy] = useState(1);
  const [dataAcc, setDataAcc] = useState({});

  const handleOpen = (e) => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    setOpenDialog(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    setOpenDialog(false);
  };

  // function set value for component rating
  const updateAccuracy = (distance, isInside) => {
    if (isInside === true) {
      setAccuracy(3);
    } else if (distance >= 10 && distance <= 100) {
      setAccuracy(2);
    } else {
      setAccuracy(1);
    }
  };

  // Calculate accuracy function
  useEffectAsync(async () => {
    const response = await fetch(`/api/geofences?deviceId=${deviceId}`);
    if (response.ok) {
      const data = await response.json();
      setDataAcc(data[0] || {});
    }
  }, []);

  useEffect(() => {
    if (Object.keys(dataAcc).length !== 0 && Object.keys(positions).length !== 0) {
      const areaValue = dataAcc.area.substring(8, dataAcc.area.length - 1).split(' ');
      const isInside = geolib.isPointWithinRadius(
        { latitude: positions.latitude, longitude: positions.longitude },
        { latitude: areaValue[0], longitude: areaValue[1].substring(0, areaValue[1].length - 1) },
        areaValue[2],
      );
      const distance = geolib.getDistance(
        { latitude: positions.latitude, longitude: positions.longitude },
        { latitude: areaValue[0], longitude: areaValue[1].substring(0, areaValue[1].length - 1) },
      );
      updateAccuracy(distance || 0, isInside);
    }
  }, [positions, deviceId, dataAcc]);

  return (
    <div style={{ display: 'flex' }}>
      <Button className={classes.accuracy} onClick={(e) => handleOpen(e)}>
        <Typography className={classes.accuracy}>
          {t('accuracy')}
        </Typography>
        <Rating name="read-only" value={accuracy} max={3} readOnly className={classes.rating} />
        <ReportIcon className={classes.iconOfButton} />
      </Button>
      <div className={classes.dialog}>
        <Dialog
          open={openDialog}
          PaperProps={{ style: { borderRadius: 14 } }}
        >
          <Paper className={classes.box} sx={{ borderRadius: '14px' }}>
            <div className={classes.title}>{t('markMean')}</div>
            <div className={classes.text1}>
              {t('indicateInfor')}
            </div>
            <img src={AccuracyImage} alt="ab" className={classes.imageContent} />
            <div className={classes.boxContent}>
              <div className={classes.text2}>
                {t('100m')}
              </div>
              <div className={classes.text2}>
                {t('10mto100m')}
              </div>
              <div className={classes.text2}>
                {t('10m')}
              </div>
            </div>
            <Button
              className={classes.btn}
              onClick={(e) => handleClose(e)}
              sx={{ '&:hover': { backgroundColor: '#009380' } }}
            >
              {t('close')}
            </Button>
          </Paper>
        </Dialog>
      </div>
    </div>
  );
};

export default AccuracyPopup;
