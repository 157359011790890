import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Button, TextField, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Header from '../Header';
import { helperActions } from '../../../../store';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import NewTable from '../../../components/NewTable';
import { getUserIds, getUserData } from '../../../common/utils';

const useStyle = makeStyles((theme) => ({
  configTable: {
    height: '600px',
    width: '100%',
  },
  confirmButton: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const SendNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyle();
  const t = useTranslation();

  const checkedList = useSelector((state) => state.helper.checkedList);
  const notification = useSelector((state) => state.helper.notification);

  const [title, setTitle] = useState(notification.title || '');
  const [content, setContent] = useState(notification.mainContent || '');

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    const data = getUserData(checkedList, dataSelect);
    dispatch(helperActions.selectedUser(data));
  }, [checkedList, dataSelect]);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    listChecked: userIds,
    getGridApi,
    saveData: true,
    removeColumn: [1],
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
    childrenTop: (
      <>
        <Header>
          <div>{t('notificationContent')}</div>
        </Header>
        <Box>
          <Typography style={{ fontSize: '26px' }}>{t('title')}</Typography>
          <TextField style={{ marginBottom: '1rem', minWidth: '300px' }} onChange={(e) => setTitle(e.target.value)} value={title} />
          <Typography style={{ fontSize: '26px' }}>{t('content')}</Typography>
          <TextField style={{ width: '100%', marginBottom: '3rem' }} multiline rows={7} onChange={(e) => setContent(e.target.value)} value={content} />
        </Box>
        <Typography style={{ fontSize: '26px', marginBottom: '20px' }}>{t('mailRecipient')}</Typography>
      </>
    ),
  };

  return (
    <>
      <NewTable {...dataTable} />
      <div className={classes.confirmButton}>
        <Button
          style={{
            backgroundColor: '#ff0505',
            border: 'outset 5px #ff0505',
            margin: '1rem 0',
            color: 'white',
            opacity: !(dataSelect.length > 0 && title.length > 0 && content.length > 0) ? 0.3 : 1,
          }}
          disabled={!(dataSelect.length > 0 && title.length > 0 && content.length > 0)}
          onClick={() => {
            navigate('confirm');
            dispatch(helperActions.setNotification({
              title,
              mainContent: content,
              userIds,
            }));
          }}
        >
          {t('confirmContent')}
        </Button>
      </div>
    </>
  );
};

export default SendNotifications;
