/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  MenuItem, IconButton, Typography, Divider, Button, Grid,
} from '@mui/material';
import moment from 'moment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { SelectCustom } from './CustomComponent';
import { renderTimeOptions, parserTime } from '../../../common/utils';

const useStyles = makeStyles((theme) => ({
  timeForm: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    padding: '10px 0',
  },
  separateForm: {
    display: 'flex',
    padding: '3px',
    alignItems: 'center',
  },
  addBtn: {
    textAlign: 'end',
  },
  checkBox: {
    color: '#ea8440',
    '&.Mui-checked': {
      color: '#ea8440',
    },
  },
  reportBack: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  toggleBtn: {
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    fontSize: '12px',
    color: '#fff',
    margin: '0 5px',
  },
  dayBtnContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: '20px auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  deleteBtn: {
    flexGrow: '1',
    textAlign: 'end',
    marginTop: '10px',
  },
  snooze: {
    margin: '10px 0',
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
}));

const HealthConfirmConstant = {
  MON_CHECKBOX: 'MON_CHECKBOX',
  TUE_CHECKBOX: 'TUE_CHECKBOX',
  WED_CHECKBOX: 'WED_CHECKBOX',
  THU_CHECKBOX: 'THU_CHECKBOX',
  FRI_CHECKBOX: 'FRI_CHECKBOX',
  SAT_CHECKBOX: 'SAT_CHECKBOX',
  SUN_CHECKBOX: 'SUN_CHECKBOX',
  HOUR_START_SELECT: 'HOUR_START_SELECT',
  MIN_START_SELECT: 'MIN_START_SELECT',
  HOUR_END_SELECT: 'HOUR_END_SELECT',
  MIN_END_SELECT: 'MIN_END_SELECT',
  SNOOZE_TIME: 'SNOOZE_TIME',
  NUMBER_OF_RESENDS: 'NUMBER_OF_RESENDS',
  DELETE: 'DELETE',
};

const initValue = {
  days: [],
  time: '00:00',
  time_end: '00:00',
  snooze_time: 1,
  number_of_resends: 1,
};

const initToggleDays = {
  SUN: false,
  MON: false,
  TUE: false,
  WED: false,
  THU: false,
  FRI: false,
  SAT: false,
};

const HealthConfirm = ({ data, setData, id, callback: handleDelete }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [healthConfirmData, setHealthConfirmData] = useState(initValue);
  const [isToggled, setIsToggled] = useState(initToggleDays);
  const [numberOfResends, setNumberOfResends] = useState(1);

  const menuTime = useCallback((number, name, first, last) => {
    const array = [];
    for (let index = 1; index <= number; index += 1) {
      array.push(index);
    }

    return array.map((value, i) => <MenuItem divider value={value} key={name + i}>{`${first || ''} ${value} ${last || ''}`}</MenuItem>);
  }, []);

  const handleChangeFieldHealCheck = (type, value, day) => {
    // console.log('hanleChangeFieldHealCheck', type, value);
    const cloneValue = { ...healthConfirmData };
    const cloneDays = [...cloneValue.days];
    const cloneData = [...data];

    setIsToggled((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));

    switch (type) {
      case HealthConfirmConstant.SUN_CHECKBOX:
        if (isToggled.SUN === true) {
          const index = cloneDays.indexOf('1');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setHealthConfirmData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('1') && !isToggled.SUN) {
          cloneDays.push('1');
          setHealthConfirmData({ ...cloneValue, days: cloneDays });
        }
        break;
      case HealthConfirmConstant.MON_CHECKBOX:
        if (isToggled.MON === true) {
          const index = cloneDays.indexOf('2');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setHealthConfirmData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('2') && !isToggled.MON) {
          cloneDays.push('2');
          setHealthConfirmData({ ...cloneValue, days: cloneDays });
        }
        break;
      case HealthConfirmConstant.TUE_CHECKBOX:
        if (isToggled.TUE === true) {
          const index = cloneDays.indexOf('3');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setHealthConfirmData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('3') && !isToggled.TUE) {
          cloneDays.push('3');
          setHealthConfirmData({ ...cloneValue, days: cloneDays });
        }
        break;
      case HealthConfirmConstant.WED_CHECKBOX:
        if (isToggled.WED === true) {
          const index = cloneDays.indexOf('4');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setHealthConfirmData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('4') && !isToggled.WED) {
          cloneDays.push('4');
          setHealthConfirmData({ ...cloneValue, days: cloneDays });
        }
        break;
      case HealthConfirmConstant.THU_CHECKBOX:
        if (isToggled.THU === true) {
          const index = cloneDays.indexOf('5');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setHealthConfirmData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('5') && !isToggled.THU) {
          cloneDays.push('5');
          setHealthConfirmData({ ...cloneValue, days: cloneDays });
        }
        break;
      case HealthConfirmConstant.FRI_CHECKBOX:
        if (isToggled.FRI === true) {
          const index = cloneDays.indexOf('6');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setHealthConfirmData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('6') && !isToggled.FRI) {
          cloneDays.push('6');
          setHealthConfirmData({ ...cloneValue, days: cloneDays });
        }
        break;
      case HealthConfirmConstant.SAT_CHECKBOX:
        if (isToggled.SAT === true) {
          const index = cloneDays.indexOf('0');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setHealthConfirmData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('0') && !isToggled.SAT) {
          cloneDays.push('0');
          setHealthConfirmData({ ...cloneValue, days: cloneDays });
        }
        break;
      case HealthConfirmConstant.HOUR_START_SELECT: {
        const newHours = value;
        const currentTime = moment(cloneValue.time, 'HH:mm');
        const updatedTime = currentTime.hours(newHours).format('HH:mm');
        setHealthConfirmData({ ...cloneValue, time: updatedTime });
        break;
      }
      case HealthConfirmConstant.MIN_START_SELECT: {
        const newMinutes = value;
        const currentTime = moment(cloneValue.time, 'HH:mm');
        const updatedTime = currentTime.minutes(newMinutes).format('HH:mm');
        setHealthConfirmData({ ...cloneValue, time: updatedTime });
        break;
      }
      case HealthConfirmConstant.HOUR_END_SELECT: {
        const newHours = value;
        const currentTime = moment(cloneValue.time_end, 'HH:mm');
        const updatedTime = currentTime.hours(newHours).format('HH:mm');
        setHealthConfirmData({ ...cloneValue, time_end: updatedTime });
        break;
      }
      case HealthConfirmConstant.MIN_END_SELECT: {
        const newMinutes = value;
        const currentTime = moment(cloneValue.time_end, 'HH:mm');
        const updatedTime = currentTime.minutes(newMinutes).format('HH:mm');
        setHealthConfirmData({ ...cloneValue, time_end: updatedTime });
        break;
      }
      case HealthConfirmConstant.SNOOZE_TIME: {
        const snoozeTime = parseInt(value);
        if (snoozeTime === 1) {
          setNumberOfResends(1);
          setHealthConfirmData({ ...cloneValue, snooze_time: 1, number_of_resends: 1 });
        } else {
          setNumberOfResends(3);
          setHealthConfirmData({ ...cloneValue, snooze_time: snoozeTime });
        }
        break;
      }
      case HealthConfirmConstant.NUMBER_OF_RESENDS: {
        const numberOfResends = parseInt(value);
        setHealthConfirmData({ ...cloneValue, number_of_resends: numberOfResends });
        break;
      }
      case HealthConfirmConstant.DELETE: {
        if (cloneData.length === 1) {
          setHealthConfirmData(initValue);
          setIsToggled(initToggleDays);
        } else {
          handleDelete(id, 'healthConfirm');
        }
        break;
      }
      default:
    }
  };

  useEffect(() => {
    const cloneMasterData = data;
    const newMasterData = cloneMasterData.map((masterData) => {
      if (masterData.id === id) {
        return { ...healthConfirmData, id };
      }
      return masterData;
    });
    setData(newMasterData);
  }, [healthConfirmData]);

  const addHealthConfirmComponent = useCallback(() => [initValue].map((item) => (
    <div key={id} style={{ margin: '0px 10px' }}>
      <Typography>{t('selectDays')}</Typography>
      <div className={classes.dayBtnContainer}>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.SUN ? {
            backgroundColor: '#0EAA92',
            '&:hover': {
              backgroundColor: '#0EAA92',
            },
          } : {
            backgroundColor: '#BCBCBC',
            '&:hover': {
              backgroundColor: '#BCBCBC',
            },
          }}
          onClick={() => {
            handleChangeFieldHealCheck(HealthConfirmConstant.SUN_CHECKBOX, '1', 'SUN');
          }}
        >
          {t('Sun')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.MON ? {
            backgroundColor: '#0EAA92',
            '&:hover': {
              backgroundColor: '#0EAA92',
            },
          } : {
            backgroundColor: '#BCBCBC',
            '&:hover': {
              backgroundColor: '#BCBCBC',
            },
          }}
          onClick={() => {
            handleChangeFieldHealCheck(HealthConfirmConstant.MON_CHECKBOX, '2', 'MON');
          }}
        >
          {t('Mon')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.TUE ? {
            backgroundColor: '#0EAA92',
            '&:hover': {
              backgroundColor: '#0EAA92',
            },
          } : {
            backgroundColor: '#BCBCBC',
            '&:hover': {
              backgroundColor: '#BCBCBC',
            },
          }}
          onClick={() => {
            handleChangeFieldHealCheck(HealthConfirmConstant.TUE_CHECKBOX, '3', 'TUE');
          }}
        >
          {t('Tue')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.WED ? {
            backgroundColor: '#0EAA92',
            '&:hover': {
              backgroundColor: '#0EAA92',
            },
          } : {
            backgroundColor: '#BCBCBC',
            '&:hover': {
              backgroundColor: '#BCBCBC',
            },
          }}
          onClick={() => {
            handleChangeFieldHealCheck(HealthConfirmConstant.WED_CHECKBOX, '4', 'WED');
          }}
        >
          {t('Wed')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.THU ? {
            backgroundColor: '#0EAA92',
            '&:hover': {
              backgroundColor: '#0EAA92',
            },
          } : {
            backgroundColor: '#BCBCBC',
            '&:hover': {
              backgroundColor: '#BCBCBC',
            },
          }}
          onClick={() => {
            handleChangeFieldHealCheck(HealthConfirmConstant.THU_CHECKBOX, '5', 'THU');
          }}
        >
          {t('Thu')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.FRI ? {
            backgroundColor: '#0EAA92',
            '&:hover': {
              backgroundColor: '#0EAA92',
            },
          } : {
            backgroundColor: '#BCBCBC',
            '&:hover': {
              backgroundColor: '#BCBCBC',
            },
          }}
          onClick={() => {
            handleChangeFieldHealCheck(HealthConfirmConstant.FRI_CHECKBOX, '6', 'FRI');
          }}
        >
          {t('Fri')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.SAT ? {
            backgroundColor: '#0EAA92',
            '&:hover': {
              backgroundColor: '#0EAA92',
            },
          } : {
            backgroundColor: '#BCBCBC',
            '&:hover': {
              backgroundColor: '#BCBCBC',
            },
          }}
          onClick={() => {
            handleChangeFieldHealCheck(HealthConfirmConstant.SAT_CHECKBOX, '0', 'SAT');
          }}
        >
          {t('Sat')}
        </IconButton>
      </div>
      <div>
        <Divider />
        <Grid container className={classes.timeForm}>
          <Grid item xs={5} sm={4} md={3} xl={2}>
            <Typography>{t('selectTime')}</Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={9} xl={10} sx={{ display: 'flex' }}>
            <SelectCustom
              id="hours-select"
              defaultValue="0"
              value={parserTime(healthConfirmData.time).hours}
              onChange={(e) => {
                handleChangeFieldHealCheck(HealthConfirmConstant.HOUR_START_SELECT, e.target.value);
              }}
            >
              {renderTimeOptions(0, 23)}
            </SelectCustom>
            <span className={classes.separateForm}> : </span>
            <SelectCustom
              id="minite-select"
              defaultValue="0"
              value={parserTime(healthConfirmData.time).minutes}
              onChange={(e) => {
                handleChangeFieldHealCheck(HealthConfirmConstant.MIN_START_SELECT, e.target.value);
              }}
            >
              {renderTimeOptions(0, 59)}
            </SelectCustom>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.snooze}>
          <Grid item xs={5} sm={4} md={3} xl={2}>
            <span style={{ marginRight: '5px' }}>{t('setSnooze')}</span>
          </Grid>
          <Grid item xs={7} sm={8} md={9} xl={10} sx={{ display: 'flex' }}>
            <SelectCustom
              id="select-snooze-time"
              sx={{ width: 130 }}
              defaultValue={1}
              value={healthConfirmData.snooze_time}
              onChange={(e) => {
                handleChangeFieldHealCheck(HealthConfirmConstant.SNOOZE_TIME, e.target.value);
              }}
            >
              <MenuItem value={1} divider>{t('OFF')}</MenuItem>
              <MenuItem value={3} divider>{t('3Minute')}</MenuItem>
              <MenuItem value={5} divider>{t('5Minute')}</MenuItem>
              <MenuItem value={10} divider>{t('10Minute')}</MenuItem>
            </SelectCustom>
          </Grid>
        </Grid>
        <Divider />
        <div className={classes.reportBack}>
          <span>{t('ifYouReportBack')}</span>
          <SelectCustom
            id="select-report-back-time"
            style={{ margin: '0 5px' }}
            defaultValue={1}
            value={healthConfirmData.number_of_resends}
            onChange={(e) => { handleChangeFieldHealCheck(HealthConfirmConstant.NUMBER_OF_RESENDS, e.target.value); }}
          >
            {menuTime(healthConfirmData.snooze_time === 1 ? 1 : numberOfResends, 'minute', '', t('times'))}
          </SelectCustom>
          <span>{t('isAnyReaction')}</span>
        </div>
        <div className={classes.deleteBtn}>
          <Button
            onClick={() => handleChangeFieldHealCheck(HealthConfirmConstant.DELETE)}
          >
            <DeleteOutlineIcon sx={{ color: '#0EAA92' }} />
            <Typography color="#0EAA92">{t('deleteSchedule')}</Typography>
          </Button>
        </div>
      </div>
    </div>
  )), [healthConfirmData, handleDelete]);

  return (
    <div>
      {addHealthConfirmComponent()}
    </div>
  );
};

export default HealthConfirm;
