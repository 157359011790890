import React from 'react';
import Visa from '../resources/VISA.png';
import MasterCard from '../resources/MASTERCARD.png';
import Amex from '../resources/AMEX.png';

const VisaImage = () => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <img src={Visa} alt="visa" style={{ width: 70, height: 40 }} />
    <img src={MasterCard} alt="visa" style={{ width: 70, height: 40 }} />
    <img src={Amex} alt="visa" style={{ width: 70, height: 40 }} />
  </div>

);

export default VisaImage;
