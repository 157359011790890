import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  notification: {
    display: 'flex',
    margin: '11px',
    backgroundColor: 'gray',
    borderRadius: '10px',
    padding: '13px',
  },
  icon: {
    backgroundImage: 'url("https://icons.iconarchive.com/icons/alecive/flatwoken/512/Apps-Pdf-icon.png")',
    width: '39px',
    height: '39px',
    backgroundSize: 'cover',
    marginRight: '8px',
  },
  content: {
    flexGrow: '1',
    margin: 0,
    padding: 0,
  },
  time: {
    margin: 0,
    padding: 0,
  },
}));

const NotiPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Card
      className={classes.notification}
      onClick={() => { navigate(`${location.pathname}/safetyconfirm`); }}
    >
      <CardContent
        className={classes.icon}
      />
      <CardContent
        className={classes.content}
      >
        <Typography>Some notifications ....</Typography>
      </CardContent>
      <CardContent
        className={classes.time}
      >
        <Typography>10:56 AM</Typography>
      </CardContent>
    </Card>
  );
};

export default NotiPage;
