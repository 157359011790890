/* eslint-disable react/no-array-index-key */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Pie } from 'react-chartjs-2';
import {
  Grid, Typography, useMediaQuery,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { makeStyles } from '@mui/styles';
import { useTranslation } from '../../../common/components/LocalizationProvider';

const useStyles = makeStyles({
  grid: {
    border: '1px solid',
    display: 'flex',
    justifyContent: 'center',
  },

});

ChartJS.register(ArcElement, Tooltip, Legend);

const AnalysisPieChart = () => {
  const t = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  const customChart = [
    {
      title: 'analysisMainManager',
      icon: <PersonIcon sx={{ fontSize: isMobile ? 30 : 50, color: '#8faadc' }} />,
      data: {
        labels: [
          'IOS',
          'Android',
        ],
        datasets: [
          {
            label: 'Percent',
            data: [24, 76],
            backgroundColor: [
              '#91ce4f',
              '#a6a6a6',
            ],
            borderColor: [
              '#006400',
              '#6E7B8B',
            ],
            borderWidth: 1,
          },
        ],
      },
    },
    {
      title: 'analysisSubManager',
      icon: <PersonOutlinedIcon sx={{ fontSize: isMobile ? 30 : 50, color: '#8faadc' }} />,
      data: {
        labels: [
          'IOS',
          'Android',
        ],
        datasets: [
          {
            label: '# of Votes',
            data: [31, 69],
            backgroundColor: [
              '#91ce4f',
              '#a6a6a6',
            ],
            borderColor: [
              '#006400',
              '#6E7B8B',
            ],
            borderWidth: 1,
          },
        ],
      },
    },
    {
      title: 'analysisMonitoringObject',
      icon: <LocationOnIcon sx={{ fontSize: isMobile ? 30 : 50, color: '#8faadc' }} />,
      data: {
        labels: [
          'IOS',
          'Android',
        ],
        datasets: [
          {
            label: '# of Votes',
            data: [11, 89],
            backgroundColor: [
              '#91ce4f',
              '#a6a6a6',
            ],
            borderColor: [
              '#006400',
              '#6E7B8B',
            ],
            borderWidth: 1,
          },
        ],
      },
    },
  ];

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: isMobile ? 'bottom' : 'top',
        labels: {
          font: {
            size: isMobile ? 16 : 20,
          },
        },
      },
    // title: {
    //   display: true,
    //   text: 'Title',
    // },
    },
  };

  return (
    <div>
      <div>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 8, sm: 8, md: 12 }}>
          {customChart.map((item, index) => (
            <Grid item xs={4} sm={4} md={4} key={index}>
              <div style={{ display: 'flex', marginTop: '30px', justifyContent: 'center', marginBottom: '5px' }}>
                <span>{item.icon}</span>
                <Typography style={{ fontSize: isMobile ? '16px' : '20px', marginLeft: '10px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>{t(item.title)}</Typography>
              </div>
              <div
                style={{
                  height: '30vh',
                  width: '100%',
                  position: 'relative',
                }}
              >
                <Pie data={item.data} options={options} width={300} height={100} />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <div style={{ marginTop: '30px' }}>
        <Grid container>
          <Grid item xs={3} className={classes.grid}>
            <Typography>{t('analysisMonthOfUse')}</Typography>
          </Grid>
          <Grid item xs={9} className={classes.grid}>
            <Typography>a</Typography>
          </Grid>
          <Grid item xs={3} className={classes.grid}>
            <Typography>{t('analysisContactRate')}</Typography>
          </Grid>
          <Grid item xs={9} className={classes.grid}>
            <Typography>a</Typography>
          </Grid>
        </Grid>
      </div>
    </div>

  );
};

export default AnalysisPieChart;
