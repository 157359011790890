import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from '../../../common/components/LocalizationProvider';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const useStyles = makeStyles((theme) => ({
  chartBox: {
    height: '500px',
    borderRadius: '20px',
    marginTop: '10px',
    // border: '1px solid red',
    [theme.breakpoints.down('md')]: {
      height: '300px',
    },
  },
}));

const AnalysisStackedChart = () => {
  const t = useTranslation();
  const classes = useStyles();

  const options = {
    plugins: {
      title: {
        display: true,
        text: t('analysisChartName'),
        font: {
          size: 24,
        },
      },
      legend: {
        position: 'bottom',
        fontSize: 100,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = [
    t('january'),
    t('february'),
    t('march'),
    t('april'),
    t('may'),
    t('june'),
    t('july'),
    t('august'),
    t('september'),
    t('october'),
    t('november'),
    t('december'),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: t('analysisMainManager'),
        data: labels.map(() => Math.floor(Math.random() * 300)),
        backgroundColor: '#70ad47',
      },
      {
        label: t('analysisSubManager'),
        data: labels.map(() => Math.floor(Math.random() * 500)),
        backgroundColor: '#5b9bd5',
      },
      {
        label: t('analysisMimamori'),
        data: labels.map(() => Math.floor(Math.random() * 2000)),
        backgroundColor: '#ffc000',
      },
    ],
  };

  return (
    <div className={classes.chartBox}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default AnalysisStackedChart;
