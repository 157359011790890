/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState, forwardRef, useRef, useEffect,
} from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const useStyles = makeStyles({
  datePicker: {
    width: '100%',
  },
});

const DatePopupEditor = forwardRef((props, ref) => {
  const classes = useStyles();
  const refDatePicker = useRef();
  const [selectedDate, setSelectedDate] = useState(props?.value && moment(props?.value).isValid() ? new Date(props?.value) : new Date());
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (!editing) {
      props?.stopEditing();
    }
  }, [editing]);

  const onChange = (selectedDate) => {
    setSelectedDate(selectedDate);
    props?.onValueChange(moment(selectedDate).format('YYYY/MM/DD'));
    setEditing(false);
  };

  return (
    <div>
      <DatePicker
        ref={refDatePicker}
        portalId="root"
        popperClassName="ag-custom-component-popup"
        onChange={onChange}
        selected={selectedDate}
        className={classes.datePicker}
        dateFormat="yyyyMMdd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
});

export default DatePopupEditor;
