import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'clientAlarms',
  initialState: {
    active: false,
  },
  reducers: {
    on(state) {
      state.active = true;
    },
    off(state) {
      state.active = false;
    },
  },
});

export { actions as clientAlarmsActions };
export { reducer as clientAlarmsReducer };
