/* eslint-disable no-plusplus */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import makeStyles from '@mui/styles/makeStyles';
import { map } from './core/MapView';
import Good from '../resources/images/iconXD/SmileIcon.svg';
import Bad from '../resources/images/iconXD/DeathIcon.svg';

const useStyle = makeStyles({
  test: {
    '& .maplibregl-popup-content': {
      backgroundColor: 'green',
    },
  },
});
const MapPopUp = () => {
  const positions = useSelector((state) => state.session.positions);
  const devices = useSelector((state) => state.devices.items);
  const classes = useStyle();

  const [popUp, setPopUp] = useState([]);
  // eslint-disable-next-line no-undef
  const elements = document.getElementsByClassName('maplibregl-popup');
  const arrayDevices = Object.values(devices);
  const arrayPositions = Object.values(positions);
  useEffect(() => {
    for (let i = 0; i < elements.length; i++) {
      elements[i].remove();
    }
    setPopUp([]);
    let arr = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in, no-unused-vars
    for (const device in devices) {
      const popup = new maplibregl.Popup({ anchor: 'bottom' })
        .addTo(map);
      arr = arr.concat(popup);
    }
    setPopUp(arr);
  }, [arrayDevices.length]);

  useEffect(() => {
    const listDevicePopUp = arrayDevices.filter((it) => it.userStatus === 'normal');

    let a = 0;
    arrayPositions.filter((it) => listDevicePopUp.find((e) => {
      const cond = e.id === it.deviceId;
      if (cond && popUp.length !== 0) {
        // eslint-disable-next-line no-unused-expressions
        popUp[a].setLngLat([it.longitude, it.latitude])
          .setHTML(`<div style="width:40px; height:40px;background-image:url(${it.userStatus === 'normal' ? Good : Bad}) "></div>`)
          .addClassName(classes.test);
        a += 1;
      }

      return cond;
    }));
  }, [positions, popUp]);

  return null;
};

export default MapPopUp;
