/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Typography, Button, useMediaQuery, Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';
import LogoImage from '../../login/LogoImage';
import TremealLogo from '../../resources/images/TremealLogo.svg';
import TremealBanner from '../../resources/images/Tremeal2.png';

const useStyles = makeStyles({
  logo: {
    display: 'flex', justifyContent: 'center', paddingTop: '20px',
  },
  container: {
    display: 'flex', justifyContent: 'center', padding: '20px 0',
  },
  containerLinkLogo: {
    padding: '10px', display: 'flex', alignItems: 'flex-end',
  },
  link: {
    padding: '0px 20px', display: 'flex', flexDirection: 'column',
  },
  storeMobile: {
    margin: '0 20px',
    width: '350px',
    height: '50px',
    borderRadius: '30px',
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  storeDesktop: {
    margin: '0 20px',
    width: '300px',
    height: '50px',
    borderRadius: '30px',
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  linkDesktop: {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 'bold',
  },
  linkMobile: {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: 'bold',
    fontSize: '10px',
  },
  treMobile: {
    height: '20px',
  },
  logoMobile: {
    height: '30px',
  },
});

const link = [
  'お役立ちコラム',
  'お問い合わせ',
  '運営会社',
  '利用規約',
  'プライバシーポリシー',
  '特商法取引法に基づく表記',
];
const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div style={{ backgroundColor: 'grey', height: 'fit-content', position: 'relative', bottom: '0px', width: '100%' }}>
      <div className={classes.logo}>
        <LogoImage color={theme.palette.primary.main} />
      </div>
      <div className={classes.container}>
        <Button
          variant="contained"
          className={mobile ? classes.storeMobile : classes.storeDesktop}
          onClick={() => { console.log('clicked'); }}
        >
          <AppleIcon sx={{ marginRight: '10px' }} />
          <Typography fontSize={mobile && '10px'} fontWeight="bold">App Store</Typography>
        </Button>
        <Button
          variant="contained"
          className={mobile ? classes.storeMobile : classes.storeDesktop}
          onClick={() => { console.log('clicked'); }}
        >
          <ShopIcon sx={{ marginRight: '10px' }} />
          <Typography fontSize={mobile && '10px'} fontWeight="bold">Google Play</Typography>
        </Button>
      </div>
      <div className={classes.containerLinkLogo}>
        <div className={classes.link}>
          {link.map((item, index) => (
            <Link
              key={index}
              to="https://google.com"
              className={mobile ? classes.linkMobile : classes.linkDesktop}
            >
              {item}
            </Link>
          ))}
        </div>
        <div style={{ paddingRight: '20px' }}>
          <img
            src={TremealLogo}
            alt="cc"
            className={mobile && classes.treMobile}
          />
        </div>
        <div style={{ paddingRight: '20px' }}>
          <img src={TremealBanner} alt="cc" className={mobile && classes.logoMobile} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
