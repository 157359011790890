import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { useTranslation } from '../common/components/LocalizationProvider';
import WarningLogo from '../resources/images/iconXD/Warning.svg';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    padding: '10px 20px',
    width: '300px',
    height: '130px',
    margin: '0 auto',
    borderRadius: '7px',
    outline: 'none',
    transition: 'all 0.3s',
    cursor: 'pointer',
    backgroundColor: '#BCBCBC',
    color: '#808080',
    '&.active': {
      transform: 'translateY(-5px)',
      // backgroundColor: '#FFD41F',
      backgroundColor: 'red',
      boxShadow: '0px 0px 6px #00000029',
    },
  },
  button: {
    padding: '10px 20px',
    width: '300px',
    height: '130px',
    margin: '0 auto',
    transition: 'all 0.5s',
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    color: '#fff',
    backgroundColor: '#FFD41F',
    border: 'none',
    borderRadius: '15px',
    boxShadow: '0 9px #E6C01C',
    '&:active': {
      backgroundColor: 'red',
      boxShadow: '0 5px #E6C01C',
      transform: 'translateY(4px)',
    },
    '&:hover': {
      backgroundColor: '#FFD41F',
    },
  },
  text: {
    padding: '5px',
    border: '1px solid white',
    borderRadius: '10px',
  },
  btnIcon: {
    height: '43px',
    width: '50px',
  },
  status: {
    border: '1px solid #808080',
    backgroundColor: '#4B4B4B',
    borderRadius: '7px',
    fontSize: '12px',
  },
  buttonTitle: {
    fontSize: '16px',
    color: '#4B4B4B',
    display: 'flex',
    fontWeight: 'bold',
  },
  buttonText: {
    fontSize: '12px',
    color: '#4B4B4B',
    display: 'flex',
  },
});

const DisasterButton = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();

  const [active, setActive] = useState(false);

  const requestCheckUserStatus = async () => {
    const item = {
      always: true,
      attributes: { alarms: 'general,sos' },
      calendarId: 0,
      id: 0,
      notificators: 'web,firebase',
      type: 'help',
    };

    const data = {
      deviceIds: [item.id],
      notificators: 'web,firebase',
      type: 'alarm',
    };

    const response = await fetch('/api/notifications/req-user-status', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      setActive(true);
      console.error(await response.text());
    } else {
      item.userStatus = data.userStatus;
      // setActive(!active);
      navigate('/clientalarm');
    }
    // }));
  };
  const handleClick = () => {
    setActive(!active);
    requestCheckUserStatus();
    /*
    setTimeout(() => {
      // navigate('/clientalarm');
    }, 2000);
    */
  };

  return (
    <Button
      className={`${classes.button} ${active ? 'active' : ''}`}
      onClick={handleClick}
      size="large"
    >
      <Grid container spacing={2}>
        <Grid item xs={3} className={classes.container}>
          <img src={WarningLogo} alt="warning" className={classes.btnIcon} />
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.status}>
            {t('buttonEmergency1')}
            {' '}
            {active ? 'OFF' : 'ON'}
            {' '}
            {t('buttonEmergency2')}
          </Typography>
          <div style={{ width: '170px' }}>
            <Typography align="left" className={classes.buttonTitle}>{t('disasterOccurred')}</Typography>
            <Typography align="left" className={classes.buttonText}>{t('One-touch')}</Typography>
          </div>
        </Grid>
      </Grid>
    </Button>
  );
};

export default DisasterButton;
