import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    margin: 'auto auto',
    flexDirection: 'column',
    position: 'absolute',
    left: '50%',
    top: '40%',
    transform: 'translate(-50%, -50%)',
  },
  btn: {
    backgroundColor: '#888888',
    border: 'outset 5px #939393',
    margin: '0 auto',
    color: 'white',
    width: '150px',
    '&:hover': {
      backgroundColor: '#888888',
    },
  },
  font: {
    fontWeight: 'bold',
    fontSize: '40px',
    color: '#222a35',
    marginBottom: '20px',
  },
});

const FinishPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const pathname = useSelector((state) => state.helper.pathname);

  const handleNavigate = () => {
    if (pathname.includes('/admin/tools')) {
      navigate('/admin/tools');
    } else {
      navigate('/admin/home/list');
    }
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.font}>{t('finish')}</Typography>
      <Button
        className={classes.btn}
        onClick={handleNavigate}
      >
        <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>{t('close')}</Typography>
      </Button>
    </div>
  );
};

export default FinishPage;
