import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'deviceType',
  initialState: {
    type: null,
  },
  reducers: {
    set(state, action) {
      state.type = action.payload;
    },
  },
});

export { actions as deviceTypeActions };
export { reducer as deviceTypeReducer };
