import React, { useState } from 'react';
import {
  Button, Modal, Box, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../../../common/components/LocalizationProvider';
import '../../../styles/components/ConfirmationPopup.css';

const useStyles = makeStyles({
  closeBtn: {
    width: '120px',
    border: 'outset 5px grey',
    backgroundColor: 'grey',
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
  submitBtn: {
    width: '120px',
    border: 'outset 5px red',
    backgroundColor: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  continueBtn: {
    width: '120px',
    border: 'outset 5px #4b7bd0',
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 716,
  height: 380,
  borderRadius: '10px',
  color: '#FFFFFF',
};

const ComfirmationPopup = (props) => {
  const {
    btnTitle, submit, disabled, styleButton, btnIcon, confirmText, width, subConfirmText, btnColor, backGroundColor, handleOption,
  } = props;
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpenModal(true);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleOnclick = () => {
    if (!handleOption) {
      handleOpen();
      submit();
    } else {
      handleOption();
    }
  };

  return (
    <div>
      <Button
        sx={{
          width: width || '125px',
          display: 'flex',
          fontSize: '16px',
          borderRadius: '24px',
          height: '48px',
          backgroundColor: btnColor,
          '&:hover': { backgroundColor: btnColor },
          '&:disabled': { backgroundColor: '#C0C0C0' },
          ...styleButton,
        }}
        variant="contained"
        onClick={handleOnclick}
        disabled={disabled}
      >
        <span style={{ marginBottom: '5px' }}>{btnTitle}</span>
        {btnIcon && (
          <span style={{ margin: ' 0px 0px 5px 10px' }}>
            {btnIcon}
          </span>
        )}
      </Button>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, backgroundColor: backGroundColor || '#333333' }}>
          <div className="container-btn-close">
            <Button className="btn-close" startIcon={<CloseIcon fontSize="large" />} onClick={handleClose} />
          </div>
          <div className="modal-content">
            <DoneOutlineIcon className="success-icon" />
            <Typography className={classes.modalTitle}>{confirmText}</Typography>
            <br />
            <Typography className={classes.modalTitle}>{subConfirmText}</Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ComfirmationPopup;
