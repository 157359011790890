import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import moment from 'moment';
import { useTranslation } from './LocalizationProvider';
import RemoveDialog from './RemoveDialog';
import PositionValue from './PositionValue';
import { useDeviceReadonly } from '../util/permissions';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { devicesActions } from '../../store';
import { useCatch, useCatchCallback } from '../../reactHelper';
import { useAttributePreference } from '../util/preferences';
import { map } from '../../map/core/MapView';

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: 'auto',
    width: theme.dimensions.popupMaxWidth,
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
      height: '250px',
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 1),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-between',
  },
  coordinates: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  avatar: {
    width: '62px',
    height: '62px',
    margin: '21px 0',
    border: '5px solid white',
  },
  root: ({ desktopPadding }) => ({
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: 5,
    // left: '50%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  }),
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell} sx={{ width: 150 }}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCardLocationSharing = ({ deviceId, selectedMember, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();
  // console.log(position, deviceId);
  // const device = useSelector((state) => state.devices.items[deviceId]);

  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const positionAttributes = {
    acc: { name: t('positionAccuracy') },
    bat: { name: t('positionBatteryLevel') },
    lat: { name: t('positionLatitude') },
    lng: { name: t('positionLongitude') },
    spd: { name: t('positionSpeed') },
  };

  // mockdata
  //

  // const positionAttributes = usePositionAttributes(t);
  const positionItems = useAttributePreference('positionItems', 'spd,acc,bat');

  const [anchorEl, setAnchorEl] = useState(null);
  console.log('selec: ', selectedMember);

  return (
    <>
      <div className={classes.root}>
        {/* {device && ( */}
        <Draggable
          handle={`.${classes.media}, .${classes.header}`}
        >
          <Card elevation={3} className={classes.card}>
            <div className={classes.header}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {desktop && <LocationOnOutlinedIcon fontSize="large" />}
                <Typography sx={desktop ? { fontSize: 24, fontWeight: 'bold' } : { fontSize: 24, fontWeight: 'bold', marginLeft: '10px' }} variant="h3">
                  {selectedMember.name}
                </Typography>
              </div>
              <div>
                <IconButton
                  size="small"
                  onClick={onClose}
                  onTouchStart={onClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
            {position ? (
              <CardContent className={classes.content}>
                <div style={{ display: 'flex' }}>
                  <Typography>
                    {t('statusDate')}
                    :
                  </Typography>
                  <Typography style={{ fontWeight: 'bold' }}>
                      &nbsp;
                    <span>{moment().format('DD/MM/YYYY')}</span>
                    ,&nbsp;&nbsp;
                    {position.time}
                  </Typography>
                </div>
                <div className={classes.coordinates}>
                  <Typography style={{ marginRight: '20px' }}>
                    {t('positionLatitude')}
                    :&nbsp;
                    {position.lat}
                  </Typography>
                  <Typography>
                    {t('positionLongitude')}
                    :&nbsp;
                    {position.lng}
                  </Typography>
                </div>
                <Divider style={{ margin: '5px 0' }} />
                <Table size="small" classes={{ root: classes.table }}>
                  <TableBody>
                    {positionItems.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                      <StatusRow
                        key={key}
                        name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                        content={(
                          <PositionValue
                            position={position}
                            property={position.hasOwnProperty(key) ? key : null}
                            attribute={position.hasOwnProperty(key) ? null : key}
                            isAdmin
                          />
                          )}
                      />
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            ) : (<div style={{ display: 'flex', justifyContent: 'center' }}>{t('sharedNoData')}</div>)}
          </Card>
        </Draggable>
        {/* )} */}
      </div>
      {/* {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}>{t('linkStreetView')}</MenuItem>
        </Menu>
      )} */}
    </>
  );
};

export default StatusCardLocationSharing;
