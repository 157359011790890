// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import {
  getAuth, GoogleAuthProvider, signOut,
} from 'firebase/auth';
import configFirebaseDev from './firebase-dev.json';
import configFirebaseStg from './firebase-stg.json';
import configFirebaseProd from './firebase-prod.json';

/* Fix me
    To VanNA: Cần fill thông tin firebase của môi trường prod
*/

const activeEnv = process.env.REACT_APP_ENV;

let firebaseConfig;

if (activeEnv === 'prod') {
  firebaseConfig = configFirebaseProd;
} else if (activeEnv === 'stg') {
  firebaseConfig = configFirebaseStg;
} else {
  firebaseConfig = configFirebaseDev;
}

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize firebase app.
const app = initializeApp(firebaseConfig);

// Initialize firebase database and get the reference of firebase database object.
const database = getDatabase(app);

const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export {
  auth, provider, database,
};
