import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import PageLayoutPilot from '../common/components/PageLayoutPilot';
import MenuPilot from '../common/components/MenuPilot';
import turnOff from '../resources/images/turnOff.png';
import turnOn from '../resources/images/turnOn.png';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  background: {
    width: '100%',
    height: '332px',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  content: {
    margin: '110px auto 38.5px auto',
    fontSize: '30px',
    color: '#6C6A6A',
  },
  btn: {
    width: '317px',
    height: '44px',
    borderRadius: '10px',
    margin: '0 auto',
    backgroundColor: '#4B4B4B',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4B4B4B',
      color: 'white',
    },
    '&:focus': {
      backgroundColor: '#4B4B4B',
      color: 'white',
    },
  },
}));

const AlarmPage = () => {
  const t = useTranslation();
  const classes = useStyles();

  const [turnOnCon, setTurnOnCon] = useState(false);

  return (
    <PageLayoutPilot
      menu={<MenuPilot />}
      breadcrumbs={['helpFunction']}
      location="/help"
    >
      <div
        className={classes.background}
        style={{ backgroundImage: `url('${turnOnCon ? turnOn : turnOff}')` }}
      />
      <div
        className={classes.content}
      >
        {turnOnCon ? t('securityBuzzerOff') : t('securityBuzzerOn')}
      </div>
      <Button
        className={classes.btn}
        onClick={() => {
          setTurnOnCon(!turnOnCon);
        }}
      >
        {turnOnCon ? t('stop') : t('securityBuzzerOn')}
      </Button>
    </PageLayoutPilot>
  );
};

export default AlarmPage;
