import React from 'react';
import {
  Card, Divider, Grid, CardActionArea, Typography, useTheme,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from '../../common/components/LocalizationProvider';
import areaSetting from '../../resources/images/areaSetting.png';
import setting from '../../resources/images/setting.png';
import lock from '../../resources/images/lock.png';
import group from '../../resources/images/group.png';
import calendar from '../../resources/images/calendar.png';
import paint from '../../resources/images/paint.png';

import '../styles/pages/Tools.css';
import BreadcrumbsCustom from '../components/BreadcrumbsCustom';

const url = '/admin/tools';
const menuNavigate = [
  {
    title: 'linkListCreateArea',
    description: 'DescriptionCreateArea',
    link: `${url}/setupArea`,
    icon: areaSetting,
    subDescription: 'SubDescriptionCreateArea',
  },
  // {
  //   title: 'linkListReset',
  //   description: 'DescriptionReset',
  //   link: `${url}/reset`,
  // },
  {
    title: 'linkListSettingAccount',
    description: 'DescriptionMonitorTarget2',
    link: `${url}/accountSetting`,
    icon: setting,
    subDescription: 'SubDescriptionMonitorTarget2',
  },
  // {
  //   title: 'linkListGroupSettings',
  //   description: 'DescriptionGroupSettings',
  //   link: `${url}/groupSettings`,
  // },
  {
    title: 'linkListChangePassword',
    description: 'DescriptionChangePassword',
    link: `${url}/changepassword`,
    icon: lock,
    subDescription: '',
  },
  {
    title: 'linkListMovingAccountTools',
    description: 'DescriptionMovingAccount',
    link: `${url}/movingAccount`,
    icon: group,
    subDescription: 'SubDescriptionMovingAccount',
  },
  {
    title: 'linkListSuspensionOrResume',
    description: 'DescriptionSuspensionOrResume',
    link: `${url}/suspensionOrResume`,
    icon: calendar,
    subDescription: 'SubDescriptionSuspensionOrResume',
  },
  // {
  //   title: 'linkListSendNotifications',
  //   description: 'DescriptionSendNotifications',
  //   link: `${url}/sendnotifications`,
  // },

  // START --update menu tool wireframe 4.0 - 2023/10/10
  {
    title: 'linkListRepresentAccount',
    description: 'descriptionHeaderRepresetive',
    link: `${url}/representAccount/manual`,
    icon: paint,
    subDescription: 'SubDescriptionHeaderRepresetive',
  },
  // END --update menu tool wireframe 4.0 - 2023/10/10

];

const Tools = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <BreadcrumbsCustom getAfter="admin" />
      <div style={matches ? {} : { margin: '4rem' }}>
        <Grid container spacing={{ xs: 4, md: 8 }} columns={{ xs: 8, sm: 8, md: 8, lg: 12, xl: 12 }}>
          {menuNavigate.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
              <CardActionArea
                onClick={() => navigate(item.link)}
              >
                <Card raised className="item-card">
                  <div className="icon-card">
                    <img alt="icon" src={item.icon} />
                  </div>
                  <div className="content-card">
                    <Typography className="title">{t(item.title)}</Typography>
                    <Typography className="description">{t(item.description)}</Typography>
                    <Typography className="description">{t(item.subDescription)}</Typography>
                  </div>
                </Card>
              </CardActionArea>
            </Grid>
          ))}
        </Grid>
      </div>
    </>

  );
};

export default Tools;
