/* eslint-disable radix */
import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Typography, IconButton, Divider, Button, Grid,
} from '@mui/material';
import moment from 'moment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import scheduleIcon from '../../../../resources/images/icon/scheduleDateIcon.svg';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { SelectCustom } from './CustomComponent';
import { renderTimeOptions, parserTime } from '../../../common/utils';

const useStyle = makeStyles((theme) => ({
  toggle: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0 10px 0',
    '& .switch-end': {
      flexGrow: '1',
      textAlign: 'end',
    },
  },
  timeForm: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    padding: '10px 0',
  },
  separateForm: {
    display: 'flex',
    padding: '3px',
    alignItems: 'center',
  },
  checkBox: {
    color: '#ea8440',
    '&.Mui-checked': {
      color: '#ea8440',
    },
  },
  toggleBtn: {
    border: '1px solid var(--Gray300_E0E0E0, #E0E0E0)',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    fontSize: '12px',
    color: '#9E9E9E',
    margin: '0 5px',
  },
  dayBtnContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    margin: '20px auto',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  deleteBtn: {
    marginLeft: '-15px',
  },
}));

const GetScheduleConstant = {
  MON_CHECKBOX: 'MON_CHECKBOX',
  TUE_CHECKBOX: 'TUE_CHECKBOX',
  WED_CHECKBOX: 'WED_CHECKBOX',
  THU_CHECKBOX: 'THU_CHECKBOX',
  FRI_CHECKBOX: 'FRI_CHECKBOX',
  SAT_CHECKBOX: 'SAT_CHECKBOX',
  SUN_CHECKBOX: 'SUN_CHECKBOX',
  HOUR_START_SELECT: 'HOUR_START_SELECT',
  MIN_START_SELECT: 'MIN_START_SELECT',
  HOUR_END_SELECT: 'HOUR_END_SELECT',
  MIN_END_SELECT: 'MIN_END_SELECT',
  DELETE: 'DELETE',
};

const initValue = {
  days: [],
  time_start: '00:00',
  time_end: '00:00',
};

const initToggleDays = {
  SUN: false,
  MON: false,
  TUE: false,
  WED: false,
  THU: false,
  FRI: false,
  SAT: false,
};

const ScheduleLocation = ({ data, setData, id, callback: handleDelete }) => {
  const classes = useStyle();
  const t = useTranslation();

  const [getScheduleData, setGetScheduleData] = useState(initValue);
  const [isToggled, setIsToggled] = useState(initToggleDays);

  const handleChangeFieldSchedule = (type, value, day) => {
    // console.log('hanleChangeFieldHealCheck', type, value, day);
    const cloneValue = { ...getScheduleData };
    const cloneDays = [...cloneValue.days];
    const cloneData = [...data];

    setIsToggled((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));

    switch (type) {
      case GetScheduleConstant.SUN_CHECKBOX:
        if (isToggled.SUN === true) {
          const index = cloneDays.indexOf('1');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setGetScheduleData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('1') && !isToggled.SUN) {
          cloneDays.push('1');
          setGetScheduleData({ ...cloneValue, days: cloneDays });
        }
        break;
      case GetScheduleConstant.MON_CHECKBOX:
        if (isToggled.MON === true) {
          const index = cloneDays.indexOf('2');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setGetScheduleData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('2') && !isToggled.MON) {
          cloneDays.push('2');
          setGetScheduleData({ ...cloneValue, days: cloneDays });
        }
        break;
      case GetScheduleConstant.TUE_CHECKBOX:
        if (isToggled.TUE === true) {
          const index = cloneDays.indexOf('3');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setGetScheduleData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('3') && !isToggled.TUE) {
          cloneDays.push('3');
          setGetScheduleData({ ...cloneValue, days: cloneDays });
        }
        break;
      case GetScheduleConstant.WED_CHECKBOX:
        if (isToggled.WED === true) {
          const index = cloneDays.indexOf('4');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setGetScheduleData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('4') && !isToggled.WED) {
          cloneDays.push('4');
          setGetScheduleData({ ...cloneValue, days: cloneDays });
        }
        break;
      case GetScheduleConstant.THU_CHECKBOX:
        if (isToggled.THU === true) {
          const index = cloneDays.indexOf('5');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setGetScheduleData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('5') && !isToggled.THU) {
          cloneDays.push('5');
          setGetScheduleData({ ...cloneValue, days: cloneDays });
        }
        break;
      case GetScheduleConstant.FRI_CHECKBOX:
        if (isToggled.FRI === true) {
          const index = cloneDays.indexOf('6');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setGetScheduleData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('6') && !isToggled.FRI) {
          cloneDays.push('6');
          setGetScheduleData({ ...cloneValue, days: cloneDays });
        }
        break;
      case GetScheduleConstant.SAT_CHECKBOX:
        if (isToggled.SAT === true) {
          const index = cloneDays.indexOf('0');
          if (index !== -1) {
            cloneDays.splice(index, 1);
            setGetScheduleData({ ...cloneValue, days: cloneDays });
          }
        }
        if (!cloneDays.includes('0') && !isToggled.SAT) {
          cloneDays.push('0');
          setGetScheduleData({ ...cloneValue, days: cloneDays });
        }
        break;
      case GetScheduleConstant.HOUR_START_SELECT: {
        const newHours = value;
        const currentTime = moment(cloneValue.time_start, 'HH:mm');
        const updatedTime = currentTime.hours(newHours).format('HH:mm');
        setGetScheduleData({ ...cloneValue, time_start: updatedTime });
        break;
      }
      case GetScheduleConstant.MIN_START_SELECT: {
        const newMinutes = value;
        const currentTime = moment(cloneValue.time_start, 'HH:mm');
        const updatedTime = currentTime.minutes(newMinutes).format('HH:mm');
        setGetScheduleData({ ...cloneValue, time_start: updatedTime });
        break;
      }
      case GetScheduleConstant.HOUR_END_SELECT: {
        const newHours = value;
        const currentTime = moment(cloneValue.time_end, 'HH:mm');
        const updatedTime = currentTime.hours(newHours).format('HH:mm');
        setGetScheduleData({ ...cloneValue, time_end: updatedTime });
        break;
      }
      case GetScheduleConstant.MIN_END_SELECT: {
        const newMinutes = value;
        const currentTime = moment(cloneValue.time_end, 'HH:mm');
        const updatedTime = currentTime.minutes(newMinutes).format('HH:mm');
        setGetScheduleData({ ...cloneValue, time_end: updatedTime });
        break;
      }
      case GetScheduleConstant.DELETE: {
        if (cloneData.length === 1) {
          setGetScheduleData(initValue);
          setIsToggled(initToggleDays);
        } else {
          handleDelete(id, 'schedule');
        }
        break;
      }
      default:
    }
  };

  useEffect(() => {
    const cloneMasterData = data;
    const newMasterData = cloneMasterData.map((masterData) => {
      if (masterData.id === id) {
        return { ...getScheduleData, id };
      }
      return masterData;
    });
    setData(newMasterData);
  }, [getScheduleData]);

  const getScheduleLocationComponent = useCallback(() => [initValue].map((item) => (
    <div className="container-schedule" key={id} style={{ margin: '10px 0px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
        <div style={{ display: 'flex', gap: 5 }}>
          <img src={scheduleIcon} alt="schedule" />
          <Typography sx={{ fontSize: 17 }}>{t('scheduleName')}</Typography>
        </div>
        <KeyboardArrowUpIcon style={{ color: '#9E9E9E' }} />
      </div>
      <Typography>{t('selectDays')}</Typography>
      <div className={classes.dayBtnContainer}>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.SUN ? {
            backgroundColor: '#B5E3DC',
            '&:hover': {
              backgroundColor: '#B5E3DC',
            },
          } : {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            handleChangeFieldSchedule(GetScheduleConstant.SUN_CHECKBOX, '1', 'SUN');
          }}
        >
          {t('Sun')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.MON ? {
            backgroundColor: '#B5E3DC',
            '&:hover': {
              backgroundColor: '#B5E3DC',
            },
          } : {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            handleChangeFieldSchedule(GetScheduleConstant.MON_CHECKBOX, '2', 'MON');
          }}
        >
          {t('Mon')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.TUE ? {
            backgroundColor: '#B5E3DC',
            '&:hover': {
              backgroundColor: '#B5E3DC',
            },
          } : {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            handleChangeFieldSchedule(GetScheduleConstant.TUE_CHECKBOX, '3', 'TUE');
          }}
        >
          {t('Tue')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.WED ? {
            backgroundColor: '#B5E3DC',
            '&:hover': {
              backgroundColor: '#B5E3DC',
            },
          } : {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            handleChangeFieldSchedule(GetScheduleConstant.WED_CHECKBOX, '4', 'WED');
          }}
        >
          {t('Wed')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.THU ? {
            backgroundColor: '#B5E3DC',
            '&:hover': {
              backgroundColor: '#B5E3DC',
            },
          } : {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            handleChangeFieldSchedule(GetScheduleConstant.THU_CHECKBOX, '5', 'THU');
          }}
        >
          {t('Thu')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.FRI ? {
            backgroundColor: '#B5E3DC',
            '&:hover': {
              backgroundColor: '#B5E3DC',
            },
          } : {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            handleChangeFieldSchedule(GetScheduleConstant.FRI_CHECKBOX, '6', 'FRI');
          }}
        >
          {t('Fri')}
        </IconButton>
        <IconButton
          className={classes.toggleBtn}
          sx={isToggled.SAT ? {
            backgroundColor: '#B5E3DC',
            '&:hover': {
              backgroundColor: '#B5E3DC',
            },
          } : {
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#FFF',
            },
          }}
          onClick={() => {
            handleChangeFieldSchedule(GetScheduleConstant.SAT_CHECKBOX, '0', 'SAT');
          }}
        >
          {t('Sat')}
        </IconButton>
      </div>
      <div>
        <Divider />
        <Grid container className={classes.timeForm}>
          <Grid item xs={5} sm={4} md={3} xl={3}>
            <Typography>{t('startTime')}</Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={9} xl={9} sx={{ display: 'flex' }}>
            <SelectCustom
              id="hours-start-select"
              defaultValue="0"
              value={parserTime(getScheduleData.time_start).hours}
              onChange={(e) => {
                handleChangeFieldSchedule(GetScheduleConstant.HOUR_START_SELECT, e.target.value);
              }}
            >
              {renderTimeOptions(0, 23)}
            </SelectCustom>
            <span className={classes.separateForm}> : </span>
            <SelectCustom
              id="minite-start-select"
              defaultValue="0"
              value={parserTime(getScheduleData.time_start).minutes}
              onChange={(e) => {
                handleChangeFieldSchedule(GetScheduleConstant.MIN_START_SELECT, e.target.value);
              }}
            >
              {renderTimeOptions(0, 59)}
            </SelectCustom>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.timeForm}>
          <Grid item xs={5} sm={4} md={3} xl={3}>
            <Typography>{t('endTime')}</Typography>
          </Grid>
          <Grid item xs={7} sm={8} md={9} xl={9} sx={{ display: 'flex' }}>
            <SelectCustom
              id="hours-end-select"
              defaultValue="0"
              value={parserTime(getScheduleData.time_end).hours}
              onChange={(e) => {
                handleChangeFieldSchedule(GetScheduleConstant.HOUR_END_SELECT, e.target.value);
              }}
            >
              {renderTimeOptions(0, 23)}
            </SelectCustom>
            <span className={classes.separateForm}> : </span>
            <SelectCustom
              id="minite-end-select"
              defaultValue="0"
              value={parserTime(getScheduleData.time_end).minutes}
              onChange={(e) => {
                handleChangeFieldSchedule(GetScheduleConstant.MIN_END_SELECT, e.target.value);
              }}
            >
              {renderTimeOptions(0, 59)}
            </SelectCustom>
          </Grid>
        </Grid>
        <Divider />
        <div className={classes.deleteBtn}>
          <Button
            onClick={() => handleChangeFieldSchedule(GetScheduleConstant.DELETE)}
          >
            <DeleteOutlineIcon sx={{ color: '#F65A5A' }} />
            <Typography color="#F65A5A">{t('accountSettingDeleteSchedule')}</Typography>
          </Button>
        </div>
      </div>
    </div>
  )), [getScheduleData, handleDelete]);

  return (
    <div>
      {getScheduleLocationComponent()}
    </div>
  );
};

export default ScheduleLocation;
