import React, { useRef, useState, useCallback, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  label_item: {
    width: '132px',
    marginRight: '8px',
  },
}));

const LabelAdd = ({ label, required = false }) => {
  const classes = useStyles();
  return (
    <div>
      <p className={classes.label_item}>
        {label}
        {required ? (
          <span>
            (
            <span style={{ color: 'red' }}>*</span>
            )
          </span>
        ) : (
          <></>
        )}
      </p>
    </div>
  );
};
export default LabelAdd;
