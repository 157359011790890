/* eslint-disable no-undef */
import React, {
  useState, useEffect, useRef,
} from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import maplibregl from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Button, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Header from '../Header';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { useCatchCallback, useEffectAsync } from '../../../../reactHelper';
import MapView, { map } from '../../../../map/core/MapView';
import MapDefaultCamera from '../../../../map/main/MapDefaultCamera';
import MapCurrentLocation from '../../../../map/MapCurrentLocation';
import MapGeocoder from '../../../../map/geocoder/MapGeocoder';
import TableConfig from '../../../components/TableConfig';
import { geofencesActions } from '../../../../store';
import usePersistedState from '../../../../common/util/usePersistedState';
import { geofenceToFeature, zoomInToArea } from '../../../../map/core/mapUtil';
import NewTable from '../../../components/NewTable';
import theme from '../../../../map/draw/theme';
import fetchApi from '../../../FetchApi';
import Vectorback from '../../../../resources/images/Vectorback.png';
import '../../../../styles/components/SetupArea.css';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {},
  userProperties: false,
  boxSelect: false,
  touchEnabled: false,
  keybindings: false,
  styles: [...theme, {
    id: 'gl-draw-title',
    type: 'symbol',
    filter: ['all'],
    layout: {
      'text-field': '{user_name}',
      'text-font': ['Roboto Regular'],
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  }],
});

class MyCustomControl {
  onAdd(map) {
    const element = document.querySelector('.hung');
    if (element) {
      element.remove();
    }
    this.containerCir = document.createElement('button');

    this.map = map;

    this.containerCir.onclick = () => {
      const zoom = this.map.getZoom();
      draw.changeMode('draw_circle', {
        initialRadiusInKm: 1 / 2 ** (zoom - 11),
      });
      draw.delete('-96.5801808656236544.76489866786821');
    };
    this.containerCir.className =
        'mapbox-gl-draw_ctrl-draw-btn my-custom-control-cir';
    // this.containerCir.innerHTML = "&#x25EF";
    this.containerImgCir = document.createElement('img');
    this.containerImgCir.src =
        ' https://cdn-icons-png.flaticon.com/16/808/808569.png';
    this.containerCir.appendChild(this.containerImgCir);

    this.mainContainer = document.createElement('div');

    this.mainContainer.className = 'mapboxgl-ctrl-group mapboxgl-ctrl hung';
    this.mainContainer.appendChild(this.containerCir);

    return this.mainContainer;
  }

  onRemove() {
    // this.containerCir.parentNode.removeChild(this.containerCir);
    this.mainContainer.remove();
    this.map = undefined;
  }
}

const useStyles = makeStyles({
  configTable: {
    height: '600px',
  },
  mapContainer: {
    flexGrow: 1,
    boxShadow: '0px 4px 4px 0px #00000040',
    height: '500px',
    width: '1180px',
  },
  title: {
    fontWeight: 'bold',
    margin: '20px 0 20px 0',
    '&::after': {
      content: '"*"',
      color: 'red',
    },
  },
});

const AreaDetails = () => {
  const t = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [item, setItem] = useState('');
  const [name, setName] = useState('');
  const [gridApi, getGridApi] = useState(null);
  const [dataTable, setDataTable] = useState({
    getGridApi,
    listData: [],
    showPagination: false,
    floatingFilter: true,
    className: classes.configTable,
    removeColumn: [1],
  });

  const refreshGeofences = useCatchCallback(async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const response1 = await fetchApi(`/api/admin/geofences/linktouser/${id}`);
      const response2 = await fetchApi(`/api/admin/geofences/linktouser/${id}/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response1.ok) {
        const responseData1 = await response1.json();
        setItem(responseData1);
        setName(responseData1[0].name);
        setDataTable({
          getGridApi,
          // listChecked: responseData1[0].userIds,
          listData: [],
          floatingFilter: true,
          className: classes.configTable,
        });
      } else {
        throw Error(await response1.text());
      }

      if (response2.ok) {
        const responseData2 = await response2.json();
        setDataTable((prevDataTable) => ({
          ...prevDataTable,
          listData: responseData2,
        }));
      } else {
        // throw Error(await response2.text());
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    refreshGeofences();
    map.addControl(draw);

    return () => {
      map.removeControl(draw);
    };
  }, []);

  useEffect(() => {
    if (item) {
      draw.deleteAll();
      const areaFocus = zoomInToArea(item[0]?.area);
      map.fitBounds(areaFocus[0], areaFocus[1]);
      draw.add(geofenceToFeature(theme, item[0]));
    }
  }, [item]);

  return (
    <div>
      <Header link={-1} noDescription noBack>
        <p className="title-page">{t('areaEditTitle')}</p>
        <div className="setup-head-item">
          <Typography>{t('titleSetupArea')}</Typography>
          <Button className="button-back" onClick={() => navigate(-1)}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
        </div>
      </Header>
      <div style={{ margin: '10px' }}>
        <div className="name-field">
          <Typography variant="h5" className="title-field">{t('sharedAreaName')}</Typography>
          <TextField
            required
            name="areaName"
            value={name || ''}
            disabled
            className="text-field"
          />
          <div className="required-field">
            <p>{t('fieldRequired')}</p>
          </div>
        </div>
        <div className="name-field">
          <Typography className="title-field" variant="h5">{t('sharedArea')}</Typography>
          <div className="required-field">
            <p>{t('fieldRequired')}</p>
          </div>
          <Typography>{t('areaText')}</Typography>
        </div>
        <div className={classes.mapContainer}>
          <MapView>
            <MapDefaultCamera />
          </MapView>
          <MapCurrentLocation />
          <MapGeocoder />
        </div>
        <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '20px 0 20px 0' }}>{t('applicableObject')}</Typography>
        <NewTable {...dataTable} />
      </div>
    </div>
  );
};

export default AreaDetails;
