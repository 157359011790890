import {
  Card, Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownWideShort, faFilter } from '@fortawesome/free-solid-svg-icons';
import CheckIcon from '@mui/icons-material/Check';
import { helperActions } from '../../store';
import fetchApi from '../FetchApi';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { valueTranslate } from '../common/utils';
import './styles/UseFilter.css';

export default forwardRef((props, ref) => {
  const { field } = props.colDef;

  const t = useTranslation();
  const dispatch = useDispatch();
  const cardRef = useRef();
  const filterSelectRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [filterState, setFilterState] = useState('off');
  const [filterSelect, setFilterSelect] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);

  const adminData = useSelector((state) => state.helper.adminData);
  const currentPage = useSelector((state) => state.helper.currentPage);
  const offsetValue = (currentPage - 1) * 50;

  useImperativeHandle(ref, () => ({
    isFilterActive() {
      return filterState !== 'off';
    },
    doesFilterPass(params) {
      const { field } = props.colDef;
      return params.data[field] === filterState;
    },
    getModel() {
      return undefined;
    },
    setModel() {},
  }));

  const handleDirect = (order) => {
    const copyData = { ...adminData };
    const orderBy = [...copyData.orderBy];
    const existingOrderByIndex = orderBy.findIndex((filterField) => filterField.name === field);
    if (existingOrderByIndex === -1) {
      orderBy.push({ name: field, direct: order });
      copyData.orderBy = orderBy;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyOrderBy = [...orderBy];
      copyOrderBy[existingOrderByIndex] = { ...orderBy[existingOrderByIndex], direct: order };
      dispatch(helperActions.setAdminData({ ...copyData, orderBy: copyOrderBy }));
    }
  };

  const handleCheck = (values) => {
    setFilterState(values);
    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: '05', value: values === 'off' ? null : values });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], value: values === 'off' ? null : values };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };

  return (
    <Card style={{ position: 'relative', overflow: 'initial', minWidth: '220px', zIndex: 99999 }} ref={cardRef}>
      <ListItem disablePadding>
        <ListItemButton onClick={() => {
          handleDirect('asc');
        }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowDownWideShort} flip="vertical" size="xl" />
          </ListItemIcon>
          <ListItemText
            primary={t('asc')}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => {
          handleDirect('desc');
        }}
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowDownWideShort} size="xl" />
          </ListItemIcon>
          <ListItemText
            primary={t('desc')}
          />
        </ListItemButton>
      </ListItem>
      <>
        <div>
          <Checkbox
            className="custom-checkbox"
            checked={filterState === 'off'}
            onChange={() => handleCheck('off')}
            icon={(<span className="icon-container" />)}
            checkedIcon={(<span className="checked"><CheckIcon style={{ color: 'black', fontSize: 20 }} /></span>)}
          />
          <span>{t('filterAll')}</span>
        </div>
        {props.values.map((item) => (
          <div key={item.value}>
            <Checkbox
              className="custom-checkbox"
              checked={filterState === item.value}
              onChange={() => handleCheck(item.value)}
              icon={(<span className="icon-container" />)}
              checkedIcon={(<span className="checked"><CheckIcon style={{ color: 'black', fontSize: 20 }} /></span>)}
            />
            <span>{valueTranslate(t, item.value, item.category)}</span>
          </div>
        ))}
      </>
    </Card>
  );
});
