import React, {
  useEffect, useRef, useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Snackbar } from '@mui/material';
import {
  positionsActions, devicesActions, sessionActions, clientAlarmsActions,
} from './store';
import { useEffectAsync } from './reactHelper';
import { useTranslation } from './common/components/LocalizationProvider';
import { snackBarDurationLongMs } from './common/util/duration';
import usePersistedState from './common/util/usePersistedState';

import alarm from './resources/alarm.mp3';
import { eventsActions } from './store/events';
import useFeatures from './common/util/useFeatures';
import { useAttributePreference } from './common/util/preferences';
import { NoAuthLinkData } from './NoAuthLinkData';

const logoutCode = 4000;

const SocketController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const authenticated = useSelector((state) => !!state.session.user);
  const devices = useSelector((state) => state.devices.items);
  const positions = useSelector((state) => state.session.positions);
  const socketRef = useRef();

  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);

  // const [soundEvents] = usePersistedState('soundEvents', []);
  // const [soundAlarms] = usePersistedState('soundAlarms', ['sos']);
  const soundEvents = useAttributePreference('soundEvents', ''); // v5.6
  const soundAlarms = useAttributePreference('soundAlarms', 'sos'); // v5.6

  const features = useFeatures();
  const { pathname, search } = useLocation();
  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    socketRef.current = socket;

    socket.onopen = () => {
      dispatch(sessionActions.updateSocket(true));
    };

    socket.onclose = async (event) => {
      dispatch(sessionActions.updateSocket(false));
      if (event.code !== logoutCode) {
        try {
          const devicesResponse = await fetch('/api/devices');
          if (devicesResponse.ok) {
            dispatch(devicesActions.update(await devicesResponse.json()));
          }
          const positionsResponse = await fetch('/api/positions');
          if (positionsResponse.ok) {
            dispatch(sessionActions.updatePositions(await positionsResponse.json()));
          }
          if (devicesResponse.status === 401 || positionsResponse.status === 401) {
            const link = Object.values(NoAuthLinkData).find((listLink) => listLink === pathname);
            console.log('SocketController path: ', pathname, link);
            if (!link) {
              navigate('/login');
            }
          }
        } catch (error) {
          // ignore errors
        }
        setTimeout(() => connectSocket(), 60000);
      }
    };
    // onerror remove at v5.6
    socket.onerror = async () => {
      dispatch(sessionActions.updateSocket(false));
      const devicesResponse = await fetch('/api/devices');
      if (devicesResponse.ok) {
        dispatch(devicesActions.update(await devicesResponse.json()));
      }
      const positionsResponse = await fetch('/api/positions', {
        headers: { 'Content-Type': 'application/json' },
      });
      if (positionsResponse.ok) {
        dispatch(positionsActions.update(await positionsResponse.json()));
      }
      setTimeout(() => connectSocket(), 60000);
    };
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.devices) {
        dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        // dispatch(positionsActions.update(data.positions));
        dispatch(sessionActions.updatePositions(data.positions)); // v5.6
      }
      if (data.events) {
        if (!features.disableEvents) {
          dispatch(eventsActions.add(data.events));
        }
        setEvents(data.events);
      }
    };
  };

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close(logoutCode);
        }
      };
    }
    return null;
  }, [authenticated]);

  useEffect(() => {
    setNotifications(events.map((event) => ({
      id: event.id,
      message: event.attributes.message,
      show: true,
    })));
  }, [events, devices, t]);

  useEffect(() => {
    events.forEach((event) => {
      if (soundEvents.includes(event.type) || (event.type === 'alarm' && soundAlarms.includes(event.attributes.alarm))) {
        new Audio(alarm).play();
      }
    });
  }, [events, soundEvents, soundAlarms]);

  // lấy địa chỉ
  // const debouncedFunction = _.debounce(() => {
  //   dispatch(sessionActions.restore());
  //   const positionsArray = Object.values(positions).map((it) => ({ latitude: it.latitude, longitude: it.longitude, deviceId: it.deviceId }));
  //   positionsArray.forEach(({ latitude, longitude, deviceId }) => {
  //     const showAddress = async () => {
  //       if (positionsArray.length > 0 && latitude && longitude) {
  //         const query = new URLSearchParams({ latitude, longitude });
  //         const response = await fetch(`/api/server/geocode?${query.toString()}`);
  //         if (response.ok) {
  //           const getAddress = await response.text();
  //           dispatch(sessionActions.push({ getAddress, deviceId }));
  //         } else {
  //           throw Error(await response.text());
  //         }
  //       }
  //     };
  //     showAddress();
  //   });
  // }, 18000);

  // useEffectAsync(async () => {
  //   debouncedFunction();
  // }, [positions]);

  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={notification.show}
          message={notification.message}
          autoHideDuration={snackBarDurationLongMs}
          onClose={() => setEvents(events.filter((e) => e.id !== notification.id))}
        />
      ))}
    </>
  );
};

export default connect()(SocketController);
