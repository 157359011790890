import {
  Box, Modal, Typography, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import React from 'react';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Vector from '../../resources/images/Vector.png';
import '../../styles/components/ModalSuccess.css';

const ModalSuccess = ({ openSuccess, handleClose, title, subTitle }) => {
  const t = useTranslation;

  return (
    <Modal
      open={openSuccess}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="modal-success">
        <IconButton onClick={handleClose} className="button-close-modal">
          <CloseIcon />
        </IconButton>
        <div className="container-content">
          <img src={Vector} alt="icon success" />
          <Typography className="text-content">{title}</Typography>
          <Typography className="text-content">{subTitle}</Typography>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalSuccess;
