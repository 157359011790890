import React, {
  memo,
  useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import {
  IconButton,
  Tooltip,
  Avatar,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Box,
  Button,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useMediaQuery from '@mui/material/useMediaQuery';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery60Icon from '@mui/icons-material/Battery60';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryCharging20Icon from '@mui/icons-material/BatteryCharging20';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { devicesActions } from '../store';
import {
  formatAlarm,
  formatBoolean,
  formatPercentage,
  formatStatus,
  getStatusColor,
} from '../common/util/formatter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { mapIconKey, mapIcons } from '../map/core/preloadImages';
import { useAdministrator } from '../common/util/permissions';
import AccuracyPopup from '../common/components/AccuracyPopup';
// import usePersistedState from '../common/util/usePersistedState';

const useStyles = makeStyles((theme) => ({
  listMobile: {
    height: '100%',
    borderRadius: '10px',
    '& ul > div': {
      overflow: 'hidden scroll !important',
      '&::-webkit-scrollbar': {
        width: '9px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
    },
  },
  list: {
    height: '100%',
    borderRadius: '10px',
  },
  listInner: {
    position: 'relative',
    margin: '0 11px',
    width: 'calc(100% - 22px) !important',
  },
  listInnerDesktop: {
    width: '100%',
    margin: 0,
  },
  avatar: {
    width: '62px',
    height: '62px',
    margin: '21px 0',
    border: '5px solid white',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  listItem: {
    backgroundColor: '#F5F5F5',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    height: '104px',
    margin: '0 11px 7px 11px',
    borderRadius: '10px',
  },
  listItemText: {
    WebkitFlex: 'none',
    margin: 0,
  },
  batteryText: {
    fontSize: '0.75rem',
    fontWeight: 'normal',
    lineHeight: '0.875rem',
  },
  boxContent: {
    paddingLeft: '15px',
    width: '155px',
  },
  positive: {
    color: theme.palette.colors.positive,
    marginBottom: '6px',
    padding: 0,
  },
  medium: {
    color: theme.palette.colors.medium,
    marginBottom: '6px',
    padding: 0,
  },
  negative: {
    color: theme.palette.colors.negative,
    marginBottom: '6px',
    padding: 0,
  },
  neutral: {
    color: theme.palette.colors.neutral,
    marginBottom: '6px',
    padding: 0,
  },
  offline: {
    color: theme.palette.colors.offline,
    marginBottom: '6px',
    padding: 0,
  },
  deviceName: {
    margin: '11px 0 5px 0',
    color: '#6C6A6A',
    fontSize: '16px',
  },
  menuBtn: {
    height: '100px',
    border: '1px solid #E3E3E3',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  menuIcon: {
    height: '45px',
    width: '45px',
    color: '#009B73',
  },
  menuBtnText: {
    height: '15px',
    color: '#6C6A6A',
    fontSize: '12px',
  },
  mobileBattery: {
    marginRight: '22.5px',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '11px',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  secondaryBtn: {
    width: '50%',
    height: '100px',
    border: '1px solid #E3E3E3',
  },
}));

const DeviceRow = ({ index, style, data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const admin = useAdministrator();
  const currentLocation = useLocation().pathname;
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const [hiddenTool, setHiddenTool] = useState(false);
  const [textMemo, setTextMemo] = useState();
  const [isOn, setIsOn] = useState(false);

  const { items } = data;
  const item = items[index];

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const position = useSelector((state) => state.session.positions[item.id]);
  const address = useSelector((state) => state.session.address);

  const handleEmergency = () => {
    setIsOn(!isOn);
  };

  const secondaryText = () => {
    const addressOfDevice = address.find((it) => it.deviceId === item.id);
    return (
      <Tooltip title={<span>{addressOfDevice?.getAddress || 'Loading...'}</span>}>
        <span>{addressOfDevice?.getAddress || 'Loading...'}</span>
      </Tooltip>
    );
  };
    // Effect để cập nhật text
  useEffect(() => {
    setTextMemo(secondaryText());
  }, [address, item, t]);

  /** code of v5
  const secondaryText = () => {
    if (item.status === 'online' || !item.lastUpdate) {
      return formatStatus(item.status, t);
    }
    return moment(item.lastUpdate).fromNow();
  };
  const textMemo = useMemo(() => secondaryText(), [item]);
  */

  useEffect(() => {
    if (currentLocation === '/history') setHiddenTool(true);
  }, [currentLocation]);

  return (
    <div style={style}>
      <Box>
        <ListItemButton
          key={item.id}
          className={desktop ? '' : classes.listItem}
          onClick={() => {
            dispatch(devicesActions.select(item.id));
          }}
          disabled={!admin && item.disabled}
          dense
        >
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <img
                className={classes.icon}
                src={mapIcons[mapIconKey(item.category)]}
                alt=""
              />
            </Avatar>
          </ListItemAvatar>
          <div className={classes.boxContent}>
            <ListItemText
              primary={item.name}
              primaryTypographyProps={{ noWrap: true, fontWeight: 'bold', fontSize: '16px', color: '#6C6A6A' }}
              secondary={textMemo}
              secondaryTypographyProps={{ noWrap: true, fontSize: '12px', color: '#6C6A6A' }}
              // classes={{ secondary: classes[getStatusColor(item.status)], primary: classes.deviceName }}
              classes={{ primary: classes.deviceName }}
              className={classes.listItemText}
            />
            <AccuracyPopup deviceId={item.id} positions={position || {}} />
          </div>
          {/* start of add a <span></span> for component Tooltip
         Adjust the order of the buttons
         Add 2 button share location and view in map app
         */}

          {desktop && (
            <>
              <Tooltip title={t('settingsTitle')}>
                <span>
                  <IconButton
                    size="small"
                    onClick={() => navigate(`/settings/device/${item.id}`)}
                  >
                    <SettingsOutlinedIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={t('locationShare')}>
                <span>
                  <IconButton
                    size="small"
                    // onClick={() => navigate(`/settings/device/${item.id}`)}
                    onClick={() => console.log('clicked to share location')}
                  >
                    <LocationOnOutlinedIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={t('history')}>
                <span>
                  <IconButton
                    size="small"
                    onClick={() => navigate('/replay')}
                    disabled={!position}
                  >
                    <HistoryOutlinedIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={t('playSound')}>
                <span>
                  <IconButton size="small">
                    <VolumeUpOutlinedIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={t('viewOnMap')}>
                <span>
                  <IconButton
                    size="small"
                    // onClick={() => navigate(`/settings/device/${item.id}`)}
                    onClick={() => console.log('View on map app')}
                  >
                    <MapOutlinedIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
          {position && (
            <>
              {position.attributes.hasOwnProperty('alarm') && (
                <Tooltip
                  title={`${t('eventAlarm')}: ${formatAlarm(
                    position.attributes.alarm,
                    t,
                  )}`}
                >
                  <span>
                    <IconButton size="small">
                      <ErrorIcon
                        fontSize="small"
                        className={classes.negative}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {position.attributes.hasOwnProperty('ignition') && (
                <Tooltip
                  title={`${t('positionIgnition')}: ${formatBoolean(
                    position.attributes.ignition,
                    t,
                  )}`}
                >
                  <span>
                    <IconButton size="small">
                      {position.attributes.ignition ? (
                        <FlashOnIcon
                          fontSize="small"
                          className={classes.positive}
                        />
                      ) : (
                        <FlashOffIcon
                          fontSize="small"
                          className={classes.neutral}
                        />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {position.attributes.hasOwnProperty('batteryLevel') && (
                <Tooltip
                  title={
                    item.status === 'online' ? `${t('positionBatteryLevel')}: ${formatPercentage(
                      position.attributes.batteryLevel,
                    )}` : 'Unknown'
                  }
                >
                  <span className={!desktop && classes.mobileBattery}>
                    <IconButton
                      size="small"
                      style={{
                        transform: !desktop && 'matrix(0.00,1.00,-1.00,0.00,-8,-7)',
                      }}
                    >
                      {item.status === 'online' ? (
                        position.attributes.batteryLevel > 70 ? (
                          position.attributes.charge ? (
                            <BatteryChargingFullIcon
                              fontSize="small"
                              className={classes.positive}
                            />
                          ) : (
                            <BatteryFullIcon
                              fontSize="small"
                              className={classes.positive}
                            />
                          )
                        ) : position.attributes.batteryLevel > 30 ? (
                          position.attributes.charge ? (
                            <BatteryCharging60Icon
                              fontSize="small"
                              className={classes.medium}
                            />
                          ) : (
                            <Battery60Icon
                              fontSize="small"
                              className={classes.medium}
                            />
                          )
                        ) : position.attributes.charge ? (
                          <BatteryCharging20Icon
                            fontSize="small"
                            className={classes.negative}
                          />
                        ) : (
                          <Battery20Icon
                            fontSize="small"
                            className={classes.negative}
                          />
                        )
                      ) : position.attributes.batteryLevel === 0 || item.status === 'offline' || item.lastUpdate ? (
                        <Battery20Icon
                          fontSize="small"
                          className={classes.offline}
                        />
                      ) : (
                        <Battery20Icon
                          fontSize="small"
                          className={classes.offline}
                        />
                      ) }
                    </IconButton>
                    {!desktop && (
                      <span>{moment().format('HH:mm')}</span>
                    )}
                  </span>
                  {/* end of add a <span></span> for component Tooltip */}
                </Tooltip>
              )}
            </>
          )}
        </ListItemButton>
      </Box>
      {selectedDeviceId && !desktop && !hiddenTool && (
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Button
            className={classes.menuBtn}
            variant="outlined"
            style={{ flexGrow: 1 }}
            onClick={() => navigate(`/settings/device/${item.id}`)}
          >
            <SettingsOutlinedIcon className={classes.menuIcon} />
            <div className={classes.menuBtnText}>{t('settingsTitle')}</div>
          </Button>
          <Button
            className={classes.menuBtn}
            variant="outlined"
            style={{ flexGrow: 1, backgroundColor: '#F5F5F5' }}
            onClick={() => console.log('clicked to share location')}
          >
            <LocationOnOutlinedIcon className={classes.menuIcon} />
            <div className={classes.menuBtnText}>{t('locationShare')}</div>
          </Button>
          <Button
            className={classes.menuBtn}
            variant="outlined"
            style={{ flexGrow: 1 }}
            onClick={() => navigate('/replay')}
          >
            <HistoryOutlinedIcon className={classes.menuIcon} />
            <div className={classes.menuBtnText}>{t('history')}</div>
          </Button>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Button
              className={classes.secondaryBtn}
              variant="outlined"
              style={{ backgroundColor: '#F5F5F5' }}
              onClick={() => handleEmergency()}
            >
              {isOn ? (
                <div>
                  <VolumeUpOutlinedIcon className={classes.menuIcon} />
                  <div className={classes.menuBtnText}>{t('playSound')}</div>
                </div>
              ) : (
                <div>
                  <VolumeOffOutlinedIcon className={classes.menuIcon} />
                  <div className={classes.menuBtnText}>{t('playSound')}</div>
                </div>
              )}
            </Button>
            <Button
              className={classes.secondaryBtn}
              variant="outlined"
              onClick={() => console.log('View on map app')}
            >
              <MapOutlinedIcon className={classes.menuIcon} />
              <div className={classes.menuBtnText}>{t('viewOnMap')}</div>
            </Button>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

const DevicesList = ({ devices, element }) => {
  const classes = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          width={width}
          height={height}
          itemCount={devices?.length || 0}
          itemData={{ items: devices || [] }}
          itemSize={110}
          overscanCount={10}
          onScroll={(e) => {
            if (e.scrollOffset <= 10) {
              if (element) element.enable();
            }
          }}
        >
          {DeviceRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default memo(DevicesList);
