/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  Button, Typography, TextField,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import './createBtn.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import {
//   CircleMode,
//   DragCircleMode,
//   DirectMode,
//   SimpleSelectMode,
// } from 'maplibre-gl-draw-circle';
import MapCurrentLocation from '../../../../map/MapCurrentLocation';
import MapGeocoder from '../../../../map/geocoder/MapGeocoder';
import Header from '../Header';
import MapDefaultCamera from '../../../../map/main/MapDefaultCamera';
import MapView, { map } from '../../../../map/core/MapView';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { errorsActions, geofencesActions } from '../../../../store';
import CircleMode from './CircleMode';
import theme from '../../../../map/draw/theme';
import { useCatchCallback } from '../../../../reactHelper';
import {
  circleFormat, geometryToArea, polygonToCircle, reverseCoordinates,
} from '../../../../map/core/mapUtil';
import NewTable from '../../../components/NewTable';
import { getUserIds } from '../../../common/utils';
import '../../../../styles/components/SetupArea.css';
import Vectorback from '../../../../resources/images/Vectorback.png';
import ModalSuccess from '../../../modal/ModalSuccess';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  // controls: {
  //   // polygon: true,
  //   // line_string: true,
  //   trash: true,
  // },
  modes: {
    ...MapboxDraw.modes,
    draw_circle: CircleMode,
  },
  userProperties: false,
});

const useStyles = makeStyles({
  configTable: {
    height: '600px',
  },
  saveBtn: {
    width: '100px',
    backgroundColor: 'red',
    border: '5px outset red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
    },
    '&:disabled': {
      backgroundColor: 'gray',
      border: '5px outset gray',
      color: 'white',
      '&:hover': {
        backgroundColor: 'gray',
      },
    },
  },
  title: {
    fontWeight: 'bold',
    margin: '20px 0 20px 0',
    '&::after': {
      content: '"*"',
      color: 'red',
    },
  },
  areaBtn: {
    width: '120px !important',
    height: '40px !important',
    backgroundColor: '#ffebad !important',
    color: 'black !important',
    border: '7px outset #ffebad !important',
  },
});

const CreateArea = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableRef = useRef();

  const [gridApi, getGridApi] = useState(null);
  const [areaData, setAreaData] = useState({ name: '', area: null });
  const [drawBtn, setDrawBtn] = useState();
  const [dataSelect, setDataSelect] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [areaButton, setAreaButton] = useState(false);

  const checkedList = useSelector((state) => state.helper.checkedList);
  // const selectedUser = useSelector((state) => state.helper.selectedUser);

  const userIds = getUserIds(checkedList, dataSelect);

  // 0.5次開発 - DEVELOPMENT-260 - 2024/11/1 TKS DangNH: remove button area
  // class MyCustomControl {
  //   onAdd(map) {
  //     this.containerCir = document.createElement('button');
  //
  //     this.map = map;
  //
  //     this.containerCir.onclick = () => {
  //       const zoom = this.map.getZoom();
  //
  //       draw.changeMode('draw_circle', {
  //         initialRadiusInKm: 1 / 2 ** (zoom - 11),
  //       });
  //
  //       draw.delete('-96.5801808656236544.76489866786821');
  //     };
  //     this.containerCir.className =
  //       `mapbox-gl-draw_ctrl-draw-btn my-custom-control-cir ${classes.areaBtn}`;
  //
  //     this.content = document.createElement('div');
  //     this.content.innerText = t('areaBtn');
  //     this.mainContainer = document.createElement('div');
  //
  //     this.mainContainer.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
  //     this.mainContainer.appendChild(this.containerCir);
  //     this.containerCir.appendChild(this.content);
  //
  //     return this.mainContainer;
  //   }
  //
  //   onRemove() {
  //     // this.container.parentNode.removeChild(this.container);
  //     this.mainContainer.remove();
  //     this.map = undefined;
  //   }
  // }

  // const myCustomControl = new MyCustomControl();

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    removeColumn: [1],
    tableRef,
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  const refreshGeofences = useCatchCallback(async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      dispatch(geofencesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, [dispatch]);

  useEffect(() => {
    refreshGeofences();
    map.addControl(draw);
    return () => map.removeControl(draw);
    // 0.5次開発 - DEVELOPMENT-260 - 2024/11/1 TKS DangNH: remove function button area
    // map.addControl(myCustomControl, 'bottom-right');
    // setDrawBtn(myCustomControl);
    // return () => map.removeControl(draw, myCustomControl);
  }, [map, draw]);

  useEffect(() => {
    const copyData = { ...areaData };
    const onDrawCreate = async (event) => {
      const feature = event.features[0];
      const circle = {
        centerCircle: reverseCoordinates(feature.properties.center),
        radiusCircle: feature.properties.radius * 1000,
      };
      draw.deleteAll();
      draw.add(feature);
      copyData.area = circleFormat(circle.centerCircle.join(' '), circle.radiusCircle);
      setAreaData(copyData);
      setAreaButton(false);
    };

    map.on('draw.create', onDrawCreate);
    return () => {
      map.off('draw.create', onDrawCreate);
    };
  }, [areaData, draw, map]);

  // 0.5次開発 - DEVELOPMENT-260 - 2024/11/1 TKS DangNH: remove function button area
  // useEffect(() => () => {
  //   map.removeControl(myCustomControl);
  // }, []);

  useEffect(() => {
    const copyData = { ...areaData };
    const listener = async (event) => {
      const feature = event.features[0];
      draw.add(feature);
      copyData.area = polygonToCircle(geometryToArea(feature.geometry));

      setAreaData(copyData);
    };

    map.on('draw.update', listener);
    return () => map.off('draw.update', listener);
  }, [dispatch, refreshGeofences, areaData]);

  const handleSaveArea = async () => {
    try {
      const response = await fetch('/api/admin/geofences/linktouser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: areaData.name,
          area: areaData.area,
          userIds,
        }),
      });
      if (response.ok) {
        setOpenSuccess(true);
        const item = await response.json();
        map.removeControl(drawBtn);
        dispatch(geofencesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      // dispatch(errorsActions.push(error.message));

    }
  };

  return (
    <div>
      <Header listPath="tools/createArea/createAreaPath" link={-1} noDescription noBack>
        <p className="title-page">{t('linkListCreateArea')}</p>
        <div className="setup-head-item">
          <Typography>{t('titleSetupArea')}</Typography>
          <Button
            disabled={!areaData.name || !areaData.area}
            className="button-creat-area"
            onClick={() => handleSaveArea()}
          >
            <Typography>{t('buttonCreate')}</Typography>
          </Button>
          <Button className="button-back" onClick={() => navigate(-1)}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
        </div>
      </Header>
      <div style={{ margin: '10px', backgroundColor: '#FFFFFF', boxShadow: '5px 5px 20px 0px #D3D3D3', padding: '5px 30px 30px 30px' }}>
        <div className="name-field">
          <Typography variant="h5" className="title-field">{t('sharedAreaName')}</Typography>
          <TextField
            required
            name="areaName"
            placeholder={t('placeholderNameArea')}
            value={areaData.name}
            className="text-field"
            onChange={(event) => {
              const copyData = { ...areaData };
              copyData.name = event.target.value;
              setAreaData(copyData);
            }}
          />
          <div className="required-field">
            <p>{t('fieldRequired')}</p>
          </div>
        </div>
        <div className="edit-area">
          <div className="name-field">
            <Typography className="title-field" variant="h5">{t('sharedArea')}</Typography>
            <div className="required-field">
              <p>{t('fieldRequired')}</p>
            </div>
            <Typography>{t('areaText')}</Typography>
          </div>
          <Button
            disabled={areaButton}
            className="button-delete"
            onClick={() => {
              setAreaButton(true);
              draw.changeMode('draw_circle', {
                initialRadiusInKm: 1 / 2 ** (map.getZoom() - 11),
              });
            }}
          >
            <Typography>{t('areaBtn')}</Typography>
          </Button>
        </div>
        <div style={{ flexGrow: 1, height: '500px', width: '1180px', boxShadow: '0px 4px 4px 0px #00000040' }}>
          <MapView>
            <MapDefaultCamera />
          </MapView>
          <MapCurrentLocation />
          <MapGeocoder />
        </div>
        <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '20px 0 20px 0' }}>{t('applicableObject')}</Typography>
        {/* <TableConfig {...dataTable} /> */}
        <NewTable {...dataTable} />
        <ModalSuccess
          openSuccess={openSuccess}
          handleClose={() => {
            setOpenSuccess(false);
            navigate('/admin/tools/setupArea');
          }}
          title={t('createAreaSuccess')}
        />
      </div>
    </div>
  );
};

export default CreateArea;
