import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../resources/images/TreLogo.svg';
// import TreLogo from '../resources/images/TreLogo.svg';
const useStyles = makeStyles(() => ({
  image: {
    alignSelf: 'center',
    width: '250px',
    height: '125px',
  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();

  return (<Logo className={classes.image} style={{ color }} />);
};

export default LogoImage;
