/* eslint-disable react/no-array-index-key */
import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import {
  Breadcrumbs, Button, Fade, Link, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles, useTheme } from '@mui/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../common/components/LocalizationProvider';
import { helperActions } from '../../store';
import '../../styles/components/BreadcrumbsCustom.css';

const useStyle = makeStyles(() => ({
  textConfig: {
    fontSize: '1.5rem',
    textDecoration: 'none',
    textTransform: 'capitalize',

  },
  textConfigMobile: {
    fontSize: '1.2rem',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
  breadcrumbsConfig: {
    display: 'inline-block',
    margin: '1rem 0',
    '& .MuiBreadcrumbs-separator ': {
      '& .MuiSvgIcon-root': {
        width: '2rem',
      },
    },
  },
  breadcrumbsMobileConfig: {
    display: 'inline-block',
    margin: '1rem 0',
    '& .MuiBreadcrumbs-separator ': {
      '& .MuiSvgIcon-root': {
        width: '1rem',
      },
    },
  },
}));
const BreadcrumbsCustom = ({ getAfter, listPath }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyle();
  const t = useTranslation();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = location;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrPath = listPath ? listPath.split('/') : pathname.split('/');
  const indexTarget = arrPath.findIndex((e) => e === getAfter);
  const listResult = arrPath.filter((v, i) => (!(i <= indexTarget)));

  const configName = (keys) => {
    const listLink = {
      home: t('linkListHome'),
      tools: t('linkListTool'),
      list: t('linkListAccountList'),
      edit: t('linkListEdit'),
      reset: t('linkListReset'),
      setupArea: t('linkListSetUpArea'),
      createArea: t('linkListCreateArea'),
      editArea: t('linkListEditArea'),
      areaDetails: t('linkListAreaDetails'),
      monitorTarget: t('linkListSettingAccount'),
      groupSettings: t('linkListGroupSettings'),
      changepassword: t('linkListChangePassword'),
      mimamoritarget: t('linkListMimamoriTarget'),
      manager: t('linkListManager'),
      linkListMovingAccountTools: t('linkListMovingAccountTools'),
      pathSuspensionOrResume: t('pathSuspensionOrResume'),
      movingAccount: t('linkListMovingAccount'),
      suspensionOrResume: t('linkListSuspensionOrResume'),
      sendnotifications: t('linkListSendNotifications'),
      confirm: t('linkListConfirm'),
      account: t('settingsUser'),
      representAccount: t('linkListRepresentAccount'),
      manual: t('manual'),
      settingAccount: t('linkListSettingAccount'),
      changePasswordForm: t('changePasswordForm'),
      details: t('linkListDetails'),
      history: t('linkListHistory'),
      accountExportCSV: t('breadCrumbAccountExportCSV'),
      accountSetting: t('accountSetting'),
      changePassTitle: t('changePassTitle'),
      pathCreateAccountRepresent: t('pathCreateAccountRepresent'),
      detailTableHeader: t('detailTableHeader'),
      addAccountMemberPath: t('addAccountMemberPath'),
      createAreaPath: t('buttonCreate'),
    };
    return listLink[keys] || keys;
  };

  const renderItem = () => listResult.map((v, i) => {
    const target = arrPath.findIndex((e) => e === v);
    const link = arrPath.slice(0, target + 1).join('/');
    return (
      <div key={i}>
        {listResult.length - 1 !== i ? (
          <div className={desktop ? classes.textConfig : classes.textConfigMobile}>{configName(v)}</div>
        ) : (
          <div className={desktop ? classes.textConfig : classes.textConfigMobile}>{configName(v)}</div>
        )}
      </div>
    );
  });

  const handleAccount = () => {
    navigate('/admin/settingAccount');
    handleClose();
  };

  const handleLogout = async () => {
    dispatch(helperActions.unActive());
    await fetch('/api/session', { method: 'DELETE' });
    navigate('/logout');
  };

  return (
    <div className="header-main">
      <Breadcrumbs separator={<ArrowForwardIosIcon />} aria-label="breadcrumb" className={desktop ? classes.breadcrumbsConfig : classes.breadcrumbsMobileConfig}>
        {renderItem()}
      </Breadcrumbs>
      <Button onClick={handleClick} className="button-hamburger-menu" startIcon={<MenuIcon />} />
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <List component="div" disablePadding>
          <ListItemButton onClick={handleAccount}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary={t('settingsTitle')} />
          </ListItemButton>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t('loginLogout')} />
          </ListItemButton>
        </List>
      </Menu>
    </div>
  );
};

export default React.memo(BreadcrumbsCustom);
