/* eslint-disable no-undef */
import React, { useState } from 'react';
import {
  signInWithPopup, getAuth, onAuthStateChanged, fetchSignInMethodsForEmail, getAdditionalUserInfo,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import {
  Button, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from 'firebase/firestore';
import {
  auth, provider,
} from '../../firebase-config/Firebase';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Google from '../resources/google-icon.svg';
import LINE from '../resources/LINE-icon.svg';
import YahooJP from '../resources/yahoojp-icon.svg';

const useStyles = makeStyles((theme) => ({
  loginBtn: {
    borderRadius: '30px',
    backgroundColor: '#F5F5F5',
    margin: '10px',
    color: '#4B4B4B',
    '&:hover': { backgroundColor: '#F5F5F5' },
  },
  iconBtn: {
    position: 'absolute', left: '20px',
  },
}));

const SignIn = ({ setIsAuth }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        setIsAuth(true);
        const { user } = result;
        const { uid } = user;
        const isNewUser = getAdditionalUserInfo(result);
        console.log('🚀 ~ file: SignIn.js:55 ~ .then ~ isNewUser:', isNewUser);
        // console.log({ uid, user });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // The AuthCredential type that was used.
      });
  };

  // const signInWithGoogle = async () => {
  //   try {
  //     const res = await signInWithPopup(auth, provider);
  //     const { user } = res;
  //     const q = query(collection(db, 'profiles'), where('uid', '==', user.uid));
  //     const docs = await getDocs(q);
  //     console.log({ q, docs });
  //     if (docs.docs.length === 0) {
  //       await addDoc(collection(db, 'users'), {
  //         uid: user.uid,
  //         name: user.displayName,
  //         authProvider: 'google',
  //         email: user.email,
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     // Người dùng đã đăng nhập
  //     const googleProviderId = 'google.com'; // Đây là providerId cho dịch vụ Google
  //     if (user.providerData.some((provider) => provider.providerId === googleProviderId)) {
  //       console.log('Người dùng đã đăng nhập bằng Google và đã liên kết với Firebase');
  //     } else {
  //       console.log('Người dùng đã đăng nhập nhưng chưa liên kết với Firebase');
  //     }
  //   } else {
  //     console.log('Người dùng chưa đăng nhập');
  //   }
  // });

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <Button variant="outline" className={classes.loginBtn} onClick={signInWithGoogle}>
        <img src={LINE} alt="LINE" className={classes.iconBtn} />
        <Typography fontSize={!desktop && 12}>{t('line')}</Typography>
      </Button>
      <Button variant="outline" className={classes.loginBtn} onClick={signInWithGoogle}>
        <img src={YahooJP} alt="YahooJP" className={classes.iconBtn} />
        <Typography fontSize={!desktop && 12}>{t('yahoojp')}</Typography>
      </Button>
      <Button variant="outline" className={classes.loginBtn} onClick={signInWithGoogle}>
        <img src={Google} alt="google" className={classes.iconBtn} />
        <Typography fontSize={!desktop && 12}>{t('google')}</Typography>
      </Button>
    </div>
  );
};

export default SignIn;
