import { createSlice } from '@reduxjs/toolkit';

const lockScreenSlice = createSlice({
  name: 'lockScreen',
  initialState: {
    checked: false,
  },
  reducers: {
    setLockScreen(state, action) {
      state.checked = action.payload;
    },
  },
});

export const { setLockScreen } = lockScreenSlice.actions;
export const selectLockScreen = (state) => state.lockScreen.checked;
export default lockScreenSlice.reducer;
