/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/destructuring-assignment */
import { makeStyles } from '@mui/styles';
import React, {
  useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import {
  Card, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownWideShort, faFilter } from '@fortawesome/free-solid-svg-icons';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { helperActions } from '../../store';
import { useTranslation } from '../../common/components/LocalizationProvider';
import fetchApi from '../FetchApi';

const useStyles = makeStyles((theme) => ({
  filterMain: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
  },
  listItemRoot: {
    '&:hover': {
      backgroundColor: '#f46717',
    },
  },
}));

const FilterComponent = (props, ref) => {
  const { field, filterParams } = props.colDef;
  const { column } = props;
  const t = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardRef = useRef();
  const filterSelectRef = useRef(null);

  const adminData = useSelector((state) => state.helper.adminData);
  const currentPage = useSelector((state) => state.helper.currentPage);

  const [open, setOpen] = useState(false);
  const [filterType, setFilterType] = useState();
  const [filterState, setFilterState] = useState('off');
  const [filterSelect, setFilterSelect] = useState(false);
  const [filterSelectPostion, setFilterSelectPosition] = useState({ right: '0' });
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [data, setData] = useState('');
  let keyHold = false;

  const pageSize = 50;
  const offset = (currentPage - 1) * pageSize;

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    props.onUiChange();
  }, [filterState]);
  useImperativeHandle(ref, () => ({

    isFilterActive() {
      return false;
    },

    getModel() {
    },

    setModel() {
    },

    afterGuiAttached(e) {
      setFilterSelect(false);
      // 0.5次開発 - DEVELOPMENT-254 - 2024/10/16 TKS DangNH: remove logic float filter
      // const table = cardRef?.current?.offsetParent?.offsetParent;
      // const tableWidth = table?.offsetWidth;
      // const cardPosition = cardRef?.current?.offsetParent?.offsetLeft;
      // const cardWidth = cardRef?.current?.offsetParent?.offsetWidth;
      // const filterSelectHeight = filterSelectRef?.current?.clientHeight;
      // if (cardWidth + cardPosition + 200 >= tableWidth) {
      //   setFilterSelectPosition({ right: '220px' });
      // } else {
      //   setFilterSelectPosition({ left: '220px' });
      // }
    },
    afterGuiDetached() {
      setOpen(false);
    },
  }));

  const handleClick = (values) => {
    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: values, value: null });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], type: values, value: filterParams?.type !== 'NUMBER' ? filter[existingFilterIndex].value : (values === '05' || values === '06') ? Number(filter[existingFilterIndex].value) : filter[existingFilterIndex].value };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };

  const handleDirect = (order) => {
    const copyData = { ...adminData };
    const orderBy = [...copyData.orderBy];
    const existingOrderByIndex = orderBy.findIndex((filterField) => filterField.name === field);
    if (existingOrderByIndex === -1) {
      orderBy.push({ name: field, direct: order });
      copyData.orderBy = orderBy;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyOrderBy = [...orderBy];
      copyOrderBy[existingOrderByIndex] = { ...orderBy[existingOrderByIndex], direct: order };
      dispatch(helperActions.setAdminData({ ...copyData, orderBy: copyOrderBy }));
    }
  };

  const handleFilterChange = (inputValue, field) => {
    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: '01', value: inputValue });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], value: column?.colDef?.filterParams?.type !== 'NUMBER' ? inputValue : (filter[existingFilterIndex].type === '05' || filter[existingFilterIndex].type === '06') ? Number(inputValue) : inputValue };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };

  useEffect(() => {
    const table = cardRef?.current?.offsetParent?.offsetParent;
    const tableWidth = table.offsetWidth;
    const cardPosition = cardRef?.current?.offsetParent?.offsetLeft;
    const cardWidth = cardRef.current?.offsetParent?.offsetWidth - 5;
    const filterSelectHeight = filterSelectRef?.current?.clientHeight - 3;
    if (cardWidth + cardPosition + 200 >= tableWidth) {
      setFilterSelectPosition({ right: `${cardWidth}px`, top: `calc(100% - ${filterSelectHeight}px)` });
    } else {
      setFilterSelectPosition({ left: `${cardWidth}px`, top: `calc(100% - ${filterSelectHeight}px)` });
    }
  }, []);

  return (
    <div style={{ padding: 5 }}>
      <TextField
        className={classes.textFieldConfig}
        id="outlined-basic"
        variant="outlined"
        value={data}
        onChange={(e) => {
          setData(e.target.value);
          handleFilterChange(e.target.value, column.colDef.field);
        }}
        onKeyDown={(event) => {
          if (column?.colDef?.filterParams?.type === 'NUMBER') {
            const { key } = event; // Get characters from keydown event
            // Check if the character is a letter
            const isLetter = /^[a-zA-Z]$/.test(key);
            // Check if the character is a special character
            const isSpecialCharacter = /^[!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(key);
            // Checks if the character is a letter or special character, suppressing the keydown event
            if (isLetter || isSpecialCharacter) {
              event.preventDefault();
            }
          }
          if (!keyHold) {
            keyHold = true;
          }
        }}
        onKeyUp={(event) => {
          keyHold = false;
          event.preventDefault();
        }}
      />
      <List style={{ position: 'relative', overflow: 'initial', minWidth: '220px', zIndex: 99999 }} ref={cardRef}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            handleDirect('asc');
          }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faArrowDownWideShort} flip="vertical" size="xl" />

            </ListItemIcon>
            <ListItemText
              primary={t('asc')}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => {
            handleDirect('desc');
          }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faArrowDownWideShort} size="xl" />
            </ListItemIcon>
            <ListItemText
              primary={t('desc')}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding ref={filterSelectRef}>
          <ListItemButton onClick={() => {
            setOpen(false);
            setFilterSelect(!filterSelect);
          }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faFilter} size="xl" />
            </ListItemIcon>
            <ListItemText
              primary={t('filter')}
            />
          </ListItemButton>
        </ListItem>
        <Card style={{ position: 'absolute', ...filterSelectPostion, width: '220px', display: !filterSelect ? 'none' : 'block' }}>
          <ListItem style={{ height: filterSelectRef?.current?.clientHeight, backgroundColor: '#CDC6C2' }} disablePadding>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemText primary={filterType || t('textContains')} />
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> }
            </ListItemButton>
          </ListItem>
          {open && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  className={classes.listItemRoot}
                  onClick={() => {
                    handleClick('01');
                    setOpen(false);
                    setFilterType(t('textContains'));
                  }}
                >
                  <ListItemText primary={t('textContains')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={classes.listItemRoot}
                  onClick={() => {
                    handleClick('02');
                    setOpen(false);
                    setFilterType(t('textDoesNotContains'));
                  }}
                >
                  <ListItemText primary={t('textDoesNotContains')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={classes.listItemRoot}
                  onClick={() => {
                    handleClick('03');
                    setOpen(false);
                    setFilterType(t('textStartWith'));
                  }}
                >
                  <ListItemText primary={t('textStartWith')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={classes.listItemRoot}
                  onClick={() => {
                    handleClick('04');
                    setOpen(false);
                    setFilterType(t('textEndWith'));
                  }}
                >
                  <ListItemText primary={t('textEndWith')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={classes.listItemRoot}
                  onClick={() => {
                    handleClick('05');
                    setOpen(false);
                    setFilterType(t('textExactly'));
                  }}
                >
                  <ListItemText primary={t('textExactly')} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  className={classes.listItemRoot}
                  onClick={() => {
                    handleClick('06');
                    setOpen(false);
                    setFilterType(t('notEqual'));
                  }}
                >
                  <ListItemText primary={t('notEqual')} />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </Card>
      </List>
    </div>
  );
};
export default React.forwardRef(FilterComponent);
