import React from 'react';
import Logo from '../resources/images/TreLogo.svg';
import '../styles/components/PageLayoutAdmin.css';

const PageLayoutAdmin = ({ menu, children }) => (
  <div className="layout-page">
    <div className="right-menu">
      <img className="logo" src={Logo} alt="logo-tre" />
      {menu}
    </div>
    <div className="container-children">
      {children}
    </div>
  </div>
);

export default PageLayoutAdmin;
