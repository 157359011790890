import React, { useState } from 'react';
import {
  Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Modal, Box,
} from '@mui/material';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import LoginLayout from './LoginLayout';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useEffectAsync } from '../reactHelper';
import pdf from '../resources/pdf/myPdf.pdf';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
  },
  dialog: {
    fullScreen: theme.breakpoints.down('md'),
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '90%',
    border: '2px solid #000',
  },
}));

const ActiveUserPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const { activationCode } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffectAsync(async () => {
    if (activationCode) {
      const response = await fetch(`/api/users/activate/${activationCode}`);
      if (response.ok) {
        setOpenDialog(true);
      } else {
        throw Error(await response.text());
      }
    }
  }, [activationCode]);

  const handleClose = () => {
    navigate('/login');
  };

  const linkClick = () => {
    setOpenModal(true);
    setOpenDialog(false);
  };

  return (
    <LoginLayout>
      <div className={classes.container}>
        <Dialog
          className={classes.dialog}
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle textAlign="center" id="responsive-dialog-title">
            {t('registerSuccessTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography>
                <Typography display="inline">・</Typography>
                <Typography display="inline">
                  {t('registerSuccessDetail1')}
                </Typography>
              </Typography>
              <Typography>
                <Typography display="inline">・</Typography>
                <Typography display="inline">
                  {t('registerSuccessDetail2')}
                </Typography>
              </Typography>
              <Typography>
                <Typography display="inline">・</Typography>
                <Typography display="inline">
                  {t('registerSuccessDetail3')}
                </Typography>
              </Typography>
              <Typography>
                <Typography display="inline">・</Typography>
                <NavLink display="inline" onClick={linkClick} to="#">{t('termOfService')}</NavLink>
                <Typography display="inline">
                  {t('registerSuccessDetail4')}
                </Typography>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
            <Viewer fileUrl={pdf} defaultScale={SpecialZoomLevel.PageWidth} />
          </Worker>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </Box>
      </Modal>
    </LoginLayout>
  );
};

export default ActiveUserPage;
