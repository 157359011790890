/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { MenuItem } from '@mui/material';
import CryptoJS from 'crypto-js';

const { REACT_APP_SECRETKEY, REACT_APP_IVKEY } = process.env;

const key = CryptoJS.enc.Utf8.parse(REACT_APP_SECRETKEY); // key
const iv = CryptoJS.enc.Utf8.parse(REACT_APP_IVKEY); // optional

export const encrypted = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
    {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  return encryptedData.toString();
};

export const combinedString = (year, month) => {
  const combinedString = year.toString() + month.toString().padStart(2, '0');

  return combinedString;
};

export const renderTimeOptions = (start, maxTime) => {
  const options = [];
  // eslint-disable-next-line no-plusplus
  for (let time = start; time <= maxTime; time++) {
    const timeFormatted = time < 10 ? `0${time}` : `${time}`;
    options.push(
      <MenuItem key={time} value={time}>
        {timeFormatted}
      </MenuItem>,
    );
  }
  return options;
};
