/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Autocomplete, Box, Button, Modal, Paper, TextField, Typography, useMediaQuery,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import debounce from 'lodash/debounce';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import Header from '../Header';
import Table from '../../../components/Table';
import ComfirmationPopup from '../ComfirmationPopup';
import Vectorback from '../../../../resources/images/Vectorback.png';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { helperActions } from '../../../../store';
import fetchApi from '../../../FetchApi';
import Vector from '../../../../resources/images/Vector.png';
import ModalSuccess from '../../../modal/ModalSuccess';

const useStyles = makeStyles((theme) => ({
  tableConfig: {
    marginTop: '20px',
    height: '480px',
    '& .ag-header-allow-overflow': {
      display: 'none',
    },
    '& .ag-row-position-absolute': {
      borderRight: 'none !important',
      borderBottom: 'none',
    },
    '& .ag-cell': {
      borderRight: 'none !important',
    },
  },
  configBtn: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem',
  },
  backBtn: {
    backgroundColor: '#fff',
    display: 'flex',
    color: '#7f7f7f',
    border: '1px solid #7f7f7f',
    width: '123px',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '20px',
    marginLeft: 30,
    padding: '20px',
  },
  createBtn: {
    backgroundColor: '#08857C',
    display: 'flex',
    color: 'white',
    width: '123px',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '20px',
    marginLeft: 30,
    padding: '20px 30px',
    '&:hover': {
      backgroundColor: '#08857C',
    },
  },
  add: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    border: '2px solid !important',
    color: '#08857C',
    borderColor: '#08857C',
    borderRadius: '20px',
    width: '130px',
    margin: '10px',
    '&:disabled': {
      backgroundColor: '#fff',
      color: '#08857C',
      borderColor: '#08857C',
    },
    '&:hover': {
      backgroundColor: '#08857C',
      color: 'white',
      borderColor: '#08857C',
    },
  },
  return: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    color: '#08857C',
    borderColor: '#08857C',
    width: '100px',
    margin: '10px',
    '&:hover': {
      backgroundColor: '#08857C',
      color: 'white',
      borderColor: '#08857C',
    },
  },
  inputRoot: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 4px 0px #D3D3D3',
    borderRadius: '0px',
    '&.MuiOutlinedInput-root .MuiAutocomplete-input': {
      backgroundColor: 'white',
      border: 'none',
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '3px !important',
    },
    '& .MuiAutocomplete-popupIndicator': {
      height: '40px',
      marginLeft: '5px',
      borderRadius: '0',
      background: '#E5E5E5 !important',
    },
  },
  modalContainer: {
    backgroundColor: 'white', width: '500px', height: '200px', margin: 'auto auto', position: 'relative', top: ' 50%', transform: 'translateY(-50%)', padding: '20px',
  },
}));

const MovingAccount = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const tableRef = useRef();
  const tableReciveRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const breakPointMD = useMediaQuery(theme.breakpoints.up('md'));
  const breakPoint800 = useMediaQuery('(min-width:800px)');

  const [selectData, setSelectData] = useState([]);
  const [selectDataRecive, setSelectDataRecive] = useState([]);
  const [value, setValue] = useState(null);
  const [valueRecive, setValueRecive] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [rowDataRecive, setRowDataRecive] = useState([]);
  const [dataTransport, setDataTransport] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [limit, setLimit] = useState(50);
  const [limitRecive, setLimitRecive] = useState(50);
  const [offset, setOffSet] = useState(0);
  const [offsetRecive, setOffSetRecive] = useState(0);
  const [total, setTotal] = useState(0);
  const [onLoad, setOnload] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [onLoadRecive, setOnloadRecive] = useState(false);
  // const [representName, setRepresentName] = useState('');
  const [open, setOpen] = useState(false);
  const [openRecive, setOpenRecive] = useState(false);

  const changeData = (params, callback) => {
    const newData = [];
    params?.forEach?.((value) => {
      newData.push({
        id: value.id,
        group: value.groupName,
        target: value.name,
      });
    });
    callback(newData);
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    navigate('/admin/tools');
  };
  // api090
  useEffect(() => {
    const fetchData = async () => {
      dispatch(helperActions.setLoading(true));
      const params = {
        limit,
        offset,
      };
      const varible = new URLSearchParams(params);
      const response = await fetchApi(`/api/admin/members/manager?${varible.toString()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        const manager = objRecive.data.map?.((value) => ({
          label: value.name,
          id: value.id,
        }));
        setSelectData(manager);
        setTotal(objRecive.total);
        setSelectDataRecive(manager);
      }
      dispatch(helperActions.setLoading(false));
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(helperActions.setLoading(true));
      const response = await fetchApi(`/api/admin/members/${value.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        const copyObjRecive = objRecive.filter((user) => user.role !== 1);
        changeData(copyObjRecive, setRowData);
      }
      dispatch(helperActions.setLoading(false));
    };
    if (value) {
      fetchData();
    } else {
      setRowData([]);
    }
  }, [value]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(helperActions.setLoading(true));
      const response = await fetchApi(`/api/admin/members/${valueRecive.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        const copyObjRecive = objRecive.filter((user) => user.role !== 1);
        changeData(copyObjRecive, setRowDataRecive);
      }
      dispatch(helperActions.setLoading(false));
    };
    if (valueRecive) {
      fetchData();
    } else {
      setRowDataRecive([]);
    }
  }, [valueRecive]);

  const sendDataFunction = (params) => {
    const copydata = [...dataTransport];
    setDataTransport(copydata.concat(params));
    const configDataSend = rowData.filter((data) => {
      const conditon = params?.find((e) => e.id === data.id && e.group === data.group && e.target === data.target);
      if (conditon) return false;
      return true;
    });
    const copyDataRecive = [...rowDataRecive];
    const configDataRecive = copyDataRecive.concat(params);
    setRowData(configDataSend);
    setRowDataRecive(configDataRecive);
  };

  const returnDataFunction = (params) => {
    const configDataTransport = dataTransport.filter((data) => {
      const conditon = params?.find((e) => e.id === data.id && e.group === data.group && e.target === data.target);
      if (conditon) return false;
      return true;
    });

    const configDataRecive = rowDataRecive.filter((data) => {
      const conditon = params?.find((e) => e.id === data.id && e.group === data.group && e.target === data.target);
      if (conditon) return false;
      return true;
    });
    const copyDataSend = [...rowData];
    const configDataSend = copyDataSend.concat(params);
    setRowData(configDataSend);
    setRowDataRecive(configDataRecive);
    setDataTransport(configDataTransport);
  };

  const onSelectionChangedRecive = useCallback((event) => {
    const rowNodes = event.api.getSelectedNodes();
    rowNodes.forEach((row, i) => {
      const conditon = dataTransport?.find((e) => e.id === row.data.id && e.group === row.data.group && e.target === row.data.target);
      if (!conditon) rowNodes[i].setSelected(false);
    });
  }, [dataTransport, rowData]);

  const onSelectionChanged = useCallback((event) => {
    const rowNodes = event.api.getSelectedNodes();
    rowNodes.forEach((row, i) => {
      const conditon = rowDataRecive?.find((e) => e.id === row.data.id && e.group === row.data.group && e.target === row.data.target);
      if (conditon) rowNodes[i].setSelected(false);
    });
  }, [dataTransport, rowDataRecive]);

  const dataTransportIds = dataTransport.map((user) => user.id);

  const handleSubmit = async () => {
    try {
      const response = await fetchApi('/api/admin/members/change_manager', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: value?.id,
          to: valueRecive?.id,
          userIds: dataTransportIds,
        }),
      });
      if (response.ok) {
        setOpenModal(true);
        setDataTransport([]);
        setValue(null);
        setValueRecive(null);
        dispatch(helperActions.setPathname(location.pathname));
        setOpenSuccess(true);
      } else {
        throw Error(await response.text());
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSearch = async (representName, callback) => {
    const response = await fetchApi(`/api/admin/members/manager?limit=50&offset=0${representName ? `&name=${representName}` : ''}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const objRecive = await response.json();
      const manager = objRecive.data.map?.((value) => ({
        label: value.name,
        id: value.id,
      }));
      setValue(value);
      setTotal(objRecive.total);
      callback(manager);
    }
  };

  const dataTable = {
    tableRef,
    listData: rowData,
    pagination: false,
    elementHeight: 40,
    onSelectionChanged,
    rowSelection: 'multiple',
    floatingFilterOff: true,
    cancelAutoHeight: true,
    rowMultiSelectWithClick: true,
    className: classes.tableConfig,
    customColumnDefs: [

      {
        headerName: '',
        field: 'target',
        filter: false,
        width: 298,
        floatingFilter: false,
        valueGetter: (params) => {
          const item = params.data;
          return `${item.id} : ${item.target}`;
        },
      },
    ],
  };

  const dataTableRecive = {
    tableRef: tableReciveRef,
    listData: rowDataRecive,
    elementHeight: 40,
    pagination: false,
    cancelAutoHeight: true,
    floatingFilterOff: true,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    className: classes.tableConfig,
    onSelectionChanged: onSelectionChangedRecive,
    customColumnDefs: [
      {
        headerName: '',
        field: 'target',
        width: 298,
        filter: false,
        floatingFilter: false,
        valueGetter: (params) => {
          const item = params.data;
          return `${item.id} : ${item.target}`;
        },
      },
    ],

  };

  const configWrapBox = () => {
    if (!breakPointMD && breakPoint800) {
      return 'no-wrap';
    }
    return 'wrap';
  };

  const handleScroll = useCallback(debounce(async (oldData, limit, offset, total) => {
    setOnload(true);
    if (offset + limit <= total && !onLoad) {
      const params = {
        limit,
        offset: limit + offset,
      };

      const varible = new URLSearchParams(params);
      const response = await fetchApi(`/api/admin/members/manager?limit=${params.limit}&offset=${params.offset}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        const manager = objRecive.data.map?.((value) => ({
          label: value.name,
          id: value.id,
        }));
        setOffSet(limit + offset);

        let copy = [...oldData];
        copy = copy.concat(manager);
        setSelectData(copy);
        setOnload(false);
      }
    }
  }, 100), []);

  const handleScrollRecive = useCallback(debounce(async (oldData, limit, offset, total) => {
    setOnloadRecive(true);
    if (offset + limit < total && !onLoadRecive) {
      const params = {
        limit: limitRecive,
        offset: limitRecive + offsetRecive,
      };
      const varible = new URLSearchParams(params);
      const response = await fetchApi(`/api/admin/members/manager?limit=${params.limit}&offset=${params.offset}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        const manager = objRecive.data.map?.((value) => ({
          label: value.name,
          id: value.id,
        }));
        setOffSetRecive(limitRecive + offsetRecive);

        let copy = [...oldData];
        copy = copy.concat(manager);
        setSelectDataRecive(copy);
        setOnloadRecive(false);
      }
    }
  }, 100), []);

  const defaultProps = {
    classes: { inputRoot: classes.inputRoot },
    sx: { width: 300 },
  };
  const defaultPropsSelect = {
    classes: { inputRoot: classes.inputRoot },
    sx: { width: 300, margin: '20px 0 30px' },
  };

  return (
    <>
      <Header link={-1} listPath="tools/linkListMovingAccountTools" noDescription noBack>
        <p className="title-page">{t('specicalAccountPageTitle')}</p>
        <div className="setup-head-item">
          <Typography>{t('titleMovingGroup')}</Typography>
          <Button
            className="button-creat-area"
            onClick={handleSubmit}
          >
            <Typography>{t('sharedPerform')}</Typography>
          </Button>
          <Button className="button-back" onClick={() => navigate(-1)}>
            <img src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
        </div>
      </Header>
      <Box style={{ margin: '0 2rem ' }}>
        <div style={{
          width: '60%',
          display: 'flex',
          justifyContent: 'space-around',
          flexWrap: configWrapBox(),
        }}
        >
          <div>
            <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>{t('movingCurrent')}</Typography>

            <Autocomplete
              {...defaultProps}
              disablePortal
              id="datasend"
              noOptionsText=""
              clearOnEscape
              options={selectData}
              value={value || null}
              open={open}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params, index) => (
                <TextField
                  placeholder={t('placeholderMovingAccount')}
                  {...params}
                  key={index}
                  onBlur={(e) => {
                    handleSearch(value, setSelectData);
                    setOpen(false);
                  }}
                  onFocus={() => setOpen(true)}
                  onChange={(e) => {
                    setOpen(true);
                    handleSearch(e.target.value, setSelectData);
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Button
                  variant="variant"
                  key={props.id}
                  style={{ width: '100%' }}
                  onClick={(e) => {
                    if (dataTransport.length > 0) {
                      setOpen(true);
                      setOpenModal(true);
                    } else {
                      setValue(option);
                      setOpen(false);
                    }
                  }}
                >
                  {props.key}
                </Button>
              )}
              ListboxProps={{
                onScroll: (e) => {
                  if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight * 2) {
                    handleScroll(selectData, limit, offset, total);
                  }
                },
              }}
            />
            <Table {...dataTable} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end', flexBasis: breakPointMD ? 'unset' : '100%' }}>
            <Button
              variant="outlined"
              className={classes.add}
              onClick={() => {
                if (tableRef.current.api.getSelectedRows().length !== 0 && valueRecive) {
                  sendDataFunction(tableRef.current.api.getSelectedRows());
                }
              }}
            >
              {t('sharedAdd')}
              <ArrowRightIcon fontSize="large" />
            </Button>
            <Button
              variant="outlined"
              className={classes.add}
              disabled={dataTransport.length === 0}
              onClick={() => {
                returnDataFunction(tableReciveRef.current.api.getSelectedRows());
              }}
            >
              <ArrowLeftIcon fontSize="large" />
              {t('moveAccountReturn')}
            </Button>
          </div>
          <div>
            <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>{t('movingDestination')}</Typography>

            <Autocomplete
              {...defaultProps}
              disablePortal
              id="datarecive"
              noOptionsText=""
              open={openRecive}
              options={selectDataRecive}
              value={valueRecive}
              isOptionEqualToValue={(option, valueRecive) => option.id === valueRecive.id}
              renderInput={(params, index) => (
                <TextField
                  {...params}
                  key={index}
                  placeholder={t('placeholderMovingAccount')}
                  onBlur={(e) => {
                    handleSearch(e.target.value, setSelectDataRecive);
                    setOpenRecive(false);
                  }}
                  onFocus={() => setOpenRecive(true)}
                  onChange={(e) => {
                    setOpenRecive(true);
                    handleSearch(e.target.value, setSelectDataRecive);
                  }}
                />
              )}
              renderOption={(props, option) => (
                <Button
                  variant="outline"
                  key={props.id}
                  style={{ width: '100%' }}
                  onClick={(e) => {
                    if (dataTransport.length > 0) {
                      setOpenRecive(true);
                      setOpenModal(true);
                    } else {
                      setValueRecive(option);
                      setOpenRecive(false);
                    }
                  }}
                >
                  {props.key}
                </Button>
              )}
              ListboxProps={{
                onScroll: (e) => {
                  if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight * 2) {
                    handleScrollRecive(selectDataRecive, limitRecive, offsetRecive, total);
                  }
                },
              }}
            />
            <Table {...dataTableRecive} />
          </div>
        </div>
        {/* 0.5次開発 - DEVELOPMENT-260 - 2024/10/18 TKS DangNH: remove modal not using */}
        {/* <Modal open={openModal}> */}
        {/*  <div className={classes.modalContainer}> */}
        {/*    <div style={{ textAlign: 'center' }}>{t('unfinishedModalText')}</div> */}
        {/*    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}> */}
        {/*      <Button */}
        {/*        variant="contained" */}
        {/*        style={{ width: '100px', backgroundColor: 'red', border: 'outset 5px red' }} */}
        {/*        onClick={() => setOpenModal(false)} */}
        {/*      > */}
        {/*        {t('close')} */}
        {/*      </Button> */}
        {/*      <Button */}
        {/*        variant="contained" */}
        {/*        style={{ width: '100px', backgroundColor: 'red', border: 'outset 5px red' }} */}
        {/*        onClick={handleSubmit} */}
        {/*      > */}
        {/*        {t('perform')} */}
        {/*      </Button> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </Modal> */}
        {/* <Modal */}
        {/*  open={openSuccess} */}
        {/*  onClose={handleCloseSuccess} */}
        {/*  aria-labelledby="modal-modal-title" */}
        {/*  aria-describedby="modal-modal-description" */}
        {/* > */}
        {/*  <Box */}
        {/*    sx={{ */}
        {/*      position: 'absolute', */}
        {/*      top: '50%', */}
        {/*      left: '50%', */}
        {/*      transform: 'translate(-50%, -50%)', */}
        {/*      width: 500, */}
        {/*      height: 200, */}
        {/*      bgcolor: '#08857C', */}
        {/*      boxShadow: 24, */}
        {/*      p: 3, */}
        {/*    }} */}
        {/*  > */}
        {/*    <Button */}
        {/*      variant="text" */}
        {/*      onClick={handleCloseSuccess} */}
        {/*      sx={{ */}
        {/*        position: 'absolute', */}
        {/*        top: 8, */}
        {/*        right: 8, */}
        {/*        color: 'white', */}
        {/*      }} */}
        {/*    > */}
        {/*      <ClearIcon /> */}
        {/*    </Button> */}
        {/*    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 30 }}> */}
        {/*      <div> */}
        {/*        <img width="60px" height="60px" src={Vector} alt="img" /> */}
        {/*      </div> */}
        {/*      <Typography sx={{ color: 'white' }} className="upsert-device-modal-title">グループ移動が完了しました</Typography> */}
        {/*    </div> */}
        {/*  </Box> */}
        {/* </Modal> */}
      </Box>
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={() => {
          setOpenSuccess(false);
        }}
        title={t('titleCompleteMoveGroup')}
      />
    </>
  );
};

export default MovingAccount;
