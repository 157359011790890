/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import {
  IconButton, Pagination, TablePagination,
} from '@mui/material';
import moment from 'moment';
import { DateFilter } from 'ag-grid-community';
import { accountsActions, helperActions } from '../../store';
import Table from './Table';
import { useTranslation } from '../../common/components/LocalizationProvider';
import fetchApi from '../FetchApi';
import UseFilter from './UseFilter';
import FloatingFilterComponent from './FloatingFilterComponent';
import {
  Member, PaymentStatus, BillingMethod, RatePlan, PaymentMethod, PaymentSpan, Type,
} from '../common/DefineClass';
import {
  convertWidth, valueTranslate, DropdownEdit,
} from '../common/utils';
import CheckBoxAll from './CheckBoxAll';
import DatePicker from './DatePicker';
import FilterComponent from './FilterComponent';

const useStyle = makeStyles((theme) => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  pagination: {
    paddingTop: '10px',
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#f47024',
      },
    },
  },
  link: {
    color: '#1e70c4',
  },
}));

const detailButton = (data) => {
  const classes = useStyle();
  const t = useTranslation();
  return (
    <Link
      underline="always"
      className={classes.link}
      to={`/admin/home/list/details/${data.representationId || data.managedUserId}/edit`}
    >
      {t('detailTableHeader')}
    </Link>
  );
};

// declare column percentage
const listColumPercent = {
  checkbox: 4,
  detail: 6,
  category: 8,
  status: 10,
  name: 12,
  userId: 8,
  disabled: 7,
  email: 20,
  groupName: 10,
  representId: 9,
  paymentId: 22,
  billingMethod: 15,
  ratePlan: 15,
  paymentMethod: 20,
  paymentSpan: 15,
  price: 8,
  lastBillingDate: 8,
  nextBillingDate: 8,
  startDate: 10,
  phone: 10,
  withdrawDate: 10,
  updateDate: 8,
  type: 7,
};

const NewTable = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,
    className,
    saveData,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    listChecked,
    refreshData,
    setRefreshData,
    ...others
  } = props;

  const classes = useStyle();
  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();

  const userModel = new Member();
  const paymentStatus = new PaymentStatus();
  const billingMethod = new BillingMethod();
  const ratePlan = new RatePlan();
  const paymentMethod = new PaymentMethod();
  const paymentSpan = new PaymentSpan();

  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);

  const [data, setData] = useState([]);
  const [listNode, setListNode] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);

  const count = Math.ceil(totalPages / limit);

  // Declare columns
  const columnDefs = [
    {
      width: convertWidth(listColumPercent.checkbox),
      filter: false,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponent: (() => (
        <CheckBoxAll
          setCheck={setChecked}
        />
      )),
    },
    {
      headerName: '',
      width: convertWidth(listColumPercent.detail),
      cellRenderer: (params) => detailButton(params.data),
      field: userModel.field1,
      floatingFilter: false,
      filter: false,
    },
    {
      headerName: t('categoryTableHeader1'),
      width: convertWidth(10),
      field: 'category1',
      valueFormatter: (params) => valueTranslate(t, params.value, 'category1'),
      filter: UseFilter,
      filterParams: {
        title: 'Category Filters',
        values: [{ value: 1, category: 'category1' }, { value: 0, category: 'category1' }],
      },
      floatingFilter: false,
    },
    {
      headerName: t('categoryTableHeader2'),
      width: convertWidth(8),
      field: 'role',
      valueFormatter: (params) => valueTranslate(t, params.value, 'role'),
      filter: UseFilter,
      filterParams: {
        title: 'Category Filters',
        values: [{ value: 2, category: 'role' }, { value: 1, category: 'role' }],
      },
      floatingFilter: false,
    },
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: userModel.field2,
      floatingFilterComponent: null,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
      filter: UseFilter,
      filterParams: {
        title: 'Category Filters',
        values: [{ value: 1, category: 'account' }, { value: 0, category: 'account' }],
      },
      floatingFilter: false,
    },
    {
      headerName: t('nameTableHeader'),
      field: userModel.field4,
      width: convertWidth(listColumPercent.name),
      filter: FilterComponent,
      floatingFilter: false,
    },
    {
      headerName: t('userId'),
      field: userModel.field5,
      filterParams: {
        type: 'NUMBER',
      },
      width: convertWidth(listColumPercent.userId),
      cellRenderer: ((params) => (
        <div key={params?.data?.id} style={{ display: 'flex', alignItems: 'center' }}>
          {params?.data?.id}
          {changePasswordById && params.data.providerType === 0 && (
            <div style={{ display: 'flex', position: 'absolute', right: 3, minHeight: '50px' }}>
              <IconButton
                sx={{ color: 'black' }}
                size="small"
                onClick={() => {
                  navigate(`/admin/tools/changePasswordForm/${params?.data?.id}`);
                  dispatch(helperActions.getChangePasswordUser(params?.data));
                }}
              >
                <EditIcon />
              </IconButton>
            </div>
          )}
        </div>
      )),
      floatingFilterComponent: FloatingFilterComponent,
      floatingFilter: false,
    },
    {
      headerName: t('userStatusTableHeader'),
      field: userModel.field6,
      width: convertWidth(listColumPercent.disabled),
      cellRenderer: (params) => valueTranslate(t, params.value, 'account'),
      filter: UseFilter,
      filterParams: {
        title: 'Status Filters',
        values: [
          { value: 0, category: 'statusUsage' },
          { value: 1, category: 'statusUsage' },
          // { value: '退会済', category: 'account' },
        ],
      },
      floatingFilterComponent: null,
      floatingFilter: false,
    },
    {
      headerName: t('userEmailTableHeader'),
      field: userModel.field7,
      width: convertWidth(listColumPercent.email),
      floatingFilterComponent: FloatingFilterComponent,
      floatingFilter: false,
    },
    {
      headerName: t('representIdTableHeader'),
      field: userModel.field9,
      width: convertWidth(listColumPercent.representId),
      floatingFilterComponent: FloatingFilterComponent,
      cellRenderer: (params) => {
        const { data } = params;
        const { representationId } = data;
        const { managedUserId } = data;

        if (representationId === null) {
          return managedUserId;
        }
        return representationId;
      },
      floatingFilter: false,
    },
    {
      headerName: t('typeApp'),
      field: 'typeApp',
      width: convertWidth(8),
      valueFormatter: (params) => valueTranslate(t, params.value, 'typeApp'),
      filter: UseFilter,
      filterParams: {
        title: 'Status Filters',
        values: [
          { value: 0, category: 'typeApp' },
          { value: 1, category: 'typeApp' },
          { value: 2, category: 'typeApp' },
        ],
      },
      floatingFilter: false,
    },
    {
      headerName: t('startDateTableHeader'),
      field: userModel.field27,
      width: convertWidth(listColumPercent.lastBillingDate),
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYYMMDD');
          return formattedDate;
        }
        return null;
      },
      filter: DateFilter,
      floatingFilter: false,
    },
    {
      headerName: t('endDateTableHeader'),
      field: userModel.field28,
      width: convertWidth(listColumPercent.nextBillingDate),
      cellRenderer: (params) => {
        if (!params.value) return t('unLimited');
        const formattedDate = moment(params.value).local().format('YYYYMMDD');
        const lastDateOfCalendar = moment('9999/12/31').format('YYYYMMDD');
        return formattedDate === lastDateOfCalendar ? t('unLimited') : formattedDate;
      },

      filter: DateFilter,
      floatingFilter: false,
    },
    {
      headerName: t('noteTableHeader'),
      field: userModel.field31,
      width: convertWidth(10),
      filter: FilterComponent,
      floatingFilter: false,
    },
    {
      headerName: t('updateDateTableHeader'),
      field: userModel.field22,
      width: convertWidth(listColumPercent.updateDate),
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYYMMDD');
          return formattedDate;
        }
        return null;
      },
      filter: DateFilter,
      floatingFilter: false,
    },
  ];

  const components = useMemo(() => ({
    agDateInput: DatePicker,
  }), []);

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => copyColumn[number].editable = true);
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => copyColumn[number] = 'remove');
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: listData || data,
    components,
    ...others,
  };

  const getData = async (filter, limit, offset, direct, checked) => {
    const response = await fetchApi('/api/admin/members/list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        limit,
        offset,
        orderBy: direct?.filter((data) => data.direct !== null),
        filterBy: filter?.filter((data) => data.value !== null),
      }),
    });
    if (response.ok) {
      const userList = await response.json();
      const transformedUsersData = userList.data.dataList?.map((data) => ({
        ...data,
        billingmethod: data.billing_method,
        userpaymentid: data.user_payment_id,
        lastBillingDate: data.last_billing_date,
        nextBillingDate: data.next_billing_date,
        updateDate: data.update_date,
        paymentmethod: data.payment_method,
        paymentnumber: data.payment_number,
        paymentspan: data.payment_span,
        rateplan: data.rate_plan,
        phone: data.telephone,
      }));
      setTotalPages(userList.total);
      setData(transformedUsersData);
      dispatch(accountsActions.push(userList?.data));
      if (checked === true) {
        dispatch(helperActions.getCheckedList(transformedUsersData));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(transformedUsersData));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    }

    if (!saveData) {
      dispatch(helperActions.removeAllData());
    }
  };

  // Function to change page
  const handlePageChange = (event, page) => {
    const offsetValue = page * limit - limit;
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setOffset(offsetValue);
    setLimit(limit);
    setChecked(false);
    getData(filterData, limit, offsetValue, direct);
  };

  useEffect(() => {
    setCurrentPage(Number(1));
    dispatch(helperActions.currentPage(1));
    setOffset(0);
    setLimit(limit);
    if (checked === true) {
      getData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      getData(filterData, limit, 0, direct, null);
    } else {
      getData(filterData, limit, 0, direct, false);
    }
  }, [filterData, direct, checked]);

  useEffect(() => {
    if (refreshData === true) {
      getData(filterData, limit, offset, direct, checked);
      setRefreshData(false);
    }
  }, [refreshData]);

  return (
    <div className={classes.contentBox} ref={mainRef}>
      <Table
        {...dataTable}
        childrenBottom={showPagination === true && (
        <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f1f0f0' }}>
          <Pagination
            className={classes.pagination}
            size="small"
            count={count}
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
          <TablePagination
            size="small"
            component="div"
            count={totalPages}
            rowsPerPage={limit}
            page={currentPage - 1}
            onPageChange={() => {
            }}
            rowsPerPageOptions={[]}
            nextIconButtonProps={{ style: { display: 'none' } }}
            backIconButtonProps={{ style: { display: 'none' } }}
          />
        </div>
        )}
      />
    </div>
  );
};

export default NewTable;
