import React from 'react';
import {
  Grid, Container, TextField, InputLabel, Box, Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { renderTimeOptions } from '../components/utils';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles({
  selectField: {
    width: '70px', height: '40px',
  },

});

const CheckForm = (props) => {
  const { formik, dataCheck } = props;
  const t = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth="sm" sx={{ display: 'none' }}>
      <Box mt={1} />
      <InputLabel htmlFor="cn">
        {t('cardNumber')}
        *
      </InputLabel>
      <TextField
        fullWidth
        disabled
        size="small"
        variant="outlined"
        id="cn"
        name="cn"
        type="text"
        inputProps={{ style: { textTransform: 'lowercase' } }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={dataCheck.cn}
      />

      <Box mt={1} />
      <InputLabel htmlFor="ed">{t('cardExpiry')}</InputLabel>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel htmlFor="em" sx={{ margin: '0 10px' }}>{t('month')}</InputLabel>
          <Select
            id="em"
            disabled
            value={dataCheck.em}
            name="em"
            className={classes.selectField}
            onBlur={formik.handleBlur}
          >
            {renderTimeOptions(1, 12)}
          </Select>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputLabel htmlFor="ey" sx={{ margin: '0 10px' }}>{t('year')}</InputLabel>
          <Select
            id="ey"
            disabled
            value={dataCheck.ey}
            name="ey"
            className={classes.selectField}
            onBlur={formik.handleBlur}
          >
            {renderTimeOptions(20, 29)}
          </Select>
        </div>
      </div>

      <Box display="flex" flexDirection="column">
        <TextField
          fullWidth
          disabled
          style={{ display: 'none' }}
          variant="outlined"
          id="aid"
          name="aid"
          type="text"
          onBlur={formik.handleBlur}
          value={dataCheck.aid}
        />

        <Box mt={1} />
        <input id="tkn" name="tkn" type="hidden" value="" />
        <div id="EMV3DS_INPUT_FORM" />

        <Box mt={1} />
        <InputLabel htmlFor="fn">{t('firstName')}</InputLabel>
        <TextField
          fullWidth
          disabled
          size="small"
          variant="outlined"
          placeholder="first name"
          id="fn"
          name="fn"
          type="text"
          onBlur={formik.handleBlur}
          value={dataCheck.fn}
        />

        <Box mt={1} />
        <InputLabel htmlFor="ln">{t('lastName')}</InputLabel>
        <TextField
          fullWidth
          disabled
          size="small"
          variant="outlined"
          placeholder="last name"
          id="ln"
          name="ln"
          type="text"
          onBlur={formik.handleBlur}
          value={dataCheck.ln}
        />

        <Box mt={1} />
        <InputLabel htmlFor="cvv">
          {t('securityCode')}
          *
        </InputLabel>
        <TextField
          fullWidth
          disabled
          size="small"
          variant="outlined"
          id="cvv"
          name="cvv"
          type="text"
          inputProps={{ style: { textTransform: 'lowercase' } }}
          onBlur={formik.handleBlur}
          value={dataCheck.cvv}
        />

        <Box mt={1} />
        <InputLabel htmlFor="pn">{t('commandPhone')}</InputLabel>
        <TextField
          fullWidth
          disabled
          size="small"
          variant="outlined"
          id="pn"
          name="pn"
          type="text"
          inputProps={{ style: { textTransform: 'lowercase' } }}
          onBlur={formik.handleBlur}
          value={dataCheck.pn}
        />

      </Box>
    </Container>
  );
};

export default CheckForm;
