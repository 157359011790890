import React, {
  useState, useEffect, useRef, useLayoutEffect,
} from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import DevicesList from './DevicesList';
import { useTranslation } from '../common/components/LocalizationProvider';
import { devicesActions, helperActions } from '../store';
import UnSelect from '../resources/images/location_share/Btn_unselect.png';

const useStyles = makeStyles({
  title: {
    margin: '11.25px auto 12.25px 29px',
    color: '#6C6A6A',
    display: 'inline-block',
  },
});

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light'
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  height: '50px',
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 42,
  height: 5,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const DeviceDrawer = ({ height, deviceId, onlyOne, list, closeBtn, slider }) => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const [devicesList, setDevicesList] = useState();

  // get devices list
  const devices = useSelector((state) => state.devices.items);
  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const device = useSelector((state) => state.devices.items[selectedDeviceId]);
  const deviceType = useSelector((state) => state.deviceType.type);
  const [configHeight, setConfigHeight] = useState(height);
  const [dragElement, setDragElement] = useState();
  const comp = useRef();
  // const dragElement = Draggable.get('.MuiDrawer-paperAnchorBottom');

  // useEffect(() => {
  //   const getDevices = [list];
  //   setDevicesList(getDevices);
  // }, [list]);

  useEffect(() => {
    setDragElement(Draggable.get('.MuiDrawer-paperAnchorBottom'));
  }, []);

  useEffect(() => {
    setDevicesList(list);
  }, [list]);

  const deviceList = (element) => <DevicesList devices={list} element={element} />;

  // function displayed when selecting a device
  const selectedDevice = (element) => {
    if (devicesList) {
      // const result = devicesList.find((arr) => arr.id === deviceId);
      return (
        <>
          {/* <StyledBox>
            <Puller style={{ width: '42px', height: '5px' }} />
            <Typography className={classes.title}>
              {t('watchTarget')}
            </Typography>
          </StyledBox> */}
          <DevicesList devices={devicesList} height={200} element={element} />
        </>
      );
    }
    return <div />;
  };

  useEffect(() => {
    if (selectedDeviceId && !onlyOne && dragElement) {
      setConfigHeight(365);
      gsap.to('.MuiDrawer-paperAnchorBottom', { y: 0, duration: 1 });
    }
    if (!selectedDeviceId && !onlyOne && dragElement) {
      setConfigHeight(height);
      gsap.to('.MuiDrawer-paperAnchorBottom', { y: configHeight - 50, duration: 1 });
    }
    if (onlyOne) {
      gsap.to('.MuiDrawer-paperAnchorBottom', { y: 0, duration: 1 });
    }
    if (selectedDeviceId && !onlyOne && !dragElement) {
      setConfigHeight(365);
      gsap.to('.MuiDrawer-paperAnchorBottom', { y: 0, duration: 1 });
    }
  }, [selectedDeviceId, configHeight]);

  useLayoutEffect(() => {
    const objectDrag = comp.current.offsetParent;
    let currentPoint;
    gsap.registerPlugin(Draggable);
    Draggable.create(objectDrag, {
      type: 'y',
      bounds: {
        minY: configHeight - 50,
        maxY: 0,
      },
      onDragStart: (e) => {
        currentPoint = e.y;
      },
      onDragEnd: (e) => {
        if (e.y < currentPoint) {
          gsap.to(objectDrag, { y: 0, duration: 1 });
        } else if (e.y > currentPoint) {
          gsap.to(objectDrag, { y: configHeight - 50, duration: 0 });
        }
      },

    });
    setDragElement(Draggable.get(objectDrag));
    gsap.to(objectDrag, { y: configHeight - 50, duration: 1 });
    return () => {};
  }, []);

  useEffect(() => {
    if (dragElement) {
      let currentPoint;
      const objectDrag = dragElement.target;
      dragElement.applyBounds(
        {
          minY: configHeight - 50,
          maxY: 0,
        },
      );
      dragElement.vars = {
        ...dragElement.vars,
        onDragStart: (e) => {
          currentPoint = e.y;
        },
        onDragEnd: (e) => {
          if (e.y < currentPoint) {
            gsap.to(objectDrag, { y: 0, duration: 1 }).then(() => {
              // if (deviceType === 'mobile') dragElement.disable();
            });
          } else if (e.y > currentPoint) {
            gsap.to(objectDrag, { y: configHeight - 50, duration: 1 });
          }
        },
      };
      dragElement.update(true);
    }
  }, [configHeight, dragElement]);

  return (
    <Root>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiDrawer-paperAnchorBottom': {
            // eslint-disable-next-line object-shorthand
            height: configHeight || '365px',
            borderRadius: '30px 30px 0px 0px',
            opacity: 1,
            overflow: 'inherit',
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open
        swipeAreaWidth={10}
        onOpen={() => {}}
        onClose={() => {}}
        variant="persistent"

      >
        {selectedDeviceId && closeBtn && (
        <IconButton
          sx={{ position: 'fixed', top: '-25px', right: 5 }}
          onClick={(e) => {
            e.preventDefault();
            e.cancelBubble = true;
            e.stopPropagation();
            dispatch(devicesActions.select(null));
            dispatch(helperActions.closeHistory());
          }}
        >
          <img src={UnSelect} alt="unSelected" />
        </IconButton>
        )}
        <StyledBox
          ref={comp}
          // onTouchMove={(e) => dragElement.enable()}
        >
          <Puller style={{ width: '42px', height: '5px' }} />
        </StyledBox>
        {slider ? (
          <>
            <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
              {devices ? selectedDevice(dragElement) : deviceList(dragElement)}
            </div>
            {slider}
          </>
        ) : (
          <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            {devices ? selectedDevice(dragElement) : deviceList(dragElement)}
          </div>
        )}

      </SwipeableDrawer>
    </Root>
  );
};

export default DeviceDrawer;
